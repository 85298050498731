import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import FICDateTime from '../../../common/dateTime'
import { getMaintenance } from '../../../redux/features/localStorage/localStorageSelectors'
import Maintenance from '../../pages/maintenance/Maintenance'
import HideLogics from './HideLogics'

interface Props {
  children: JSX.Element
}

const MaintenanceGuard = ({ children }: Props): JSX.Element => {
  const { startAt } = useSelector(getMaintenance)

  const [inMaintenance, setInMaintenance] = useState(
    startAt && FICDateTime.isBefore(startAt, new Date(), 'seconds')
  )
  const timer = useRef<number>(0)

  useEffect(() => {
    if (inMaintenance || !startAt) {
      clearTimeout(timer.current)
    }
  }, [inMaintenance, startAt])

  useLayoutEffect(() => {
    if (startAt) {
      const alreadyStarted = FICDateTime.isBefore(startAt, 'now', 'seconds')
      setInMaintenance(alreadyStarted)
      if (!alreadyStarted) {
        timer.current = window.setTimeout(() => {
          setInMaintenance(true)
        }, FICDateTime.diff(startAt, 'now') + 2000)
      }
    } else {
      setInMaintenance(false)
    }
  }, [startAt])

  return inMaintenance ? (
    <>
      <HideLogics />
      <Maintenance />
    </>
  ) : (
    children
  )
}

export default MaintenanceGuard
