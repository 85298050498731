import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { BECallStatus } from '../../../../common/types'
import {
  GetRecoveryCodeStatusResponse,
  LoginAttempt,
  RecoveryCodeStatus,
} from '../../../../common/types/auth'
import MFAStore, { RecoveryCode } from '../mfaTypes'

const fetchRecoveryCode = createAsyncThunk<
  RecoveryCode,
  LoginAttempt | undefined,
  {
    rejectValue: Partial<RecoveryCode>
  }
>('mfa/fetchRecoveryCode', async (loginAttempt, thunkApi) => {
  try {
    const response = await Api.get<GetRecoveryCodeStatusResponse>({
      request: 'mfa/recovery_code/status',
      queryData: { loginAttempt: loginAttempt?.authCode },
    })
    return {
      status: response.data.data.isRecoveryCodeMissing
        ? response.data.data.details.missingCause ?? RecoveryCodeStatus.BLOCKED
        : RecoveryCodeStatus.ACTIVE,
      remainingAttempts: response.data.data.details.remainingAttempts,
      fetchStatus: BECallStatus.SUCCESS,
    }
  } catch (e) {
    return thunkApi.rejectWithValue({
      fetchStatus: BECallStatus.FAILED,
    })
  }
})

export const addFetchRecoveryCodeCases = (
  builder: ActionReducerMapBuilder<MFAStore>
): void => {
  builder
    .addCase(fetchRecoveryCode.pending, state => {
      state.recoveryCode.fetchStatus = BECallStatus.LOADING
    })
    .addCase(fetchRecoveryCode.fulfilled, (state, action) => {
      state.recoveryCode = {
        ...state.recoveryCode,
        ...action.payload,
      }
    })
    .addCase(fetchRecoveryCode.rejected, (state, action) => {
      if (action.payload) {
        state.recoveryCode = {
          ...state.recoveryCode,
          ...action.payload,
        }
      }
    })
}

export default fetchRecoveryCode
