import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useEffect } from 'react'

import useValuesSelector from '../../../../../redux/common/useValuesSelector'
import { fakeSendDataToGTMType } from '../types'
import useCompanyIdGtm from '../useCompanyIdGtm'

const useUserIdDispatcher = (): void => {
  const { regimeMin, userPlan, userType, visitorLifetimeValue, visitorType } =
    useValuesSelector('gaTags', [
      'regimeMin',
      'userPlan',
      'userType',
      'visitorLifetimeValue',
      'visitorType',
    ])

  const userId = useCompanyIdGtm()
  const sendDataToGTM: fakeSendDataToGTMType = useGTMDispatch()

  useEffect(() => {
    if (userId) {
      sendDataToGTM({
        userId,
        visitorType,
        visitorLifetimeValue,
        user_plan: userPlan,
        regime_min: regimeMin,
        user_type: userType,
        event: 'setUserId',
      })
    }
  }, [
    regimeMin,
    sendDataToGTM,
    userId,
    userPlan,
    userType,
    visitorLifetimeValue,
    visitorType,
  ])
}

export default useUserIdDispatcher
