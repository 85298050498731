import { AxiosResponse } from 'axios'

import store from '../../redux'
import { GlobalStore } from '../../redux/app/rootReducer'
import { getAccessToken } from '../../redux/features/localStorage/localStorageSelectors'
import { getSupport } from '../../redux/features/support/supportSelectors'
import { ApiCallOptions, AxiosHeaders } from '../network/Api'
import {
  ApiFilter,
  ApiSort,
  PaginatedResponse,
  Pagination,
  QuestionOption,
  QuestionOptionsBackendType,
  UnknownObject,
} from '../types'
import { getDeviceId } from '../utils/appUtils'
import { camelToSnakeCase } from './stringUtils'

export const applyFilters = (
  request: string,
  filters: ApiFilter[] | undefined
): string => {
  if (filters && filters.length > 0) {
    const filtersString = $.param({ filter: filters })
    if (request.includes('?') || request.includes('&')) {
      request += '&' + filtersString
    } else {
      request += '?' + filtersString
    }
  }
  return request
}

export const applySorting = (
  request: string,
  sort: ApiSort[] | undefined
): string => {
  if (sort && sort.length > 0) {
    const sortString = sort.reduce((acc, s, i) => {
      if (s.direction) {
        acc += `${s.direction === 'desc' ? '-' : ''}${camelToSnakeCase(
          s.sortBy
        )}${i !== sort.length - 1 ? ',' : ''}`
      }
      return acc
    }, '')
    if (!sortString) return request

    if (request.includes('?') || request.includes('&')) {
      request += `&sort=${sortString}`
    } else {
      request += `?sort=${sortString}`
    }
  }
  return request
}

export const createHeaders = (
  customToken: ApiCallOptions['customToken'],
  hasFormData: boolean,
  headers: ApiCallOptions['headers'],
  noCache: ApiCallOptions['noCache'],
  url: boolean,
  withoutToken: ApiCallOptions['withoutToken']
): AxiosHeaders => {
  const state: GlobalStore = store.getState()
  const support = getSupport(state)
  const token = customToken || getAccessToken(state)
  const deviceId = getDeviceId()

  return {
    'Accept-Language': 'it',
    ...headers,
    ...(hasFormData
      ? {}
      : { 'Content-Type': 'application/json; charset=utf-8' }),
    ...(noCache ? { 'Cache-Control': 'no-cache' } : {}),
    ...(token && !withoutToken ? { Authorization: token } : {}),
    ...(support.adminAccountId && !url
      ? { 'Admin-Account-Id': `${support.adminAccountId}` }
      : {}),
    ...(deviceId ? { 'X-Device-Id': deviceId } : {}),
  }
}

type Response<T, E> = {
  data: T
  extra?: E
  pagination: Pagination
}

export const paginatedResponse = <
  T extends UnknownObject | UnknownObject[],
  E extends UnknownObject | UnknownObject[] = never
>(
  response: AxiosResponse<PaginatedResponse<T, E>>
): Response<T, E> => {
  const { data, extra, ...pagination } = response.data
  return { data, extra, pagination }
}

export const convertQuestionOptions = (
  options: QuestionOptionsBackendType
): QuestionOption[] =>
  Object.entries(options).map(([text, { color, value }]) => ({
    text,
    value,
    color,
  }))
