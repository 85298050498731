import { Draft, PayloadAction } from '@reduxjs/toolkit'

import LocalStorageStore from '../localStorageStoreTypes'
import { updateLocalStorageObject } from '../localStorageUtils'

export const setHasAutomaticRenewal = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<boolean>
): LocalStorageStore => {
  updateLocalStorageObject({ hasAutomaticRenewal: action.payload })
  return { ...state, hasAutomaticRenewal: action.payload }
}
