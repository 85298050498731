import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'

import {
  AutocompleteMenu,
  AutocompleteOption,
  FICAutocomplete,
  MenuOuter,
} from '../../../common/components-v2/FICAutocomplete/style'

export class FICAutocompleteImpl extends Component {
  constructor(props) {
    super(props)
    this.autocompleteRef = null
    this.firstLoad = false
    this.filter = props.value
    this.scheduledChange = null
    this.scheduledLoadOptions = null
    this.lastOnChangeValue = props.value || undefined
    this.state = {
      useInternalValue: false,
      internalValue: props.value || undefined,
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        this.focus()
        setTimeout(() => {
          this.setState({ useInternalValue: false })
        }, 0)
      }, 100)
    }
  }

  focus = () => {
    if (this.autocompleteRef) {
      this.autocompleteRef.focus()
    }
  }

  blur = () => {
    if (this.autocompleteRef) {
      this.autocompleteRef.blur()
    }
  }

  shouldUseInternalValue = () => {
    return (
      this.props.changeOnBlur ||
      (!this.props.uncontrolled && this.props.changeDelay)
    )
  }

  getDisplayValue = () => {
    const props = this.props
    const state = this.state
    return this.shouldUseInternalValue() && state.useInternalValue
      ? state.internalValue || ''
      : props.value != null
      ? props.value
      : ''
  }

  handleChange = e => {
    const props = this.props
    const value = e.target.value
    // Options
    this.filter = value
    if (props.loadOptions) {
      this.cancelScheduledLoadOptions()
      if (props.triggerLength <= value.length) {
        this.scheduleLoadOptions(value)
      }
    }
    // General input
    if (props.clearable) {
      this.setState({ clearableVisible: value })
    }
    if (this.shouldUseInternalValue()) {
      this.setState({ internalValue: value })
    }
    if (props.changeDelay) {
      this.cancelScheduledChange()
      this.scheduleChange(value)
    } else {
      this.executeOnChange(value)
    }
  }

  executeOnChange = value => {
    const props = this.props
    if (props.onChange && value !== this.lastOnChangeValue) {
      this.lastOnChangeValue = value
      props.onChange(value, props.name)
    }
  }

  handleSelect = (value, data) => {
    const props = this.props
    value = data[props.valueKey ? props.valueKey : props.labelKey]
    this.cancelScheduledChange()
    this.cancelScheduledLoadOptions()
    if (this.shouldUseInternalValue()) {
      this.setState({ internalValue: value })
    }
    if (!props.preventChangeOnSelect) {
      this.executeOnChange(value)
    }
    if (props.onSelect) {
      this.lastOnChangeValue = value
      props.onSelect(value, data)
    }
  }

  handleFocus = e => {
    const props = this.props
    if (this.shouldUseInternalValue()) {
      this.setState({
        useInternalValue: true,
        internalValue: props.value,
      })
    }
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  handleBlur = e => {
    const props = this.props
    const value = e.target.value
    this.setState({ useInternalValue: false })
    if (props.changeOnBlur) {
      this.executeOnChange(value)
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  scheduleChange = value => {
    this.scheduledChange = setTimeout(() => {
      this.executeOnChange(value)
    }, this.props.changeDelay)
  }

  cancelScheduledChange = () => {
    if (this.scheduledChange) {
      clearTimeout(this.scheduledChange)
    }
  }

  scheduleLoadOptions = value => {
    this.scheduledLoadOptions = setTimeout(
      () => this.props.loadOptions(value),
      this.props.loadOptionsDelay
    )
  }

  cancelScheduledLoadOptions = () => {
    if (this.scheduledLoadOptions) {
      clearTimeout(this.scheduledLoadOptions)
    }
  }

  render() {
    let {
      idKey,
      labelKey,
      loadOptions,
      maxMenuHeight,
      options,
      placeholder,
      renderItem,
      renderMenuContent,
      shouldItemRender,
      style,
      triggerLength,
      validator,
      value,
      valueKey,
      ...props
    } = this.props
    let className = 'FICAutocomplete'
    if (props.className) {
      className += ' ' + props.className
    }
    let inputClassName = 'FICInputText_Input'
    if (validator && !validator.valid) {
      inputClassName += ' FICInputText_Invalid'
    }
    value = this.getDisplayValue()
    return (
      <Autocomplete
        ref={ref => {
          this.autocompleteRef = ref
        }}
        wrapperProps={{ className: className }}
        getItemValue={item => item[valueKey || labelKey]}
        wrapperStyle={{ ...style, position: 'relative' }}
        renderMenu={(items, value, style) => {
          if (renderMenuContent) {
            return renderMenuContent(items, value, style)
          }

          if (!items || items.length === 0) {
            return <div />
          }
          style.minWidth = 0
          if (maxMenuHeight) {
            style.maxHeight = 30 * maxMenuHeight
          }
          const displayMenu =
            triggerLength > this.filter.length ? 'none' : 'block'
          return (
            <MenuOuter style={{ display: displayMenu }}>
              <AutocompleteMenu
                style={style}
                children={items}
                className='FICAutocomplete_Menu'
              />
            </MenuOuter>
          )
        }}
        menuStyle={{
          position: 'absolute',
          top: 30,
          left: 0,
        }}
        items={options}
        renderItem={(item, isHighlighted) => {
          return renderItem ? (
            renderItem(item)
          ) : (
            <AutocompleteOption
              key={item[idKey || labelKey]}
              focus={isHighlighted}
            >
              {item[labelKey]}
            </AutocompleteOption>
          )
        }}
        shouldItemRender={(item, value) => {
          return shouldItemRender
            ? shouldItemRender(item, value)
            : item[labelKey] &&
                item[labelKey].toLowerCase().indexOf(value.toLowerCase()) > -1
        }}
        inputProps={{
          className: inputClassName,
          placeholder: placeholder,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur,
          onKeyPress: props.onKeyPress,
          onKeyDown: props.onKeyDown,
          onKeyUp: props.onKeyUp,
          onClick: props.onClick,
          onMouseOver: props.onMouseOver,
          onMouseOut: props.onMouseOut,
          autoComplete: 'new-password',
        }}
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onMenuVisibilityChange={isOpen => {
          if (
            isOpen &&
            !this.firstLoad &&
            loadOptions &&
            triggerLength <= this.filter.length
          ) {
            this.firstLoad = true
            loadOptions('')
          }
        }}
      />
    )
  }
}

FICAutocomplete.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  loadOptionsDelay: PropTypes.number,
  triggerLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idKey: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  emptyLabel: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  changeOnBlur: PropTypes.bool,
  changeDelay: PropTypes.number,
  clearable: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  preventChangeOnSelect: PropTypes.bool,
  renderMenuContent: PropTypes.func,
  renderItem: PropTypes.func,
  validator: PropTypes.func,
  shouldItemRender: PropTypes.func,
  autoFocus: PropTypes.bool,
  uncontrolled: PropTypes.bool,
  size: PropTypes.string,
  isValid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
}

FICAutocompleteImpl.propTypes = FICAutocomplete.propTypes

FICAutocomplete.defaultProps = {
  className: undefined,
  options: [],
  loadOptions: undefined,
  loadOptionsDelay: 0,
  triggerLength: 0,
  value: '',
  idKey: undefined,
  valueKey: undefined,
  labelKey: 'label',
  emptyLabel: '',
  placeholder: '',
  onSelect: undefined,
  onChange: undefined,
  changeOnBlur: true,
  changeDelay: 500,
  clearable: true,
  maxMenuHeight: undefined,
  preventChangeOnSelect: false,
  renderMenuContent: undefined,
  renderItem: undefined,
  validator: undefined,
  shouldItemRender: undefined,
  autoFocus: false,
  uncontrolled: false,
  size: undefined,
  isValid: undefined,
  isDisabled: undefined,
  style: {},
}

FICAutocompleteImpl.defaultProps = FICAutocomplete.defaultProps

export default FICAutocomplete
