import FICColors from '../../styles/FICColors'

export function getComponentsStyles(props) {
  const componentsFixedStyle = {
    container: { border: 'none !important', marginBottom: '0' },
    control: {
      height: '100%',
      minHeight: 'unset',
      lineHeight: '24px',
      '>div': { height: 30 },
    },
    singleValue: { margin: 0 },
    menu: { zIndex: 99 },
    menuList: {},
    option: {
      paddingRight: 30,
      cursor: 'pointer',
      '&:hover, &:active': {
        backgroundColor: FICColors.gray,
      },
    },
    clearIndicator: {
      marginTop: '8px',
      cursor: 'pointer',
    },
  }

  return {
    container: baseStyle => ({
      ...baseStyle,
      ...props.style,
      ...componentsFixedStyle.container,
    }),
    control: (baseStyle, state) => ({
      ...baseStyle,
      ...componentsFixedStyle.control,
      ...getControlComponentStyle(state, props),
    }),
    singleValue: baseStyle => ({
      ...baseStyle,
      ...componentsFixedStyle.singleValue,
    }),
    menu: baseStyle => ({ ...baseStyle, ...componentsFixedStyle.menu }),
    menuList: baseStyle => ({ ...baseStyle, ...componentsFixedStyle.menuList }),
    option: (baseStyle, state) => ({
      ...baseStyle,
      ...componentsFixedStyle.option,
      ...getOptionComponentStyle(state),
    }),
    clearIndicator: baseStyle => ({
      ...baseStyle,
      ...componentsFixedStyle.clearIndicator,
    }),
  }
}

function getControlComponentStyle(state, props) {
  let style = {}
  if (props.isValid === false) {
    style = {
      ...style,
      ...{
        borderColor: FICColors.red,
        boxShadow: '0 0 4px ' + FICColors.red,
        ':focus': {
          borderColor: FICColors.red,
          boxShadow: '0 0 4px ' + FICColors.red,
        },
      },
    }
  }

  const disabledControlStyle = {
    backgroundColor: FICColors.inputDisabled,
    cursor: 'not-allowed',
    borderColor: FICColors.inputDisabled,
    pointerEvents: 'all',
    ':hover': {
      borderColor: FICColors.inputDisabled,
    },
  }

  const borderColor = props.isValid === false ? FICColors.red : FICColors.blue

  const customControlStyle = {
    borderColor: state.isFocused
      ? borderColor
      : props.isValid === false
      ? FICColors.red
      : FICColors.borderColor,
    boxShadow:
      props.isValid === false || state.isFocused
        ? '0 0 0 2px ' +
          borderColor +
          '3D,0 0 0 0px ' +
          borderColor +
          ',inset 0 1px 2px 0 rgba(51,51,51,0.12);'
        : 'none',
    ':hover': {
      borderColor: state.isFocused
        ? borderColor
        : props.isValid === false
        ? FICColors.red
        : FICColors.borderColor,
    },
  }

  style = state.isDisabled
    ? { ...style, ...disabledControlStyle }
    : { ...style, ...customControlStyle }

  return style
}

function getOptionComponentStyle(state) {
  const style = {}

  if (state.isFocused) {
    style.backgroundColor = 'transparent'
  }

  if (state.isSelected) {
    style.backgroundColor = FICColors.lightBlue
    style.color = FICColors.blue
    style.display = 'flex'
    style.alignItems = 'center'
    style['&:hover'] = { backgroundColor: FICColors.lightBlue }
    style['.fa, .icon'] = { display: 'inline!important' }
  }

  return style
}
