import { ValueOf } from '../../../common/types'
import { Webinar } from '../../../components/pages/accountant/pages/common/types'

export type AccountantCall = {
  callDate: string
  id: number
  notes: string
  phone: string
  reason: string
  requesterName: string
  requesterSurname: string
}

type LocalStorageStore = {
  hasuraAccessToken: string
  accessToken: string
  accountantCall: AccountantCall | null
  accountantCallBannerVisible: boolean
  accountantNotifications: number
  accountantWebinars: number
  activeWebinars: number[]
  upcomingWebinar: Webinar | null
  bankReconciliationWaitingActivation: BankReconciliationWaitingActivation
  expensesListSearchText: string
  expensesListSelectedMonths: number[]
  ficBannerMessage: string
  fiscalYear: number
  hasAutomaticRenewal: boolean
  hideSignUpPushNotification: boolean
  invoicesAnchor: string
  isPushNotificationsEnabled: boolean
  isSnowEnabled: boolean
  lastAccessTokenAction: lastAccessTokenActionType
  lastChangedAtFiscalYear: number
  lastDeletedConnectionId: number
  lastOpenedSidebarMenu: string
  lastSelectedKey: string
  maintenance: MaintenanceStore
  marketingModalNextAppearance: string
  newWebinarsCount: number
  nextEditAliquotaAskDate: number
  nextPrivacyAskDate: number
  notNowPrivacyCount: number
  pendingExpensesRegistration: SinglePendingExpenseRegistrationType[]
  sessionId: string
  sidebarCounters: SidebarCounters
  totalNotifications: number
  unreadNotifications: number
  version: string
  wantsManageCompany: boolean
}
export default LocalStorageStore

export type SidebarCounterKey =
  | 'bankStatementsCounter'
  | 'calendarCounter'
  | 'cessioneCreditoCounter'
  | 'expensesCounter'
  | 'pendingExpensesCounter'
  | 'primaNotaCounter'
  | 'ribaCounter'
  | 'solicitsCounter'
  | 'taxesCounter'
  | 'tsPayCounter'

export type SidebarCounters = Record<SidebarCounterKey, number>

export type BankReconciliationWaitingActivation = {
  paymentAccountId?: number | null
  bankStatementId?: number
}

export interface MaintenanceStore {
  message: string
  startAt: string
}

export enum SinglePendingExpenseRegistrationStatusType {
  pending = 'pending',
  skipped = 'skipped',
  done = 'done',
}

export interface SinglePendingExpenseRegistrationType {
  id: number
  status: SinglePendingExpenseRegistrationStatusType
}

export type lastAccessTokenActionType =
  | ''
  | 'changedPassword'
  | 'changedCompany'
  | 'changedEmail'
  | 'disconnectedDevices'

export type localStorageKeys = keyof LocalStorageStore
export type localStorageValues = ValueOf<LocalStorageStore>
