import { InputText } from '@fattureincloud/fic-design-system'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'

type SearchInputProps = {
  className?: string
  disabled?: boolean
  loading?: boolean
  onSearch: (search: string) => void
  placeholder?: string
  value?: string
}

const SearchInput = ({
  className,
  disabled,
  loading,
  onSearch,
  placeholder = 'Ricerca rapida',
  value,
}: SearchInputProps): JSX.Element => {
  const [search, setSearch] = useState(value ?? '')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const inputHasFocus = useRef(false)
  const searchRef = useRef<HTMLInputElement>(null)
  const prevSearch = useRef('')

  useEffect(() => {
    if (value !== undefined && value !== prevSearch.current) {
      setSearch(value)
      setDebouncedSearch(value)
    }
  }, [value])

  const debouncedSearchFn = useMemo(
    () =>
      debounce((s: string) => {
        setDebouncedSearch(s)
      }, 500),
    []
  )

  useEffect(() => {
    debouncedSearchFn.cancel()
    debouncedSearchFn(search)
  }, [debouncedSearchFn, search])

  useEffect(() => {
    // Give back the focus to the input when the loading is finished
    if (!loading && inputHasFocus.current) {
      searchRef.current?.focus()
    }
  }, [loading])

  useEffect(() => {
    if (prevSearch.current !== debouncedSearch) {
      prevSearch.current = debouncedSearch
      onSearch(debouncedSearch)
    }
  }, [debouncedSearch, onSearch])

  return (
    <InputText
      actionIcon={{ icon: faTimes, onClick: () => setSearch('') }}
      className={className}
      disabled={disabled || loading}
      inputSize='large'
      leadingIcon={{ icon: faSearch }}
      onBlur={() => {
        inputHasFocus.current = false
      }}
      onFocus={() => {
        inputHasFocus.current = true
      }}
      placeholder={placeholder}
      ref={searchRef}
      setValue={setSearch}
      value={search}
    />
  )
}

export default SearchInput
