import { Draft, PayloadAction } from '@reduxjs/toolkit'

import LocalStorageStore, { AccountantCall } from '../localStorageStoreTypes'
import { updateLocalStorageObject } from '../localStorageUtils'

export const setAccountantCallBanner = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<{ visible: boolean }>
): LocalStorageStore => {
  updateLocalStorageObject({
    accountantCallBannerVisible: action.payload.visible,
  })

  return { ...state, accountantCallBannerVisible: action.payload.visible }
}

export const setAccountantCall = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<AccountantCall | null>
): LocalStorageStore => {
  updateLocalStorageObject({ accountantCall: action.payload })
  return { ...state, accountantCall: action.payload }
}
