import { isKey } from '../tsUtils'

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>?@[\\\]^_{|}~])[A-Za-z\d!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]{8,}$/

const LOWER_CHARS_REGEX = /[a-z]/
const UPPER_CHARS_REGEX = /[A-Z]/
const NUMBERS_REGEX = /[\d]/
const SPECIALS_CHARS_REGEX = /[!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]/
const INVALID_CHARS_REGEX = /[^a-zA-Z\d!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]/

export type PasswordValidation = {
  all: boolean
  length: boolean
  lower: boolean
  numbers: boolean
  special: boolean
  upper: boolean
  invalid: boolean
}

export function validatePassword(pass: string, split: true): PasswordValidation
export function validatePassword(pass: string, split: false): boolean
export function validatePassword(pass: string): boolean
export function validatePassword(
  pass: string,
  split = false
): boolean | PasswordValidation {
  if (split) {
    const v: PasswordValidation = {
      all: true,
      length: pass.length > 7,
      lower: LOWER_CHARS_REGEX.test(pass),
      numbers: NUMBERS_REGEX.test(pass),
      special: SPECIALS_CHARS_REGEX.test(pass),
      upper: UPPER_CHARS_REGEX.test(pass),
      invalid: INVALID_CHARS_REGEX.test(pass),
    }
    v.all = Object.keys(v).every(key =>
      isKey(key, v) ? (key === 'invalid' ? !v[key] : v[key]) : false
    )
    return v
  }

  return PASSWORD_REGEX.test(pass)
}
