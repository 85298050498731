import { Draft, PayloadAction } from '@reduxjs/toolkit'

import PreferencesStore, { SetPreference } from '../preferencesStoreTypes'

export const setPreference = <T extends keyof PreferencesStore>(
  state: Draft<PreferencesStore>,
  action: PayloadAction<SetPreference<T>>
): void => {
  const { name, value } = action.payload
  state[name] = value
}
