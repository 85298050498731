import { Nullish, Primitives, UnknownObject } from '../types'
import {
  CodeMFAFactor,
  DeviceMFAFactor,
  MFAFactor,
  PhoneMFAFactor,
} from '../types/auth'

export const isset = <T>(value: Nullish<T>): value is T =>
  value !== undefined && value !== null

/**
 * Checks if a value is empty. Empty values are:
 * - undefined
 * - null
 * - empty string
 * - empty array
 * - empty object
 * @param {unknown} value - The value to check.
 * @returns {boolean}
 */
export const isEmpty = (value: unknown): boolean => {
  if (value === undefined || value === null) return true
  if (typeof value === 'string') return value === ''
  if (Array.isArray(value)) return value.length === 0
  if (value && typeof value === 'object') return Object.keys(value).length === 0
  return false
}

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number'

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const isObject = (value: unknown): value is UnknownObject =>
  isset(value) && typeof value === 'object'

export const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean'

export const isArrayOf = <T extends Primitives>(
  array: unknown[]
): array is T[] =>
  array.length === 0
    ? true
    : array.every(
        (value, i, arr) =>
          arr[0] === null ? value === null : typeof value === typeof arr[0] // cause typeof null === 'object'
      )

export const isDeviceMFAFactor = (
  f: MFAFactor | undefined
): f is DeviceMFAFactor => f !== undefined && f.type === 'device'

export const isPhoneMFAFactor = (
  f: MFAFactor | undefined
): f is PhoneMFAFactor => f !== undefined && f.type === 'phone'

export const isCodeMFAFactor = (f: MFAFactor | undefined): f is CodeMFAFactor =>
  f !== undefined && f.type === 'authenticator'

export const isKey = <T extends UnknownObject>(
  key: string | symbol | number,
  obj: T
): key is keyof T => key in obj
