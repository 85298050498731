import { Icon } from '@fattureincloud/fic-design-system'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'

import { StyledLink } from './styled'

export const InformativaPrivacyCerved = () => (
  <StyledLink href={'https://www.informativaprivacyancic.it'} target={'_blank'}>
    l'informativa privacy <Icon icon={faExternalLink} />
  </StyledLink>
)

export const InformativaNoteLegaliCerved = () => (
  <StyledLink
    target={'_blank'}
    href={'https://indicepa.gov.it/ipa-portale/note-legali'}
  >
    le note legali <Icon icon={faExternalLink} />
  </StyledLink>
)

export const InformativaDatasetCerved = () => (
  <StyledLink
    target={'_blank'}
    href={'https://indicepa.gov.it/ipa-dati/dataset'}
  >
    il dataset <Icon icon={faExternalLink} />
  </StyledLink>
)
