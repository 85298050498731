import {Icon} from '@fattureincloud/fic-design-system'
import {faCheck, faMinus} from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import styled, {css} from 'styled-components/macro'

import FICColors from '../../../common/styles/FICColors'

function FICCheckboxImpl(props) {
    return (
        <Container size={props.size} style={props.style} className={props.className}>
      <span onClick={() => onChange(props)}>
        {props.renderCheckbox
            ? props.renderCheckbox(props)
            : renderCheckbox(props)}
      </span>
        </Container>
    )
}

function renderCheckbox(props) {
    return (
        <Fragment>
            <Checkbox
                isChecked={props.value}
                isDisabled={props.isDisabled}
                size={props.size}
            >
                {props.value && (
                    <Icon icon={props.isIndeterminate ? faMinus : faCheck}/>
                )}
            </Checkbox>
            <Label size={props.size} isDisabled={props.isDisabled}>
                {props.text}
            </Label>
        </Fragment>
    )
}

function onChange(props) {
    if (props.isDisabled || !props.onChange) {
        return
    }

    props.onChange(!props.value)
}

const Container = styled.div`
  height: ${props => (props.size === 'large' ? '20px' : '16px')};
`

const Checkbox = styled.span`
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: ${FICColors.white};
  border: 1px solid ${props => (props.isChecked ? FICColors.blue : FICColors.borderColor)};
  border-radius: 4px;
  width: ${props => (props.size === 'large' ? '20px' : '16px')};
  height: ${props => (props.size === 'large' ? '20px' : '16px')};
  line-height: ${props => (props.size === 'large' ? '18px' : '14px')};
  cursor: pointer;
  text-align: center;
  color: ${FICColors.white};
  ${props =>
          props.isChecked &&
          css`
            background-color: ${FICColors.blue};
          `}
  ${props =>
          props.isDisabled &&
          css`
            background-color: ${FICColors.inputDisabled};
            border-color: ${FICColors.inputDisabled};
            cursor: not-allowed;
            ${props =>
                    props.isChecked &&
                    css`
                      background-color: ${FICColors.button.primary.blue.disabled};
                      border-color: ${FICColors.button.primary.blue.disabled};
                    `}
          `}
`

const Label = styled.span`
  display: inline-block;
  vertical-align: top;
  color: ${FICColors.black};
  font-size: ${props => (props.size === 'large' ? '16px' : '14px')};
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-left: 8px;
  cursor: pointer;
  ${props =>
          props.isDisabled &&
          css`
            color: ${FICColors.inputDisabledText};
            cursor: not-allowed;
          `}
`

const FICCheckbox = styled(FICCheckboxImpl)``

FICCheckbox.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.bool,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'large']),
    renderCheckbox: PropTypes.func,
    isIndeterminate: PropTypes.bool,
    styled: PropTypes.object,
}

FICCheckbox.defaultProps = {
    text: '',
    value: false,
    onChange: null,
    isDisabled: false,
    size: 'medium',
    renderCheckbox: undefined,
    isIndeterminate: false,
    style: undefined,
}

export default FICCheckbox
