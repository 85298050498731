import { Reducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import { accountant, AccountantStore } from '../features/accountant'
import companies from '../features/auth/companies/companiesSlice'
import { companyDetails } from '../features/auth/companyDetails'
import connections from '../features/auth/connections/connectionsSlice'
import currentSession from '../features/auth/currentSession/currentSessionSlice'
import person from '../features/auth/person/personSlice'
import AuthStore from '../features/auth/types'
import configurations from '../features/configurations/configurationsSlice'
import ConfigurationsStore from '../features/configurations/configurationsStoreTypes'
import gaTags from '../features/gaTags/gaTagsSlice'
import { GaTagsStore } from '../features/gaTags/gaTagsStoreTypes'
import helpOverlay from '../features/helpOverlay/helpOverlaySlice'
import HelpOverlayStore from '../features/helpOverlay/helpOverlayStoreTypes'
import loaders from '../features/loaders/loadersSlice'
import LoadersStore from '../features/loaders/loadersStoreTypes'
import { localStorage, LocalStorageStore } from '../features/localStorage'
import { mfa, MFAStore } from '../features/mfa'
import modals from '../features/modals/modalsSlice'
import ModalsStore from '../features/modals/modalsStoreTypes'
import modalsConfig from '../features/modalsConfig/modalsConfigSlice'
import ModalsConfigStore from '../features/modalsConfig/modalsConfigStoreTypes'
import { preferenceReducer, PreferencesStore } from '../features/preferences'
import { question, QuestionStore } from '../features/question'
import support from '../features/support/supportSlice'
import SupportStore from '../features/support/supportStoreTypes'
import { swapStorage } from '../features/swapStorage'
import SwapStorageStore from '../features/swapStorage/swapStorageTypes'
import { tsPay, TSPayStore } from '../features/tsPay'
import visibilities from '../features/visibilities/visibilitiesSlice'
import VisibilitiesStore from '../features/visibilities/visibilitiesStoreTypes'

export interface GlobalStore {
  accountant: AccountantStore
  auth: AuthStore
  configurations: ConfigurationsStore
  helpOverlay: HelpOverlayStore
  loaders: LoadersStore
  localStorage: LocalStorageStore
  mfa: MFAStore
  modals: ModalsStore
  modalsConfig: ModalsConfigStore
  preferences: PreferencesStore
  support: SupportStore
  swapStorage: SwapStorageStore
  tsPay: TSPayStore
  visibilities: VisibilitiesStore
  gaTags: GaTagsStore
  question: QuestionStore
}

const createRootReducer = (): Reducer =>
  combineReducers<GlobalStore>({
    accountant,
    auth: combineReducers({
      person,
      connections,
      companies,
      companyDetails,
      currentSession,
    }),
    configurations,
    helpOverlay,
    loaders,
    localStorage,
    mfa,
    modals,
    modalsConfig,
    preferences: preferenceReducer,
    support,
    swapStorage,
    tsPay,
    visibilities,
    gaTags,
    question,
  })

export default createRootReducer
