import { Button } from '@fattureincloud/fic-design-system'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { isProduction } from '../../../common/environment'
import useCountdown from '../../../common/hooks/useCountdown'
import useHistoryListener from '../../../common/hooks/useHistoryListener'
import { reloadPage } from '../../../common/utils/urlUtils'
import { getIsSupport } from '../../../redux/features/support/supportSelectors'
import telescope from './../../../resources/images/illustrations/telescope.svg'

const niceMessage = 'C’è qualche problema di caricamento, niente di grave.'

const onClick = () => reloadPage()

const ErrorPage = ({
  error: { message = niceMessage },
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  const isSupport = useSelector(getIsSupport)
  const countDownTime = isProduction && !isSupport ? 30 : 30000
  const errorMessage = isProduction && !isSupport ? niceMessage : message

  useHistoryListener(resetErrorBoundary)

  const timeLeft = useCountdown(countDownTime, reloadPage)

  return (
    <Wrapper>
      <Illustration src={telescope} alt={'telescope-error-icon'} />
      <h4>{errorMessage}</h4>
      <div>
        <SubText>Nuovo tentativo tra {timeLeft} secondi. </SubText>
        <Retry
          text={'Riprova ora'}
          type={'text'}
          size={'large'}
          onClick={onClick}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Illustration = styled.img`
  margin-bottom: 20px;
  width: 230px;
`

const SubText = styled.div`
  display: inline;
  color: ${({ theme }) => theme.palette.grey['500']};
`

const Retry = styled(Button)`
  display: inline-block;
`

export default ErrorPage
