import { useSelector } from 'react-redux'

import { GlobalStore } from '../app/rootReducer'

/**
 * Selects only a set of values from a redux store part (first level reducers => keyof GlobalStore).
 * Implements an automatic check to update the components listening to the values only when needed.
 */
const useValuesSelector = <
  T extends keyof GlobalStore,
  K extends keyof GlobalStore[T]
>(
  storeKey: T,
  keys: K[]
): Pick<GlobalStore[T], K> => {
  // If the equality function return true, the selected fields haven't changed
  // use Object.is, like React, for comparing objects
  const store = useSelector(
    (state: GlobalStore) => state[storeKey],
    (prev, curr) =>
      keys.every(k =>
        typeof prev[k] === 'object' && typeof curr[k] === 'object'
          ? Object.is(prev[k], curr[k])
          : prev[k] === curr[k]
      )
  )

  // Returns only selected keys
  return keys.reduce(
    (prevObj, currKey) => ({ ...prevObj, [currKey]: store[currKey] }),
    {} as Pick<GlobalStore[T], K>
  )
}

export default useValuesSelector
