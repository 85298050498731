import { download } from './download'

class FICNetwork {}

FICNetwork.download = (url, method = 'GET', params, filename) => {
  const xhr = new XMLHttpRequest()
  xhr.open(method, url, true)
  xhr.responseType = 'blob'

  if (method === 'POST') {
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
  }

  xhr.onload = function (e) {
    if (!filename) {
      const headers = xhr.getAllResponseHeaders().split('\n')

      const disposition = headers.filter(header =>
        header.startsWith('content-disposition')
      )

      if (disposition.length > 0) {
        filename = headerFilename(disposition[0])
      } else {
        filename = url.split('/').pop().split('?')[0]
      }
    }

    download(e.target.response, filename)
  }
  if (method === 'POST') {
    xhr.send($.param(params || {}))
  } else {
    xhr.send()
  }
}

export function headerFilename(disposition) {
  let filename = ''
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  /* decodeURIComponent(filename) */
  return filename
}

FICNetwork.print = url => {
  if (!url.startsWith(window.location.origin)) {
    url = reactGlobalParams.api_host + '/download?url=' + encodeURIComponent(url)
  }
  const w = window.open(url)
  setTimeout(() => {
    try {
      w.print()
    } catch (e) {
      console.log(e)
    }
  }, 1000)
}

export default FICNetwork
