import equal from 'react-fast-compare'

import store from '../../../redux'
import {
  getLocalStorage,
  LOCALSTORAGE_FIC_KEY,
  localStorageKeys,
  setReduxStorage,
} from '../../../redux/features/localStorage'

const skipItemsAlways = ['lastSelectedKey']

const skipItemsOnlyIfLogged = [
  ...skipItemsAlways,
  'accessToken',
  'fiscalYear',
  'invoicesAnchor',
  'lastOpenedSidebarMenu',
  'expensesListSelectedMonths',
  'expensesListSearchText',
]
export const ficConfigListener = (e: StorageEvent): void => {
  if (
    e.newValue &&
    e.newValue !== e.oldValue &&
    e.key === LOCALSTORAGE_FIC_KEY
  ) {
    const localStorageItems = Object.keys(
      getLocalStorage(store.getState())
    ) as Array<localStorageKeys>

    const newLocalStorageStore = JSON.parse(e.newValue)
    const state = store.getState()

    const isLogged = !!state.localStorage['accessToken']

    localStorageItems.forEach(localStorageItem => {
      if (
        (isLogged && skipItemsOnlyIfLogged.includes(localStorageItem)) ||
        skipItemsAlways.includes(localStorageItem)
      ) {
        return
      }

      const newValue = newLocalStorageStore[localStorageItem]
      const reduxValue = state.localStorage[localStorageItem]

      if (!equal(newValue, reduxValue)) {
        store.dispatch(setReduxStorage({ [localStorageItem]: newValue }))
      }
    })
  }
}
