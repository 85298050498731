import { Toast } from '@fattureincloud/fic-design-system'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { BECallStatus } from '../../../../common/types'
import SwapStorageStore, {
  DisconnectAccountantCompany,
  DisconnectCompany,
} from '../swapStorageTypes'

const disconnectAccountantCompany = createAsyncThunk<
  DisconnectAccountantCompany,
  DisconnectCompany,
  {
    rejectValue: DisconnectAccountantCompany
  }
>(
  'swapStorage/disconnectAccountantCompany',
  async (disconnectCompany, thunkApi) => {
    try {
      await Api.delete({
        request: `accountant/clients/${disconnectCompany.id}`,
      })
      return {
        company: undefined,
        fetchStatus: BECallStatus.SUCCESS,
      }
    } catch (e) {
      return thunkApi.rejectWithValue({
        company: undefined,
        fetchStatus: BECallStatus.FAILED,
      })
    }
  }
)

export const addDisconnectAccountantCompany = (
  builder: ActionReducerMapBuilder<SwapStorageStore>
): void => {
  builder
    .addCase(disconnectAccountantCompany.pending, state => {
      state.disconnectAccountantCompany.fetchStatus = BECallStatus.LOADING
    })
    .addCase(disconnectAccountantCompany.fulfilled, (state, action) => {
      Toast.success('Cliente scollegato con successo')
      state.disconnectAccountantCompany = action.payload
    })
    .addCase(disconnectAccountantCompany.rejected, (state, action) => {
      if (action.payload) {
        state.disconnectAccountantCompany = action.payload
      }
    })
}

export default disconnectAccountantCompany
