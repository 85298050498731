import { Draft, PayloadAction } from '@reduxjs/toolkit'

import SwapStorageStore, { TSPayPOSPayments } from '../swapStorageTypes'

export const setTSPayPOSPayments = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<TSPayPOSPayments | undefined>
): void => {
  state.tsPayPOSPayments = action.payload
}
