import { BECallStatus } from '../../../common/types'
import { RecoveryCodeStatus } from '../../../common/types/auth'
import { GlobalStore } from '../../app/rootReducer'
import MFAStore, { RecoveryCode } from './mfaTypes'

export const selectMFA = (state: GlobalStore): MFAStore => state.mfa

export const selectRecoveryCode = (state: GlobalStore): RecoveryCode =>
  state.mfa.recoveryCode

export const selectCanUseRecoveryCode = (state: GlobalStore): boolean =>
  state.mfa.recoveryCode.fetchStatus === BECallStatus.SUCCESS &&
  state.mfa.recoveryCode.status === RecoveryCodeStatus.ACTIVE

export const selectRecoveryCodeBlocked = ({
  mfa: { recoveryCode },
}: GlobalStore): boolean =>
  recoveryCode.fetchStatus !== BECallStatus.IDLE &&
  recoveryCode.status === RecoveryCodeStatus.BLOCKED

export const selectCanGenerateRecoveryCode = ({ mfa }: GlobalStore): boolean =>
  mfa.recoveryCode.fetchStatus === BECallStatus.SUCCESS &&
  mfa.recoveryCode.status !== RecoveryCodeStatus.ACTIVE &&
  mfa.recoveryCode.status !== RecoveryCodeStatus.DISABLED
