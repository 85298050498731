import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class FICBaseButton extends Component {
  constructor(props) {
    super(props)
    this.clickable = true
  }

  render() {
    const {
      className,
      href,
      isDisabled,
      isLoading,
      onClick,
      renderButton,
      style,
      target,
    } = this.props
    return (
      <a
        className={className + (!isDisabled ? ' ajax-link' : '')}
        onClick={() => {
          if (!this.clickable || isLoading || isDisabled) {
            return
          }

          this.clickable = false
          setTimeout(() => {
            this.clickable = true
          }, 500)
          if (onClick) {
            onClick()
          }
        }}
        href={!isDisabled ? href : undefined}
        target={target}
        style={style}
      >
        {renderButton(this.props, this.state)}
      </a>
    )
  }
}

FICBaseButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderButton: PropTypes.func,
}

FICBaseButton.defaultProps = {
  className: undefined,
  href: undefined,
  target: undefined,
  onClick: undefined,
  style: undefined,
  isDisabled: false,
  isLoading: false,
  renderButton: undefined,
}
