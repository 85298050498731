import { Draft, PayloadAction } from '@reduxjs/toolkit'

import LocalStorageStore from '../localStorageStoreTypes'
import { updateLocalStorageObject } from '../localStorageUtils'

export const setPendingExpensesCount = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<{ pendingExpensesCount: number }>
): LocalStorageStore => {
  const { pendingExpensesCount } = action.payload
  updateLocalStorageObject({
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter: pendingExpensesCount,
    },
  })
  return {
    ...state,
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter: pendingExpensesCount,
    },
  }
}

export const incrementPendingExpensesCount = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<{ increment: number }>
): LocalStorageStore => {
  const { increment } = action.payload
  updateLocalStorageObject({
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter:
        state.sidebarCounters.pendingExpensesCounter + increment,
    },
  })
  return {
    ...state,
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter:
        state.sidebarCounters.pendingExpensesCounter + increment,
    },
  }
}

export const decrementPendingExpensesCount = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<{ decrement: number }>
): LocalStorageStore => {
  const { decrement } = action.payload
  updateLocalStorageObject({
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter:
        state.sidebarCounters.pendingExpensesCounter - decrement,
    },
  })
  return {
    ...state,
    sidebarCounters: {
      ...state.sidebarCounters,
      pendingExpensesCounter:
        state.sidebarCounters.pendingExpensesCounter - decrement,
    },
  }
}
