class FICColors {}

FICColors.primary = '#0D97D5'
FICColors.success = '#00875A'
FICColors.danger = '#DE350B'
FICColors.warning = '#f57100'
FICColors.gray = '#7e91aa'
FICColors.yellow = '#FF991F'

FICColors.borderPrimary = '#007ce4'
FICColors.borderSuccess = '#36c200'
FICColors.borderDanger = '#cc0000'
FICColors.borderWarning = '#f57100'
FICColors.borderGray = '#888e97'

FICColors.hoverPrimary = '#005ba7'
FICColors.hoverSuccess = '#4cbe00' // TODO
FICColors.hoverDanger = '#e72500' // TODO
FICColors.hoverWarning = '#f57100' // TODO
FICColors.hoverGray = '#7e91aa' // TODO
FICColors.hoverYellow = '#ff8a00'

FICColors.textDefault = '#555'
FICColors.textDescription = '#aaaaaa'
FICColors.textGray = '#888'
FICColors.textLink = '#0088cc'
FICColors.textSuccess = '#00b600'
FICColors.textDanger = '#e70000'
FICColors.textDisabled = '#b1b1b1'

FICColors.white = '#fff'
FICColors.lightGray = '#fafafa'
FICColors.defaultBlue = '#0094D4'

FICColors.lightBorderColor = '#d5d5d563'
FICColors.shadowColor = 'rgba(0, 0, 0, 0.06)'

FICColors.messageTextSuccess = '#3c763d'
FICColors.messageTextInfo = '#31708f'
FICColors.messageTextWarning = '#8a6d3b'
FICColors.messageTextDanger = '#a94442'

FICColors.messageBorderSuccess = '#d6e9c6'
FICColors.messageBorderInfo = '#bce8f1'
FICColors.messageBorderWarning = '#faebcc'
FICColors.messageBorderDanger = '#ebccd1'

FICColors.messageBackgroundSuccess = '#dff0d8'
FICColors.messageBackgroundInfo = '#d9edf7'
FICColors.messageBackgroundWarning = '#fcf8e3'
FICColors.messageBackgroundDanger = '#f2dede'

FICColors.actionButton = { gray: '#d1d1d1' }

export default FICColors
