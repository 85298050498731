import { PersonStore } from './personStoreTypes'

const personInitialState: PersonStore = {
  birthDate: '',
  firstName: '',
  lastName: '',
  email: '',
  id: 0,
  hash: '',
  customerlyHash: '',
  passwordManagement: {
    manualChange: false,
    forceChange: false,
    expired: false,
  },
  emailConfirmationState: {
    needConfirmation: false,
  },
  needTermsOfServiceConfirmation: false,
  survey: {
    token: null,
    isMandatory: true,
    lastAction: undefined,
  },
  needEditVats: false,
  newNavEnabled: false,
  hasToShowSubaccountsFeatureSuggestion: false,
  consents: [],
}

export default personInitialState
