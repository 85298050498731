import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getCurrentConnection } from '../../../../redux/features/auth/connections/connectionsSelectors'

const accountantPaths = [
  '/accountant',
  '/helpsecurity',
  '/settings/account',
  '/settings/notifications',
  '/settings/apps',
]

export type companyGtmId = number | undefined

const useCompanyIdGtm = (): companyGtmId => {
  const { pathname } = useLocation()

  const currentConnection = useSelector(getCurrentConnection)

  if (!currentConnection) {
    return undefined
  }

  const { controlledId, controllerId } = currentConnection

  if (controllerId && accountantPaths.some(path => pathname.startsWith(path))) {
    return controllerId
  }

  return controlledId
}

export default useCompanyIdGtm
