import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { BECallStatus } from '../../../../common/types'
import TSPayStore from '../tsPayTypes'

const activateOutgoingPayments = createAsyncThunk<
  Pick<TSPayStore, 'fetchStatus' | 'outgoingPayments'>,
  undefined,
  {
    rejectValue: Partial<TSPayStore>
  }
>('tsPay/activateOutgoingPayments', async (_, thunkApi) => {
  try {
    await Api.post({ request: 'ts_pay/outgoing_payments' })

    return {
      outgoingPayments: true,
      fetchStatus: BECallStatus.SUCCESS,
    }
  } catch (e) {
    return thunkApi.rejectWithValue({
      fetchStatus: BECallStatus.FAILED,
    })
  }
})

export const addActivateOutgoingPaymentsCases = (
  builder: ActionReducerMapBuilder<TSPayStore>
): void => {
  builder
    .addCase(activateOutgoingPayments.pending, state => {
      state.fetchStatus = BECallStatus.LOADING
    })
    .addCase(activateOutgoingPayments.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(activateOutgoingPayments.rejected, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
}

export default activateOutgoingPayments
