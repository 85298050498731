import { GlobalStore } from '../../../app/rootReducer'
import {
  EmailConfirmationType,
  PasswordManagementType,
  PersonStore,
} from './personStoreTypes'

export const getPerson = (state: GlobalStore): PersonStore => state.auth.person

export const getPasswordManagement = (
  state: GlobalStore
): PasswordManagementType => getPerson(state).passwordManagement

export const getPersonId = (state: GlobalStore): number => getPerson(state).id

export const getEmailConfirmationState = (
  state: GlobalStore
): EmailConfirmationType => getPerson(state).emailConfirmationState
