import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import store from '../../redux'
import useValuesSelector from '../../redux/common/useValuesSelector'
import { setLocalStorage } from '../../redux/features/localStorage'
import { getModals } from '../../redux/features/modals/modalsSelectors'
import Api from '../network/Api'
import useFocusSharedTabs from './useFocusSharedTabs'

interface NotificationsCounter {
  data: {
    unread: number
    total: number
  }
}

export const updateNotificationsCounter = (): void => {
  void Api.get<NotificationsCounter>({
    request: 'notifications/initial_data',
    noAlerts: true,
  }).then(response => {
    const { total, unread } = response.data.data
    store.dispatch(
      setLocalStorage({
        unreadNotifications: unread,
        totalNotifications: total,
      })
    )
  })
}

const useNotificationsPolling = (): void => {
  const { showCompanyChanged } = useSelector(
    getModals,
    (prev, curr) => prev.showCompanyChanged === curr.showCompanyChanged
  )
  const { isPushNotificationsEnabled } = useValuesSelector('localStorage', [
    'isPushNotificationsEnabled',
  ])

  const doNotificationsPolling = useCallback((): void => {
    if (!isPushNotificationsEnabled && !showCompanyChanged) {
      updateNotificationsCounter()
    }
  }, [isPushNotificationsEnabled, showCompanyChanged])
  useFocusSharedTabs(doNotificationsPolling, 5 * 60 * 1000)
}

export default useNotificationsPolling
