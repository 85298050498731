import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GlobalParamsPayload, setFromApi } from '../../app/actions/setFromApi'
import initialState from './supportInitialState'

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.support
      }
    )
  },
})

const support = supportSlice.reducer

export default support
