export const getFilenameFromUrl = (url: string | null | undefined): string => {
  if (!url) return ''
  let filename = url.split('/').reverse()[0]
  const questionMark = filename.indexOf('?')
  if (questionMark >= 0) {
    filename = filename.split('?')[0]
  }
  return filename
}

export const getFileExtension = (url: string | null): string => {
  if (!url) return ''
  const filename = getFilenameFromUrl(url)
  const pos = filename.lastIndexOf('.')
  return pos < 1 ? '' : filename.slice(pos + 1).toLocaleLowerCase()
}

export const isPDF = (file: string): boolean => getFileExtension(file) === 'pdf'

export const isIMG = (file: string): boolean =>
  ['jpg', 'png', 'gif'].includes(getFileExtension(file))

export const isXML = (file: string): boolean => getFileExtension(file) === 'xml'

export const getFileNameFromURL = (url: string | null | undefined): string =>
  url ? url.substring(url.lastIndexOf('/') + 1) : ''

export const fileDownload = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string
): void => {
  const blob = new Blob([data], { type: 'application/octet-stream' })
  const blobURL = window.URL?.createObjectURL
    ? window.URL.createObjectURL(blob)
    : window.webkitURL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }, 200)
}
