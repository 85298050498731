import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { css } from 'styled-components/macro'

import FICNumbers from '../../../common/general/js/FICNumbers'
import FICColors from '../../../common/styles/FICColors'
import { LefIcon, RightIcon } from './icons'
import { LeftText, RightText } from './sideText'

class FICInputTextImpl extends Component {
  constructor(props) {
    super(props)
    this.textInput = null
    this.scheduledChange = null
    this.lastOnChangeValue = props.value || undefined
    this.state = {
      useInternalValue: false,
      internalValue: props.value || undefined,
      clearableVisible: props.clearable && props.value,
      // allowAutoFocusChange: true,
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        this.focus()
        setTimeout(() => {
          this.setState({ useInternalValue: false })
        }, 0)
      }, 100)
    }
  }

  focus() {
    if (this.textInput) {
      this.textInput.focus()
    }
  }

  blur() {
    if (this.textInput) {
      this.textInput.blur()
    }
  }

  /*
  TODO: togliere jQuery e usare react
  nextInput () {
    let inputs = $(':input')
    let nextInput = inputs.get(inputs.index(this.textInput) + 1)
    if (nextInput) {
      nextInput.focus()
    }
  } */

  uncontrolledGetValue() {
    return this.textInput.value
  }

  uncontrolledSetValue(value) {
    this.textInput.value = value
  }

  shouldUseInternalValue = () => {
    return (
      this.props.changeOnBlur ||
      (!this.props.uncontrolled && this.props.changeDelay)
    )
  }

  getDisplayValue = () => {
    const props = this.props
    const state = this.state
    return props.uncontrolled
      ? undefined
      : this.shouldUseInternalValue() && state.useInternalValue
      ? state.internalValue || ''
      : props.transform
      ? props.transform(props.value)
      : props.value != null
      ? props.value
      : ''
  }

  handleChange = e => {
    const props = this.props
    const value = e.target.value
    // this.setState({ allowAutoFocusChange: false })
    if (props.clearable) {
      this.setState({ clearableVisible: value })
    }
    if (this.shouldUseInternalValue()) {
      this.setState({ internalValue: value, useInternalValue: true })
    }
    if (props.changeDelay) {
      if (this.scheduledChange) {
        clearTimeout(this.scheduledChange)
      }
      this.scheduledChange = setTimeout(() => {
        this.executeOnChange(value)
      }, props.changeDelay)
    } else {
      this.executeOnChange(value)
    }
  }

  executeOnChange = value => {
    const props = this.props
    if (
      props.onChange &&
      (value !== this.lastOnChangeValue || value !== props.value)
    ) {
      this.lastOnChangeValue = value
      props.onChange(value)
    }
  }

  handleFocus = e => {
    const props = this.props
    if (this.shouldUseInternalValue()) {
      this.setState({
        useInternalValue: true,
        internalValue: props.value,
      })
    }
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  handleBlur = e => {
    const props = this.props
    const value = e.target.value
    this.setState({ useInternalValue: false })
    if (props.changeOnBlur) {
      this.executeOnChange(value)
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  render() {
    let {
      clearable,
      leftIcon,
      leftIconClick,
      leftText,
      onChange,
      rightIcon,
      rightIconClick,
      rightText,
      style,
      ...props
    } = this.props
    const FICInputTextTag = props.type === 'multiline' ? 'textarea' : 'input'

    if (props.isValid === true) {
      rightIcon = 'check'
    }

    // Textarea
    const style2 =
      FICInputTextTag === 'textarea' && props.lines > 0
        ? { height: 30 + 25 * (props.lines - 1) }
        : {}
    style = Object.assign({}, style, style2)
    // Clearable
    if (clearable) {
      rightIcon = this.state.clearableVisible ? 'close' : null
      rightIconClick = onChange
        ? () => {
            const prevValue = this.uncontrolledGetValue()
            if (props.uncontrolled) {
              this.uncontrolledSetValue('')
            }
            if (prevValue !== '') {
              this.setState({ clearableVisible: false })
              this.executeOnChange('')
            }
          }
        : rightIconClick
    }
    // Advanced options style
    if (leftIcon) {
      Object.assign(style, { paddingLeft: 28 })
    }
    if (rightIcon) {
      Object.assign(style, { paddingRight: 28 })
    }
    leftText =
      leftText && (typeof leftText === 'function' ? leftText() : leftText)
    if (leftText) {
      Object.assign(style, {
        paddingLeft:
          14 +
          6 * (typeof leftText === 'string' ? leftText.length : leftText.width),
      })
    }
    rightText =
      rightText && (typeof rightText === 'function' ? rightText() : rightText)
    if (rightText) {
      Object.assign(style, {
        paddingRight:
          14 +
          6 *
            (typeof rightText === 'string'
              ? rightText.length
              : rightText.width),
      })
    }
    // Display
    const value = this.getDisplayValue()
    let result = (
      <FICInputTextTag
        type={props.inputType}
        ref={input => {
          this.textInput = input
        }}
        value={value}
        placeholder={props.placeholder}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        onClick={props.onClick}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}
        className='FICInputText_Input'
        maxLength={props.maxLength}
        style={style || props.style}
        disabled={props.isDisabled}
        readOnly={props.isReadOnly}
        autoComplete='new-password' // Disable Chrome autofill
      />
    )

    // Advanced options wrap
    result = (
      <InputContainer>
        {leftText && <LeftText>{leftText}</LeftText>}
        {leftIcon && (
          <LefIcon
            className={'Icon'}
            icon={leftIcon}
            onClick={leftIconClick}
            style={leftIconClick ? { cursor: 'pointer' } : {}}
            size={props.size}
            isDisabled={props.isDisabled}
            spin={props.leftIconSpin}
          />
        )}
        {result}
        {rightIcon && (
          <RightIcon
            className={'Icon'}
            icon={rightIcon}
            onClick={rightIconClick}
            style={rightIconClick ? { cursor: 'pointer' } : {}}
            size={props.size}
            isValid={props.isValid}
            isDisabled={props.isDisabled}
            spin={props.rightIconSpin}
          />
        )}
        {rightText && <RightText>{rightText}</RightText>}
      </InputContainer>
    )

    if (props.errorText !== undefined) {
      result = (
        <ErrorTextContainer>
          {result}
          {props.errorText && <ErrorText>{props.errorText}</ErrorText>}
        </ErrorTextContainer>
      )
    }

    return <div className={props.className}>{result}</div>
  }
}

const ErrorTextContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
`

const ErrorText = styled.div`
  color: ${FICColors.red};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 4px;
  width: 100%;
`

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const FICInputText = styled(FICInputTextImpl)`
  display: block;
  position: relative;
  ${props =>
    props.width &&
    css`
      width: ${
        typeof props.width === 'number' ? props.width + 'px' : props.width
      }};
    `}
  ${InputContainer} {
    line-height: 30px;
    ${props =>
      props.size === 'large' &&
      css`
        line-height: 30px;
      `}
  }
  .FICInputText_Input {
    background-color: ${FICColors.white};
    border: 1px solid ${FICColors.borderColor};
    border-radius: 4px;
    //min-width: 258px;
    width: 100%;
    color: ${FICColors.black};
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    //line-height: 24px;
    text-align: left;
    padding: 4px 8px;
    box-sizing: border-box;
    ${props =>
      props.size === 'large' &&
      css`
        height: 40px;
        font-size: 16px;
        &::placeholder {
          font-size: 16px;
        }
      `}
    ${props =>
      props.isValid === false &&
      css`
        border-color: ${FICColors.red};
        box-shadow: 0 0 0 2px ${FICColors.red}3D, 0 0 0 0px ${FICColors.red},
          inset 0 1px 2px 0 rgba(51, 51, 51, 0.12);
      `}
    ${props =>
      props.isDisabled &&
      css`
        background-color: ${FICColors.inputDisabled};
        cursor: not-allowed;
      `}
    &:focus {
      border-color: ${FICColors.blue};
      box-shadow: 0 0 0 2px ${FICColors.blue}3D, 0 0 0 0px ${FICColors.blue},
        inset 0 1px 2px 0 rgba(51, 51, 51, 0.12);
      ${props =>
        props.isValid === false &&
        css`
          border-color: ${FICColors.red};
          box-shadow: 0 0 0 2px ${FICColors.red}3D, 0 0 0 0px ${FICColors.red},
            inset 0 1px 2px 0 rgba(51, 51, 51, 0.12);
        `}
    }
    &::placeholder {
      color: ${FICColors.placeholder};
      font-size: 14px;
    }
  }
`

FICInputText.dotsToCommasTransform = value => FICNumbers.dotsToCommas(value)

FICInputTextImpl.propTypes = FICInputText.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  maxLength: PropTypes.number,
  style: PropTypes.object,
  uncontrolled: PropTypes.bool,
  changeOnBlur: PropTypes.bool,
  changeDelay: PropTypes.number,
  lines: PropTypes.number,
  transform: PropTypes.func,
  inputType: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  clearable: PropTypes.bool,
  leftIcon: PropTypes.object,
  leftIconClick: PropTypes.func,
  leftIconHover: PropTypes.func,
  leftText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rightIcon: PropTypes.object,
  rightIconClick: PropTypes.func,
  rightIconHover: PropTypes.func,
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  leftIconSpin: PropTypes.bool,
  rightIconSpin: PropTypes.bool,
  size: PropTypes.string,
  isValid: PropTypes.bool,
  errorText: PropTypes.string,
  validator: PropTypes.object,
  tooltipPlace: PropTypes.string,
  tooltipEvent: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
}

FICInputTextImpl.defaultProps = FICInputText.defaultProps = {
  type: 'text',
  value: undefined,
  placeholder: undefined,
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onKeyPress: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  onClick: undefined,
  maxLength: undefined,
  style: {},
  uncontrolled: false,
  changeOnBlur: true,
  changeDelay: 500,
  lines: 1,
  transform: undefined,
  inputType: 'text',
  isDisabled: undefined,
  isReadOnly: undefined,
  clearable: false,
  leftIcon: undefined,
  leftIconClick: undefined,
  leftIconHover: undefined,
  leftText: undefined,
  rightIcon: undefined,
  rightIconClick: undefined,
  rightIconHover: undefined,
  leftIconSpin: false,
  rightIconSpin: false,
  rightText: undefined,
  size: undefined,
  isValid: undefined,
  errorText: undefined,
  validator: undefined,
  tooltipPlace: undefined,
  tooltipEvent: undefined,
  width: undefined,
  autoFocus: false,
}

export default FICInputText
