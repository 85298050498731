import cloneDeep from 'lodash/cloneDeep'
import equal from 'react-fast-compare'

import FICDateTime from '../../../../common/dateTime'

class FICUtils {}

FICUtils.orderedCompare = (object1, object2) =>
  JSON.stringify(object1) == JSON.stringify(object2)

FICUtils.compare = (object1, object2) => equal(object1, object2)

FICUtils.arrayByDeletingItem = (array, idx) => {
  const newArray = array.slice()
  newArray.splice(idx, 1)
  return newArray
}

FICUtils.arrayByReplacingItem = (array, idx, newValue) => {
  const newArray = array.slice()
  newArray[idx] = newValue
  return newArray
}

/* FICUtils.clone = (object) => {
  return Object.assign({}, object)
} */

FICUtils.clone = object => {
  return cloneDeep(object)
}

FICUtils.setNavigationAddress = address => {
  window.history.pushState(null, null, '/' + address)
}

FICUtils.randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

FICUtils.selectListFromArray = list => {
  if (!list) {
    return []
  }
  return list.map(item => ({ value: item, label: item }))
}

FICUtils.selectListFromSimpleList = list => {
  if (!list) {
    return []
  }
  return list.map(item => ({ value: item.id, label: item.nome }))
}

FICUtils.engToItDate = input => FICDateTime.format(input, 'IT')

FICUtils.itToEngDate = input => FICDateTime.format(input, 'SQL')

FICUtils.engToItDatetime = input => FICDateTime.format(input, 'IT+T')

FICUtils.itToEngDatetime = input => FICDateTime.format(input, 'SQL+T')

FICUtils.todayEngDate = () => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }

  const d = new Date()
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

FICUtils.todayItDate = () => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }

  const d = new Date()
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

FICUtils.parseDate = text =>
  FICDateTime.isValid(text) ? FICDateTime.format(text, 'IT') : null

FICUtils.getPrevMonth = () => {
  const prevMonthDate = new Date()
  prevMonthDate.setDate(0)
  return prevMonthDate.getMonth() + 1
}

FICUtils.controllaPIVA = pi => {
  let validi, i, s, c
  if (pi == '') {
    return ''
  }
  if (pi.indexOf('IT') == 0) {
    pi = pi.substring(2)
  }
  if (pi.length != 11) {
    return (
      'La lunghezza della partita IVA non è\n' +
      'corretta: la partita IVA dovrebbe essere lunga\n' +
      'esattamente 11 caratteri.\n'
    )
  }
  validi = '0123456789'
  for (i = 0; i < 11; i++) {
    if (!validi.includes(pi.charAt(i))) {
      return (
        "La partita IVA contiene il carattere non valido '" +
        pi.charAt(i) +
        "'.\nI caratteri validi sono le cifre.\n"
      )
    }
  }
  s = 0
  for (i = 0; i <= 9; i += 2) {
    s += pi.charCodeAt(i) - '0'.charCodeAt(0)
  }
  for (i = 1; i <= 9; i += 2) {
    c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0))
    if (c > 9) {
      c = c - 9
    }
    s += c
  }
  if ((10 - (s % 10)) % 10 != pi.charCodeAt(10) - '0'.charCodeAt(0)) {
    return (
      'La partita IVA non è valida:\n' +
      'il codice di controllo non corrisponde.\n'
    )
  }
  return ''
}

FICUtils.controllaCF = cf => {
  let validi, i, s, set1, set2, setpari, setdisp
  if (cf == '') {
    return ''
  }
  cf = cf.toUpperCase()
  if (cf.length !== 16) {
    return (
      'La lunghezza del codice fiscale non è\n' +
      'corretta: il codice fiscale dovrebbe essere lungo\n' +
      'esattamente 16 caratteri.\n'
    )
  }
  validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (i = 0; i < 16; i++) {
    if (!validi.includes(cf.charAt(i))) {
      return (
        "Il codice fiscale contiene il carattere non valido '" +
        cf.charAt(i) +
        "'.\nI caratteri validi sono le lettere non accentate e le cifre.\n"
      )
    }
  }
  set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
  setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX'
  s = 0
  for (i = 1; i <= 13; i += 2) {
    s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  for (i = 0; i <= 14; i += 2) {
    s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
  }
  if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0)) {
    return (
      'Il codice fiscale non è corretto:\n' +
      'il codice di controllo non corrisponde.\n'
    )
  }
  return ''
}

FICUtils.qtyPrecision = 6

/*
Vecchia funzione di arrotondamento
FICUtils.roundToDigit = (num, digits) => {
  if (num < 1.0e-6 && num > -1.0e-6) {
    return 0
  }
  return (
    +(Math.round(num * FICUtils.nSign(num) + 'e+' + digits) + 'e-' + digits) *
    FICUtils.nSign(num)
  )
}
 */

FICUtils.roundToDigit = (num, digits) => {
  // if (digits < 0 || digits > 8) throw new Error('Invalid decimals digits.')
  var epsilon = 0.000000005 // 0.5*10^-8
  if (num < epsilon && num > -epsilon) return 0
  var p = Math.pow(10, digits)
  var pn = num * p
  var roundedPn = Math.round(pn)
  var precision = epsilon * p
  if (Math.abs(roundedPn - pn) + precision > 0.5)
    pn += precision * FICUtils.nSign(num)
  return Math.round(pn) / p
}

FICUtils.nSign = num => {
  if (num > 0) {
    return 1
  }
  if (num < 0) {
    return -1
  }
  return 0
}

FICUtils.roundSignificative = (n, digits = FICUtils.qtyPrecision) => {
  if (digits === undefined) {
    digits = 0
  }
  let point = 0
  const sign = FICUtils.nSign(n)
  n *= sign
  if (n >= 1) {
    for (let i = 1; i < 15; i++) {
      if (n / Math.pow(10, i) < 1) {
        point = -i + digits
        break
      }
    }
  } else {
    for (let i = 1; i < 15; i++) {
      if (n * Math.pow(10, i) > 1) {
        point = i + digits - 1
        break
      }
    }
  }
  if (point < 0) {
    return (
      sign *
      FICUtils.roundToDigit(n / Math.pow(10, -point), 0) *
      Math.pow(10, -point)
    )
  }
  return sign * FICUtils.roundToDigit(n, point)
}

FICUtils.dotsToCommas = txt => {
  if (txt === undefined) {
    return ''
  }
  return String(txt).replace('.', ',')
}

FICUtils.commasToDots = txt => {
  if (txt === undefined) {
    return ''
  }
  return String(txt).replace(',', '.')
}

FICUtils.getLanguageForCountry = country => {
  if (
    ['Italia', 'San Marino', 'Svizzera', 'Città del Vaticano'].includes(country)
  ) {
    return 'it'
  }
  if (['Germania', 'Austria'].includes(country)) {
    return 'de'
  }
  if (['Francia'].includes(country)) {
    return 'fr'
  }
  if (['Spagna'].includes(country)) {
    return 'es'
  }
  if (['Grecia'].includes(country)) {
    return 'el'
  }
  return 'en'
}

/**
 * @param obj
 * @param keyStartsWith
 * @returns object
 */
FICUtils.clearObject = (obj, keyStartsWith = '_') => {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k]) {
      FICUtils.clearObject(obj[k])
    } else if (k.charAt(0) === keyStartsWith) {
      delete obj[k]
    }
  }

  // here we have an object that in the worst case has elements link _keyName: {} || _keyName: [], we need to delete them all cause they are useless
  for (const k in obj) {
    if (k.charAt(0) !== keyStartsWith) {
      continue // not a key that starts with [keyStartsWith]
    }
    // The property is an empty object and the key name starts with [keyStartsWith]
    if (Object.keys(obj[k]).length === 0 && k.charAt(0) === keyStartsWith) {
      delete obj[k] // delete that property
    }
  }

  return obj
}

FICUtils.firstCharToLowerCase = str =>
  str.charAt(0).toLowerCase() + str.slice(1)

export default FICUtils
