import { OS, Service } from './misc'

export type MFADevice = {
  deviceId: string
  id: string
  lastUsage: string
  mfaEnabled: boolean
  model: string
  name: string | null
  os: OS.ANDROID | OS.IOS
  service: Service
  type: 'mobile' | 'web'
}

export type MFAIntent = {
  expiresIn: number
  intentId: string
  pollingInterval: number
  retryIn: number | null
  seed?: string
}

type BaseMFAFactor = {
  id: number
  setupCompleted: boolean
  favorite: boolean
}

export type CodeMFAFactor = BaseMFAFactor & {
  type: 'authenticator'
}

export type DeviceMFAFactor = BaseMFAFactor & {
  deviceData: MFADevice
  favorite: boolean
  type: 'device'
}

export type PhoneMFAFactor = BaseMFAFactor & {
  backup: boolean
  phoneNumber: string
  type: 'phone'
}

export type MFAFactor = CodeMFAFactor | DeviceMFAFactor | PhoneMFAFactor

export enum MFAStatus {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  PENDING = 'pending',
}

export enum PollingStatus {
  APPROVED = 'approved',
  DENIED = 'denied',
  EXPIRED = 'expired',
  PENDING = 'pending',
}

export enum LoginAttemptStatus {
  FAILURE = 'failure',
  SUCCESS = 'success',
  BLOCK = 'block',
  CONFIRMATION_REQUIRED = 'confirmation_required',
  MFA_REQUIRED = 'mfa_required',
}

export enum SudoModeAction {
  CREATE_RECOVERY_CODE = 'create_recovery_code',
}

export type LoginAttempt = {
  authCode: string
  expiresIn: number
  resendIn: number | null // Tempo dopo cui può chiamare A4, se null, non può più
  status: LoginAttemptStatus
}

export type AuthIntent = {
  id: string
  needsCredentials: boolean
  credentialsValidated: boolean
  needsMfa: boolean
  mfaValidated: boolean
}

export enum RecoveryCodeStatus {
  ACTIVE = 'active',
  USED = 'use',
  REGENERATED = 'regenerate',
  BLOCKED = 'block',
  DISABLED = 'disable_mfa',
  NEVER_CREATED = 'never_created',
}

// RESPONSES

export type GetMFAFactorsResponse = {
  data: {
    status: MFAStatus
    factors: MFAFactor[]
    defaultDeviceId: string
  }
}

export type PollingResponse = {
  data: {
    status: PollingStatus
  }
}

export type SetupCreateIntentResponse = {
  data: MFAIntent
}

export type AuthConfirmResponse = {
  data: {
    authIntent: AuthIntent
    mfaIntent: MFAIntent
  }
}

export type GetAuthConfirmIntentResponse = {
  data: MFAIntent & {
    factorData: MFAFactor
  }
}

export type GetRecoveryCodeStatusResponse = {
  data: {
    isRecoveryCodeMissing: boolean
    details: {
      remainingAttempts: number
      missingCause?: RecoveryCodeStatus
      invalidatedAt?: string
    }
  }
}

export type GetRecoveryCodeResponse = {
  data: {
    recoveryCode: string
  }
}

export type ConfirmIntentResponse = {
  data: {
    recoveryCode?: string
  }
}
