import { Draft, PayloadAction } from '@reduxjs/toolkit'

import TSPayStore from '../tsPayTypes'

export const setDigitalPOSActive = (
  state: Draft<TSPayStore>,
  action: PayloadAction<boolean>
): void => {
  state.digitalPos = action.payload
}
