import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'

import { GlobalParamsPayload, setFromApi } from '../../app/actions/setFromApi'
import initialState from './localStorageInitialState'
import {
  setAccountantCall,
  setAccountantCallBanner,
} from './reducers/accountant'
import { setHasAutomaticRenewal } from './reducers/company'
import {
  decrementPendingExpensesCount,
  incrementPendingExpensesCount,
  setPendingExpensesCount,
} from './reducers/expensesReducer'
import {
  resetLocalStorageItem,
  setLocalStorage,
  setReduxStorage,
} from './reducers/genericReducer'

const localStorageSlice = createSlice({
  name: 'localStorage',
  initialState,
  reducers: {
    decrementPendingExpensesCount,
    incrementPendingExpensesCount,
    resetLocalStorageItem,
    setAccountantCall,
    setAccountantCallBanner,
    setHasAutomaticRenewal,
    setLocalStorage,
    setPendingExpensesCount,
    setReduxStorage,
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        const newValues = {
          ...action.payload.localStorage,
          newWebinarsCount: 0,
          activeWebinars: [...(state.activeWebinars ?? [])],
        }

        if (action.payload.localStorage.activeWebinars) {
          const count = action.payload.localStorage.activeWebinars.filter(
            (x: number) => !state.activeWebinars.includes(x)
          ).length
          newValues.newWebinarsCount = count
          newValues.activeWebinars = [
            ...action.payload.localStorage.activeWebinars,
          ]
        }

        merge(state, newValues)
      }
    )
  },
})
export default localStorageSlice
