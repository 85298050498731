import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import initialState from './loadersInitialState'

interface SetLoading {
  isLoading: boolean
}

const loadersSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    setInnerLoading: (state, action: PayloadAction<SetLoading>) => {
      state.inner = action.payload.isLoading
    },
    setOuterLoading: (state, action: PayloadAction<SetLoading>) => {
      state.outer = action.payload.isLoading
    },
  },
})

const loaders = loadersSlice.reducer

export const { setInnerLoading, setOuterLoading } = loadersSlice.actions
export default loaders
