import { Icon } from '@fattureincloud/fic-design-system'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import FICColors from '../../../common/styles/FICColors'
import FICBaseButton from './FICBaseButton'

/**
 * Renderizza un tag <a>
 * accetta solo testo (obbligatorio) e due parametri opzionali per il colore (color, colorHover)
 * può visualizzare un icona a sinistra del testo (iconLeft)
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function FICLinkImpl(props) {
  return (
    <FICBaseButton
      {...props}
      className={props.className}
      style={props.style}
      renderButton={renderLink}
    />
  )
}

function renderLink(props) {
  return (
    <span>
      {props.iconLeft && (
        <Icon icon={props.iconLeft} style={{ marginRight: 4 }} />
      )}
      {props.text}
    </span>
  )
}

const FICLink = styled(FICLinkImpl)`
  color: ${props =>
    props.isDisabled
      ? FICColors.link.gray
      : props.color
      ? props.color
      : FICColors.link.normal};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${props =>
      props.isDisabled
        ? FICColors.link.gray
        : props.colorHover
        ? props.colorHover
        : FICColors.link.hover};
    text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
    cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  }
`

FICLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderButton: PropTypes.func,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconLeft: PropTypes.object,
}

FICLink.defaultProps = {
  ...FICBaseButton.defaultProps,
  color: undefined,
  colorHover: undefined,
  text: '',
  className: undefined,
}

FICLinkImpl.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

FICLinkImpl.defaultProps = {
  className: undefined,
  style: undefined,
}

export default FICLink
