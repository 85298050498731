import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'
import Cookies from 'js-cookie'
import React, { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isDevelopment } from '../../../../common/environment'
import { getIsSupport } from '../../../../redux/features/support/supportSelectors'
import useUserIdDispatcher from './hooks/useUserIdDispatcher'

interface Props {
  children: ReactNode
}

const UserIdDispatcher = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  useUserIdDispatcher()
  return <> {children} </>
}

const GoogleTagMangerProvider = ({ children }: Props): JSX.Element => {
  const isSupport = useSelector(getIsSupport)
  const ficPersistentId = Cookies.get(
    isDevelopment ? 'fic_dev_persistent_id' : 'fic_persistent_id'
  )

  const gtmState: ISnippetsParams | undefined = useMemo(
    () =>
      isSupport
        ? undefined
        : {
            id: 'GTM-TPR8RR7',
            dataLayer: { ficPersistentId, event: 'setFicPersistentId' },
          },
    [ficPersistentId, isSupport]
  )

  return (
    <GTMProvider state={gtmState}>
      <UserIdDispatcher>{children}</UserIdDispatcher>
    </GTMProvider>
  )
}

export default GoogleTagMangerProvider
