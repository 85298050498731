class TagManagerTracker {
  execute(action, category, label) {
    this.executeRaw({
      event: 'asyncAction',
      gaHitType: 'event',
      category: category,
      action: action,
      label: label,
    })
  }

  executeRaw(data) {
    // debugger
    if (typeof dataLayer === 'undefined') {
      console.info('Tag Manager is not initialized.')
      return
    }

    dataLayer.push(data)
  }

  view = (category, label) => {
    this.execute('visualizzazione', category, label)
  }

  click = (category, label, action) => {
    this.execute(action || 'click', category, label)
  }
}

export default TagManagerTracker
