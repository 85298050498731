import update from 'immutability-helper'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import set from 'lodash/set'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import validate from 'validate.js'

import { defaultConfig } from '../FICForm/defaults'
import FICFormNode from '../FICForm/FICFormNode'

const FICForm = ({
  className,
  config,
  formOptions,
  style,
  validationResult,
  validationRules,
  ...nodeProps
}) => {
  const validation =
    validationResult === undefined
      ? FICForm.validate(nodeProps.data, validationRules)
      : validationResult
  return (
    <div className={`FICForm ${className ?? ''}`} style={style}>
      <FICFormNode
        {...nodeProps}
        config={config || FICForm.defaultConfig}
        validation={validation}
        formOptions={formOptions}
      />
    </div>
  )
}

FICForm.defaultProps = {
  className: undefined,
  style: {},
  config: undefined,
  schema: {},
  validationRules: {},
  validationResult: undefined,
  data: {},
  onChange: undefined,
  formOptions: {},
}

FICForm.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  schema: PropTypes.object,
  validationRules: PropTypes.object,
  validationResult: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  formOptions: PropTypes.object,
}

FICForm.defaultConfig = {}

FICForm.createConfig = rawConfig => {
  const newConfig = merge({}, defaultConfig, rawConfig)
  forEach(newConfig.customValidators, (v, k) => {
    validate.validators[k] = v
  })
  return newConfig
}

FICForm.init = config => {
  FICForm.defaultConfig = config
}

FICForm.validate = (data, validationRules) => {
  const validationResults = validate(data, validationRules, {
    fullMessages: false,
  })
  return validationResults || {}
}

export default FICForm

// Hook per gestire lo stato della form in modo easy
export const useFormController = (initialData, objectPath) => {
  const [data, setData] = useState(initialData)
  const handleDataChange = (name, value) => {
    const selectorPath = [objectPath, name].filter(t => t).join('.')
    const selector = set({}, selectorPath, { $set: value })
    const newData = update(data, selector)
    setData(newData)
  }
  return [data, handleDataChange, setData]
}
