import { createSelector } from '@reduxjs/toolkit'

import FICDateTime from '../../../../common/dateTime'
import { GlobalStore } from '../../../app/rootReducer'
import { getLocalStorage } from '../../localStorage'
import { CompaniesStore } from '../companies/companiesStoreTypes'
import {
  CompanyDetailsStore,
  CopylotAttributesType,
  CustomVarsType,
  FunctionsType,
  LimitType,
  PermissionKey,
  PermissionsSelector,
  PermissionsType,
} from '../companyDetails/companyDetailsStoreTypes'
import { getCurrentConnection } from '../connections/connectionsSelectors'

export const getCompanyId = (state: GlobalStore): number =>
  getCurrentConnection(state)?.controlledId

export const getCompanies = (state: GlobalStore): CompaniesStore =>
  state.auth.companies

export const getCompaniesArray = createSelector([getCompanies], companies =>
  Object.values(companies.byId)
)

const getCompanyDetails = (state: GlobalStore) => state.auth.companyDetails

export const getCompany = createSelector(
  [getCompanyId, getCompanies, getCompanyDetails],
  (companyId, companies, details) => ({
    ...companies.byId[companyId],
    ...details,
  })
)

export const getCompaniesCount = (state: GlobalStore): number =>
  getCompanies(state).allIds.length

export const getAccountantClientsCount = createSelector(
  [getCurrentConnection, getCompanies],
  ({ controllerId }, { byId }) => {
    return byId[controllerId].controlledCompanies.filter(
      controlledId => controlledId !== controllerId
    ).length
  }
)

export const getHasCompanies = (state: GlobalStore): boolean =>
  getCompaniesCount(state) > 0

export const getCompanyPermissions = (state: GlobalStore): PermissionsType =>
  getCompany(state).permissions

export const getPermission =
  <T extends PermissionKey>(key: T) =>
  (state: GlobalStore): PermissionsType[T] =>
    state.auth.companyDetails.permissions[key]

export const getPermissions =
  <T extends PermissionKey>(keys: T[]) =>
  (state: GlobalStore): PermissionsSelector<T> => {
    return keys.reduce((acc, key) => {
      acc[key] = state.auth.companyDetails.permissions[key]
      return acc
    }, {} as PermissionsSelector<T>)
  }

export const getCompanyFunctions = (state: GlobalStore): FunctionsType =>
  getCompany(state).functions

export const getFunction =
  <T extends keyof FunctionsType>(key: T) =>
  (state: GlobalStore): boolean =>
    state.auth.companyDetails.functions[key]

export const getPlan = (state: GlobalStore): number =>
  state.auth.companyDetails.planId

export const getCrossCompetenze = (state: GlobalStore): boolean =>
  getCompany(state).crossCompetenze

export const getLicenseExpireDate = (state: GlobalStore): string =>
  getCompany(state).licenseExpire

export const getCompanySignupDate = (state: GlobalStore): string =>
  getCompany(state).signupDate

export const getIsLicenseExpired = createSelector(
  [getCompany, getHasCompanies],
  ({ gracePeriod, licenseExpire }, hasCompanies) => {
    if (!hasCompanies || gracePeriod) return false

    const difference = FICDateTime.diff(
      new Date().setHours(0, 0, 0, 0),
      licenseExpire,
      'days'
    )

    return difference >= 0
  }
)

export const getIsManagingCompany = createSelector(
  [getLocalStorage, getCurrentConnection],
  (localStorage, connection) =>
    Boolean(localStorage.wantsManageCompany && connection?.manageable)
)

export const getHasAccountant = (state: GlobalStore): boolean =>
  getCompany(state).accountantsCount > 0

export const getIsMaster = (state: GlobalStore): boolean =>
  getCompany(state).role === 'master'

export const getIsSubAccount = (state: GlobalStore): boolean =>
  getCompany(state).role === 'subaccount'

export const getCompanyCustomVars = (state: GlobalStore): CustomVarsType =>
  state.auth.companyDetails.customVars

export const getIsCustomVarActive =
  (key: keyof CustomVarsType) =>
  (state: GlobalStore): boolean =>
    state.auth.companyDetails.customVars[key] === '1'

export const getLimits = (
  state: GlobalStore,
  key: keyof CompanyDetailsStore['limits']
): LimitType => state.auth.companyDetails.limits[key]

export const getCompanySuppliersLimit = (state: GlobalStore): LimitType =>
  getLimits(state, 'suppliers')

export const getCompanyClientsLimit = (state: GlobalStore): LimitType =>
  getLimits(state, 'clients')

export const getCompanyDocumentsLimit = (state: GlobalStore): LimitType =>
  getLimits(state, 'documents')

export const getCompanyProductsLimit = (state: GlobalStore): LimitType =>
  getLimits(state, 'products')

export const getShowFailedIntentOnCreditCardBanner = (
  state: GlobalStore
): boolean => state.auth.companyDetails.showFailedIntentOnCreditCardBanner

export const getCopilotAttributes = (
  state: GlobalStore
): CopylotAttributesType => state.auth.companyDetails.copilot
