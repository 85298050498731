import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import initialState from './helpOverlayInitialState'
import {
  HelpOverlayPosition,
  HelpOverlayVisibility,
} from './helpOverlayStoreTypes'

const helpOverlaySlice = createSlice({
  name: 'helpOverlay',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<HelpOverlayPosition>) => {
      state.position = action.payload
    },
    setVisibility: (state, action: PayloadAction<HelpOverlayVisibility>) => {
      const { visibility } = action.payload
      state.visibility = visibility
    },
  },
})

const helpOverlay = helpOverlaySlice.reducer

export const { setPosition, setVisibility } = helpOverlaySlice.actions
export default helpOverlay
