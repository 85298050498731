import { LocationListener } from 'history'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import useHistoryListener from '../../../common/hooks/useHistoryListener'
import useValuesSelector from '../../../redux/common/useValuesSelector'
import { getOuterLoader } from '../../../redux/features/loaders/loadersSelectors'

const pathToRemoveFileUpload = [
  '/importer/',
  '/expenses/new',
  '/accountant/map',
  '/importer/bankstatements',
]

type FileUploadElement = {
  fileupload?: (text: string) => void
}

const destroyFileUploader = () => {
  const fileUploaderElement = $('#fileupload') as FileUploadElement

  if (fileUploaderElement?.fileupload) {
    try {
      fileUploaderElement.fileupload('destroy')
    } catch {
      console.log('catch fileUploaderElement error')
    }
  }
}

const HideLogics: any = () => {
  const { key: pathKey, pathname: pathName } = useLocation()
  const outer = useSelector(getOuterLoader)
  const { showCompanyChanged, showCompanyPicker } = useValuesSelector(
    'modals',
    ['showCompanyChanged', 'showCompanyPicker']
  )

  const previousUrl = useRef(pathName)
  // TODO: rimuovere quando non esisteranno più $().fileupload (php)
  const fileUploaderDestroy = useCallback<LocationListener>(location => {
    if (
      new RegExp(pathToRemoveFileUpload.join('|')).test(previousUrl.current) &&
      location.pathname !== previousUrl.current
    ) {
      destroyFileUploader()
    }
    previousUrl.current = location.pathname
  }, [])

  // TODO: rimuovere quando non esisteranno più $().fileupload (php)
  useEffect(() => {
    if (
      new RegExp(pathToRemoveFileUpload.join('|')).test(previousUrl.current) &&
      pathName !== previousUrl.current
    ) {
      destroyFileUploader()
    }
    previousUrl.current = pathName
  }, [pathName])

  // Hide of all elements when route changes or outer changes
  useEffect(() => {
    const reactModals =
      document.querySelectorAll<HTMLDivElement>('.fic-modal-visible')
    const oldModalsOverlay = document.querySelectorAll<HTMLDivElement>(
      '.modal-backdrop.fade.in'
    )
    const oldModals = document.querySelectorAll<HTMLDivElement>(
      '.modal.hide.fade.in'
    )
    const openTips =
      document.querySelectorAll<HTMLDivElement>('.opentip-container')

    const fixWithDisplayNone = [
      ...Array.from(reactModals),
      ...Array.from(oldModalsOverlay),
      ...Array.from(oldModals),
      ...Array.from(openTips),
    ]

    fixWithDisplayNone.forEach(element => {
      if (!element.querySelector('.ignoreHideLogics')) {
        element.style.display = 'none'
      }
    })
  }, [pathKey, outer])

  useEffect(() => {
    const reactModals =
      document.querySelectorAll<HTMLDivElement>('.fic-modal-visible')
    const oldModals = document.querySelectorAll<HTMLDivElement>(
      '.modal-backdrop.fade.in'
    )
    const oldModals2 = document.querySelectorAll<HTMLDivElement>(
      '.modal.hide.fade.in'
    )

    const fixWithOpacity0 = [
      ...Array.from(reactModals),
      ...Array.from(oldModals),
      ...Array.from(oldModals2),
    ]

    fixWithOpacity0.forEach((element: any) => {
      if (showCompanyPicker || showCompanyChanged) {
        element.style.opacity = '0'
      } else {
        element.style.opacity = '1'
      }
    })
  }, [showCompanyPicker, showCompanyChanged])

  useEffect(() => {
    if (pathName !== '/helpsecurity/video') {
      const divsToRemove = document.querySelectorAll<HTMLDivElement>(
        '.gc-bubbleDefault.pls-container'
      )
      if (divsToRemove) {
        divsToRemove.forEach((element: HTMLElement) => {
          element.remove()
        })
      }
    }
  }, [pathName])

  const removeOnHistoryChange = useCallback<LocationListener>(
    (location, action) => {
      document.querySelector<HTMLDivElement>('#modalToRemove')?.remove()
      document.querySelector<HTMLDivElement>('.modalToRemove')?.remove()
      fileUploaderDestroy(location, action)
    },
    [fileUploaderDestroy]
  )

  useHistoryListener(removeOnHistoryChange)

  return null
}

export default HideLogics
