import { validateTaxCode, validateVatNumber } from '.'
import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

/**
 * Length validator
 * @param {string} s - The string to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateLength = (
  s: string,
  params?: ValidatorParams
): ValidationResult => {
  const { allowEmpty, maxLength, minLength } = params || {}

  if (allowEmpty && s === '') return Validation.success()

  if (minLength && s.length < minLength) {
    return Validation.error(
      `Il campo deve contenere almeno ${minLength} caratteri.`
    )
  }
  if (maxLength && s.length > maxLength) {
    return Validation.error(
      `Il campo può contenere al massimo ${maxLength} caratteri.`
    )
  }
  return Validation.success()
}

/**
 * Tax code OR vat number validator
 * @param {string} taxCode - The tax code to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateTaxCodeOrVatNumber = (
  taxCode: string,
  params?: ValidatorParams
): ValidationResult => {
  const check = validateVatNumber(taxCode, params)
  if (check.valid) {
    return check
  }
  return validateTaxCode(taxCode, params)
}
