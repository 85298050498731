export enum LicensePlan {
  TRIAL = 'trial',
  STANDARD = 'standard',
  PREMIUM = 'premium',
  PREMIUM_PLUS = 'premium_plus',
  COMPLETE = 'complete',
}

export enum NumericLicensePlan {
  TRIAL = 0,
  STANDARD = 1,
  PREMIUM = 2,
  PREMIUM_PLUS = 3,
  COMPLETE = 4,
}

export enum LimitCategory {
  DOCUMENTS = 'documents',
  CLIENTS = 'clients',
  PRODUCTS = 'products',
  SUPPLIERS = 'suppliers',
}

export enum LicenseRenewAction {
  EMPTY = 'empty',
  BUY = 'buy',
  RENEW = 'renew',
  TRANSFORM = 'transform',
  RENEW_AND_TRANSFORM = 'renew_and_transform',
  REACTIVATE = 'reactivate',
}
