import { inputHelperError, inputHelperWarning } from '../../constants/formConst'
import { TValidation, ValidationResult } from './types'

export const Validation: TValidation = {
  error: (message: string): ValidationResult => ({
    valid: false,
    helper: {
      ...inputHelperError,
      message,
    },
  }),
  warning: (message: string): ValidationResult => ({
    valid: false,
    helper: {
      ...inputHelperWarning,
      message,
    },
  }),
  success: (): ValidationResult => ({ valid: true }),
}
