import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`

export const flexRow = css`
  display: flex;
  flex-direction: row;
`

export const flexCentered = css`
  justify-content: center;
  align-items: center;
`

type FlexAlign = 'start' | 'end' | 'center' | 'spaced'
type FlexAlignParams = [FlexAlign] | [FlexAlign, FlexAlign]

const mapAlignment: Record<FlexAlign, string> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  spaced: 'space-between',
}

export const flexAlign = (
  ...args: FlexAlignParams
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    justify-content: ${mapAlignment[args[0]]};
    align-items: ${mapAlignment[args[1] ?? args[0]]};
  `
