import { Draft, PayloadAction } from '@reduxjs/toolkit'

import SwapStorageStore, { EditTranchesModalData } from '../swapStorageTypes'

export const setEditTranchesModalData = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<EditTranchesModalData | undefined>
): void => {
  state.editTranchesModalData = action.payload
}
