import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { TSPayService } from '../../../../common/types'
import SwapStorageStore from '../swapStorageTypes'

export const setTSPayAutoActivateService = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<TSPayService | undefined>
): void => {
  state.tsPayAutoActivateService = action.payload
}
