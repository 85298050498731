import { Toast } from '@fattureincloud/fic-design-system'

import store from '../../redux'
import { setOuterLoading } from '../../redux/features/loaders/loadersSlice'
import { LOCALSTORAGE_FIC_KEY } from '../../redux/features/localStorage/localStorageUtils'
import Api from '../network/Api'
import { redirectToLoginPage } from './urlUtils'

export const logout = (): void => {
  store.dispatch(setOuterLoading({ isLoading: true }))

  OneSignal?.push(() => {
    OneSignal.deleteTag('send')
  })

  localStorage.removeItem('sendTags')
  localStorage.removeItem('oneSignalPersonId')

  Api.get({
    url: window.location.origin,
    request: '/rest.php?request=logout',
    useCredentials: true,
  })
    .then(() => {
      // Destroy customerly session
      window.customerly?.logout?.()

      localStorage.removeItem(LOCALSTORAGE_FIC_KEY)
      localStorage.removeItem('support')
      localStorage.removeItem('need_setup')
      redirectToLoginPage()
    })
    .catch(() => {
      store.dispatch(setOuterLoading({ isLoading: false }))
      Toast.error('Si è verificato un errore sconosciuto.')
    })
}
