import {
  css,
  CSSObject,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

export enum BREAKPOINTS {
  SMALL = '500px', // Small device, portrait smartphone
  MEDIUM = '800px', // Landscape smartphone and portrait tablet
  LARGE = '1200px', // Landscape table and small PC
}

type CSSInterpolation = FlattenInterpolation<ThemeProps<DefaultTheme>>
type Style = CSSInterpolation | CSSObject

const isFlattenInterpolation = (style: Style): style is CSSInterpolation =>
  Array.isArray(style)

export const breakpoint = (
  bk: BREAKPOINTS | string,
  style: Style
): CSSInterpolation => css`
  @media screen and (max-width: ${bk}) {
    ${isFlattenInterpolation(style) ? style : css(style)}
  }
`

/**
 * Apply the style only for small devices (like portrait smartphones)
 */
export const smallDevice = (style: Style): CSSInterpolation =>
  breakpoint(BREAKPOINTS.SMALL, style)

/**
 * Apply the style only for medium devices (like tablets)
 */
export const mediumDevice = (style: Style): CSSInterpolation =>
  breakpoint(BREAKPOINTS.MEDIUM, style)

/**
 * Apply the style only for large devices (landscape tablets and small PC)
 */
export const largeDevice = (style: Style): CSSInterpolation =>
  breakpoint(BREAKPOINTS.LARGE, style)
