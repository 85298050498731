import { createSlice } from '@reduxjs/toolkit'

import initialState from './accountantInitialState'
import {
  setClientsInvitations,
  setClientsInvitationsCallStatus,
} from './reducers/clientsInvitationsReducer'

const accountantSlice = createSlice({
  name: 'accountant',
  initialState,
  reducers: {
    setClientsInvitations,
    setClientsInvitationsCallStatus,
  },
})

export default accountantSlice
