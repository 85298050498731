import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GlobalParamsPayload, setFromApi } from '../../app/actions/setFromApi'
import initialState from './configurationsInitialState'
import ConfigurationsStore from './configurationsStoreTypes'

const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setConfigurations: (
      state,
      action: PayloadAction<Partial<ConfigurationsStore>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    setChat: (state, action: PayloadAction<ConfigurationsStore['chat']>) => ({
      ...state,
      chat: action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return {
          ...state,
          ...action.payload.configurations,
        }
      }
    )
  },
})

const configurations = configurationsSlice.reducer

export const { setChat, setConfigurations } = configurationsSlice.actions
export default configurations
