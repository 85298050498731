import { Draft, PayloadAction } from '@reduxjs/toolkit'

import SwapStorageStore, { DocumentToTransform } from '../swapStorageTypes'

export const setDocumentToTransform = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<DocumentToTransform | undefined>
): void => {
  state.documentToTransform = action.payload
}
