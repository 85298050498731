import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { MFAIntent } from '../../../../common/types/auth'
import MFAStore from '../mfaTypes'

export const setMFAIntent = (
  state: Draft<MFAStore>,
  action: PayloadAction<MFAIntent | undefined>
): void => {
  state.intent = action.payload
}
