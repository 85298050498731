import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'

import initialState from './modalsConfigInitialState'
import ModalsConfigStore from './modalsConfigStoreTypes'

type SetConfig = Partial<ModalsConfigStore>

const modalsConfigSlice = createSlice({
  name: 'modalsConfig',
  initialState,
  reducers: {
    setModalsConfig: (state, action: PayloadAction<SetConfig>) => {
      const config = action.payload
      merge(state, config)
    },
  },
})

const modalsConfig = modalsConfigSlice.reducer

export const { setModalsConfig } = modalsConfigSlice.actions
export default modalsConfig
