import { createSlice } from '@reduxjs/toolkit'

import initialState from './mfaInitialState'
import { setMFAAuthIntent } from './reducers/authIntentReducer'
import { setMFAFactor } from './reducers/factorReducer'
import { setMFAIntent } from './reducers/intentReducer'
import {
  setRecoveryCode,
  setRecoveryCodeAttempt,
  setRecoveryCodeStatus,
} from './reducers/recoveryCodeReducer'
import { addFetchRecoveryCodeCases } from './thunks/fetchRecoveryCode'
import { addGenerateRecoveryCodeCases } from './thunks/generateRecoveryCode'

const mfaSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    setMFAIntent,
    setMFAFactor,
    setMFAAuthIntent,
    setRecoveryCode,
    setRecoveryCodeAttempt,
    setRecoveryCodeStatus,
  },
  extraReducers: builder => {
    addFetchRecoveryCodeCases(builder)
    addGenerateRecoveryCodeCases(builder)
  },
})

export default mfaSlice
