import FIC from '../../old/common/general/js/FIC'
import { GlobalParamsApiResponse } from '../../redux/app/actions/setFromApi'
import { hostApi, hostCompute, hostSecure } from '../environment'

const camelToUnderscore = (key: string): string => {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase()
}

const getSnakeCaseObject = (
  object: Record<string, any>
): Record<string, unknown> => {
  const response: Record<string, any> = {}

  for (const key in object) {
    if (typeof object[key] === 'object' && object[key]) {
      response[camelToUnderscore(key)] = getSnakeCaseObject(object[key])
    } else {
      response[camelToUnderscore(key)] = object[key]
    }
  }

  return response
}

export const loadReactGlobalParams = (
  apiResponse: GlobalParamsApiResponse['data']['reduxData']
): void => {
  const { auth, configurations, support } = apiResponse

  const withCompanyParams = auth.companyDetails
    ? {
        plan: auth.companyDetails.planId,
        functions: getSnakeCaseObject(auth.companyDetails.functions),
        permissions: getSnakeCaseObject(auth.companyDetails.permissions),
      }
    : {}

  const reactGlobalParams: any = {
    access_token: apiResponse.accessToken,
    api_host: hostApi,
    chat: getSnakeCaseObject(configurations.chat),
    compute_host: hostCompute,
    peid: auth.person.id,
    secure_host: hostSecure,
    suid: apiResponse.suid,
    uid: apiResponse.uid,
    isSupport: support?.isSupport,
    adminAccountId: support?.adminAccountId,
    person_hash: auth.person.hash,
    ...withCompanyParams,
  }

  window.reactGlobalParams = reactGlobalParams
  FIC.setGlobals(reactGlobalParams)
}
