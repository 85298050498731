import { Dispatch, SetStateAction } from 'react'

/* OBJ & FN */
export type UnknownObject = Record<string, unknown>

export type EmptyObject = Record<string, never>

/* REACT TYPES */

export type Component<T extends unknown = Record<string, never>> = (
  props: T
) => JSX.Element

export type ReactSetState<T> = Dispatch<SetStateAction<T>>

export type RequireProps<T, K extends keyof T> = T & { [C in K]-?: T[C] }

/* UTILITIES */

export type Primitives = boolean | string | number | null | undefined

export type ValueOf<T> = T[keyof T]

export type Nullish<T> = T | null | undefined

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P]
}

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export type TableSettings = {
  dimensionRow?: number
  notVisibleColumns?: string[]
  orderColumns?: string[]
}

/* ENUMS */

export enum OS {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
  THIRD_PARTY = 'third_party',
  UNKNOWN = 'unknown',
}

export enum Service {
  FIC = 'fic',
  DIC = 'dic',
}

export enum BECallStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum Permission {
  WRITE = 'write',
  READ = 'read',
  NONE = 'none',
}
