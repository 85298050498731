import mfaSlice from './mfaSlice'
export type { default as MFAStore } from './mfaTypes'
export { default as fetchRecoveryCode } from './thunks/fetchRecoveryCode'
export { default as generateRecoveryCode } from './thunks/generateRecoveryCode'
export * from './mfaSelectors'

export const {
  setMFAAuthIntent,
  setMFAFactor,
  setMFAIntent,
  setRecoveryCode,
  setRecoveryCodeAttempt,
  setRecoveryCodeStatus,
} = mfaSlice.actions

export const mfa = mfaSlice.reducer
