import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GlobalParamsPayload,
  setFromApi,
} from '../../../app/actions/setFromApi'
import initialState from './companiesInitialState'

interface SetName {
  name: string
  companyId: number
}

interface SetAlias {
  alias: string
  companyId: number
}
const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<SetName>) => {
      const { companyId, name } = action.payload
      state.byId[companyId].name = name
    },
    setCompanyAlias: (state, action: PayloadAction<SetAlias>) => {
      const { alias, companyId } = action.payload
      state.byId[companyId].alias = alias
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.auth.companies
      }
    )
  },
})

const companies = companiesSlice.reducer

export const { setCompanyAlias, setCompanyName } = companiesSlice.actions
export default companies
