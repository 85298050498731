import './FICModal.less'

import { ThemeProvider } from '@fattureincloud/fic-design-system'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { browserVersion, isIE } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'

import FIC from '../../../common/general/js/FIC'
import FICUtils from '../../../common/general/js/FICUtils'
import FICButton from '../FICButton/FICButton'
import { Provider } from 'react-redux'
import store from '../../../../redux'

export var FICModalBody = Modal.Body
const FICModalRawHeader = Modal.Header
const FICModalRawTitle = Modal.Title
const FICModalRawFooter = Modal.Footer

export default class FICModalV2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _visible: 'pre-show',
    }
    this._mounted = true
    this._container = props.container
  }

  componentDidCatch(error, info) {
    if (isIE && browserVersion < '11') {
      alert(
        'Si è verificato un errore. Aggiorna il browser alla versione più recente.'
      )
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ _visible: 'visible' }, () => {
        this.focus()
      })
    }, 0)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  hideInBG = () => {
    this.setState({ _visible: 'hidden' })
  }
  showFromBG = () => {
    if (this.state._visible === 'hidden') {
      this.setState({ _visible: 'visible' }, () => {
        this.focus()
      })
    }
  }
  hide = () => {
    // Sarebbe close
    const id = this.props.id
    this.setState({ _visible: 'closed' }, () => {
      if (this._container) {
        this._container.remove(id)
      }
    })
    this.props.onHide && this.props.onHide()
  }
  focus = () => {
    setTimeout(() => {
      $('.fic-modal-' + this.props.id).focus()
    }, 10) // Delay necessario
  }
  renderChildren = props => {
    if (!this.props.children) return null
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        hide: this.hide,
      })
    })
  }

  render() {
    const style = { content: {} }
    if (this.props.width) {
      style.content.width = this.props.width
    }
    if (this.props.height) {
      style.content.height = this.props.height
    }
    if (this.props.maxWidth) {
      style.content.maxWidth = this.props.maxWidth
    }
    let className = 'fic-modal fic-modal-' + this.props.id
    if (this.props.scrollable) {
      className += ' scrollable'
    }
    return (
      <ReactModal
        id={this.props.id}
        className={className}
        overlayClassName={'FICModal2_Overlay fic-modal-' + this.state._visible}
        isOpen
        contentLabel='Finestra di dialogo'
        onHide={this.hide}
        style={style}
        onRequestClose={this.props.allowClose === false ? null : this.hide}
        tabindex='-1'
        ariaHideApp={false}
      >
        {this.renderChildren() || (
          <div>
            <FICModalHeader html={this.props.htmlTitle} />
            <FICModalBody className={this.props.className}>
              {this.props.htmlBody}
            </FICModalBody>
            <FICModalFooter
              modal={this}
              html={this.props.htmlFooter}
              buttons={this.props.footerButtons}
            />
          </div>
        )}
      </ReactModal>
    )
  }
}

class FICModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propsStack: [],
    }
    this.modalRefs = {}
  }

  add = properties => {
    this.setState(prevState => {
      const newStack = prevState.propsStack.slice()
      const newId =
        new Date().getTime() + '-' + FICUtils.randomBetween(0, 10000)
      properties.id = newId
      for (const key in this.modalRefs) {
        if (!this.modalRefs.hasOwnProperty(key)) continue
        this.modalRefs[key].hideInBG()
      }
      newStack.push(properties)
      return { propsStack: newStack }
    })
  }
  remove = id => {
    delete this.modalRefs[id]
    setTimeout(() => {
      this.setState(prevState => {
        let newStack = prevState.propsStack.slice()
        newStack = newStack.filter(item => item.id !== id)
        return { propsStack: newStack }
      })
    }, 500)
    if (Object.keys(this.modalRefs).length > 0) {
      let lastKey
      // do not change or you'll get fired
      // seriously
      for (lastKey in this.modalRefs) {
        // do nothing
      }
      this.modalRefs[lastKey].showFromBG()
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={FIC.theme}>
          <div>
            {this.state.propsStack.map(item => {
              return (
                <FICModalV2
                  id={item.id}
                  key={item.id}
                  container={this}
                  ref={modal => {
                    if (modal && modal.state._visible !== 'closed') {
                      this.modalRefs[item.id] = modal
                    }
                  }}
                  {...item.props}
                >
                  {item.content}
                </FICModalV2>
              )
            })}
          </div>
        </ThemeProvider>
      </Provider>
    )
  }
}

FICModalV2.init = container => {
  FICModalV2.container = ReactDOM.render(<FICModalContainer />, container)
}

/*

USAGE:

FICModal.show({
  title: "Ciao",
  body: "Miao",
  actions: [
    {text: "Conferma", color: "success", onClick: null},
    {text: "Chiudi", color: "gray", onClick: null},
  ]
})

*/

FICModalV2.show = options => {
  FICModalV2.container.add({
    content: null,
    props: {
      width: options.width,
      height: options.height,
      htmlTitle: options.title,
      htmlBody: options.body,
      htmlFooter: options.footer,
      footerButtons: options.actions,
      onHide: options.onHide,
      allowClose: options.allowClose,
    },
  })
}

FICModalV2.showMessage = (title, message, smallMessage) => {
  FICModalV2.show({
    title: title,
    body: (
      <span>
        {message}
        {smallMessage && (
          <Fragment>
            <br />
            <span className='small-gray-message'>{smallMessage}</span>
          </Fragment>
        )}
      </span>
    ),
    actions: [{ text: 'Chiudi', color: 'white' }],
  })
}

/*

USAGE:

FICModal.showCustom(<Component/>, {propName: "Abc"})

*/

FICModalV2.showCustom = (content, modalProps = {}) => {
  const props =
    modalProps === null || Object.keys(modalProps).length === 0
      ? null
      : modalProps
  FICModalV2.container.add({ content: content, props })
}

export function FICModalHeader(props) {
  return (
    <FICModalRawHeader>
      <FICModalRawTitle>{props.html}</FICModalRawTitle>
    </FICModalRawHeader>
  )
}

function itemsWithType(items, type) {
  if (!items) return []
  else return items.map(item => Object.assign(item, { type: type }))
}

export function FICModalFooter(props) {
  let footer = props.html
  const items = [
    ...itemsWithType(props.leftItems, 'left'),
    ...itemsWithType(props.centerItems, 'center'),
    ...itemsWithType(
      [...props.rightItems, ...props.buttons].reverse(),
      'right'
    ),
  ]
  if (items) {
    footer = items.map(
      ({ hide, html, icon, keepOpen, onClick, type, ...item }, idx) => {
        if (hide) {
          return null
        }
        if (html) {
          return (
            <div
              className={'footer-item ' + type}
              key={idx}
              style={{ display: 'inline-block', height: '32px' }}
            >
              {html}
            </div>
          )
        }
        return (
          <FICButton
            className={'footer-item ' + type}
            key={idx}
            iconLeft={icon}
            onClick={() => {
              if (onClick !== undefined) {
                onClick()
              }
              if (props.modal && !keepOpen) {
                props.modal.hide()
              }
            }}
            {...item}
          />
        )
      }
    )
  }
  return <FICModalRawFooter>{footer}</FICModalRawFooter>
}

FICModalFooter.propTypes = {
  leftItems: PropTypes.array,
  centerItems: PropTypes.array,
  rightItems: PropTypes.array,
  buttons: PropTypes.array,
}

FICModalFooter.defaultProps = {
  leftItems: [],
  centerItems: [],
  rightItems: [],
  buttons: [],
}

FICModalHeader.propTypes = {
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
