import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { MFAFactor } from '../../../../common/types/auth'
import MFAStore from '../mfaTypes'

export const setMFAFactor = (
  state: Draft<MFAStore>,
  action: PayloadAction<MFAFactor | undefined>
): void => {
  state.factor = action.payload
}
