import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GlobalParamsPayload,
  setFromApi,
} from '../../../app/actions/setFromApi'
import initialState from './companyDetailsInitialState'
import { EnasarcoType } from './companyDetailsStoreTypes'
import {
  decrementUsageLimit,
  incrementUsageLimit,
  setUsageLimit,
} from './reducers/limitsReducer'

interface SetCompanyType {
  companyType: string
}

interface SetLicenseExpiringDate {
  licenseExpiringDate: string
}

interface SetEiSendingCounter {
  eiSendingCounter: number
}

interface SetHasMed {
  hasMed: boolean
}

interface SetAccountantsCount {
  accountantsCount: number
}

interface SetHasBlockedNumerations {
  hasBlockedNumerations: boolean
}

interface SetIsAgyoActive {
  active: boolean
}

interface SetCountry {
  country: string
}

interface SetNeedTermsConditionsConfirmation {
  needTermsConditionsConfirmation: boolean
}

interface SetIsRegimeForfettario {
  setIsRegimeForfettario: boolean
}

interface SetForfettariRevenue {
  forfettariRevenue: number
}

interface SetVatNumber {
  vat: string
}

type SetInvoiceTradingStatus = {
  status: string
}

interface SetPlanId {
  planId: number
}

const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState,
  reducers: {
    setCompanyCountry: (state, action: PayloadAction<SetCountry>) => {
      const { country } = action.payload
      state.country = country
    },
    setCompanyType: (state, action: PayloadAction<SetCompanyType>) => {
      const { companyType } = action.payload
      state.companyType = companyType
    },
    setLicenseExpiringDate: (
      state,
      action: PayloadAction<SetLicenseExpiringDate>
    ) => {
      const { licenseExpiringDate } = action.payload
      state.licenseExpire = licenseExpiringDate
    },
    setEiSendingCounter: (
      state,
      action: PayloadAction<SetEiSendingCounter>
    ) => {
      const { eiSendingCounter } = action.payload
      state.pa.eiSendingCounter = eiSendingCounter
    },
    setHasMed: (state, action: PayloadAction<SetHasMed>) => {
      const { hasMed } = action.payload
      state.hasMed = hasMed
    },
    setAccountantsCount: (
      state,
      action: PayloadAction<SetAccountantsCount>
    ) => {
      const { accountantsCount } = action.payload
      state.accountantsCount = accountantsCount
    },
    setHasBlockedNumerations: (
      state,
      action: PayloadAction<SetHasBlockedNumerations>
    ) => {
      const { hasBlockedNumerations } = action.payload
      state.hasBlockedNumerations = hasBlockedNumerations
    },
    setIsAgyoActive: (state, action: PayloadAction<SetIsAgyoActive>) => {
      const { active } = action.payload
      state.isAgyoActive = active
    },
    setNeedTermsConditionsConfirmation: (
      state,
      action: PayloadAction<SetNeedTermsConditionsConfirmation>
    ) => {
      const { needTermsConditionsConfirmation } = action.payload
      state.needTermsConditionsConfirmation = needTermsConditionsConfirmation
    },
    setIsRegimeForfettario: (
      state,
      action: PayloadAction<SetIsRegimeForfettario>
    ) => {
      const { setIsRegimeForfettario } = action.payload
      state.regimeForfettario = setIsRegimeForfettario
    },
    setForfettariRevenue: (
      state,
      action: PayloadAction<SetForfettariRevenue>
    ) => {
      const { forfettariRevenue } = action.payload
      state.forfettariRevenue = forfettariRevenue
    },
    setVatNumber: (state, action: PayloadAction<SetVatNumber>) => {
      const { vat } = action.payload
      state.vatNumber = vat
    },
    decrementUsageLimit,
    incrementUsageLimit,
    setUsageLimit,
    setInvoiceTradingStatus: (
      state,
      action: PayloadAction<SetInvoiceTradingStatus>
    ) => {
      state.invoiceTradingStatus = action.payload.status
    },
    setEnasarco: (state, action: PayloadAction<Partial<EnasarcoType>>) => {
      state.enasarco = { ...state.enasarco, ...action.payload }
    },
    setPlanId: (state, action: PayloadAction<SetPlanId>) => {
      const { planId } = action.payload
      state.planId = planId
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.auth.companyDetails
      }
    )
  },
})

export default companyDetailsSlice
