import { BECallStatus } from '../../../common/types'
import AccountantStore from './accountantTypes'

const accountantStore: AccountantStore = {
  clientsInvitations: {
    callStatus: BECallStatus.IDLE,
  },
}

export default accountantStore
