import { SubscriptionPageLimits } from '../../components/pages/settings/pages/settingsSubscriptionPage/common/types'
import { LicensePlan, LimitCategory, NumericLicensePlan } from './types'

export const licenseNames: Record<LicensePlan, string> = {
  [LicensePlan.TRIAL]: 'In prova',
  [LicensePlan.STANDARD]: 'Standard',
  [LicensePlan.PREMIUM]: 'Premium',
  [LicensePlan.PREMIUM_PLUS]: 'Premium Plus',
  [LicensePlan.COMPLETE]: 'Complete',
}

export const planIdMap: Record<number, LicensePlan> = {
  [NumericLicensePlan.TRIAL]: LicensePlan.TRIAL,
  [NumericLicensePlan.STANDARD]: LicensePlan.STANDARD,
  [NumericLicensePlan.PREMIUM]: LicensePlan.PREMIUM,
  [NumericLicensePlan.PREMIUM_PLUS]: LicensePlan.PREMIUM_PLUS,
  [NumericLicensePlan.COMPLETE]: LicensePlan.COMPLETE,
}

export const reversePlanMap: Record<LicensePlan, number> = {
  [LicensePlan.TRIAL]: NumericLicensePlan.TRIAL,
  [LicensePlan.STANDARD]: NumericLicensePlan.STANDARD,
  [LicensePlan.PREMIUM]: NumericLicensePlan.PREMIUM,
  [LicensePlan.PREMIUM_PLUS]: NumericLicensePlan.PREMIUM_PLUS,
  [LicensePlan.COMPLETE]: NumericLicensePlan.COMPLETE,
}

// Default trial limits, used only to populate state when needed
export const defaultLimits: SubscriptionPageLimits = {
  [new Date().getFullYear()]: {
    [LimitCategory.DOCUMENTS]: {
      gifted: 0,
      limit: 100,
      usage: 0,
      isCustom: false,
    },
    [LimitCategory.CLIENTS]: {
      gifted: 0,
      limit: 500,
      usage: 0,
      isCustom: false,
    },
    [LimitCategory.PRODUCTS]: {
      gifted: 0,
      limit: 500,
      usage: 0,
      isCustom: false,
    },
    [LimitCategory.SUPPLIERS]: {
      gifted: 0,
      limit: 500,
      usage: 0,
      isCustom: false,
    },
  },
}

export const licensePurchaseWindowDays = 31
