import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setLocalStorage } from '../../redux/features/localStorage'
import {
  ficCdnGeneral,
  isDevelopment,
  isProduction,
  version as appVersion,
} from '../environment'
import Api from '../network/Api'
import useFocusSharedTabs from './useFocusSharedTabs'

interface ConfigPollingApiResponseType {
  maintenance: {
    startAt: string
    message: string
  }
  version: string
}

const useConfigPolling = (): void => {
  const dispatch = useDispatch()

  const doConfigPolling = useCallback((): void => {
    if (isDevelopment || isProduction) {
      void Api.get<ConfigPollingApiResponseType>({
        request: 'config.json',
        url: ficCdnGeneral,
        noCache: true,
        noAlerts: true,
      }).then(response => {
        const { maintenance, version } = response.data
        if (version && maintenance) {
          dispatch(setLocalStorage({ maintenance: maintenance }))
          if (appVersion !== version) {
            dispatch(setLocalStorage({ version }))
          }
        }
      })
    }
  }, [dispatch])

  useFocusSharedTabs(doConfigPolling, 60 * 1000)
}

export default useConfigPolling
