import PropTypes from 'prop-types'
import React from 'react'

import { FICFormField, Label } from '../../forms-v2/FICForm/FICFormField'

const FICFormNode = props => {
  const { config, schema } = props
  const nodeProps = { ...props }
  // Load config
  const nodeConfig = config.components[schema.type]
  if (!nodeConfig) {
    console.error(
      "FICForm: Component config not found for type '" + schema.type + "'."
    )
    return null
  }
  // Generate items if list
  if (nodeConfig.role === 'list') {
    const ItemComponent = nodeConfig.itemComponent
    const items = schema.items.map((itemSchema, idx) => {
      const itemProps = { ...nodeProps, schema: itemSchema }

      let validationFields = []
      if (itemSchema.handleChildrenValidation) {
        itemProps.hideValidation = true

        if (itemSchema.items) {
          validationFields = itemSchema.items.map(item => {
            return item.name
          })
        }
      }

      return (
        <ItemComponent
          key={idx}
          flex={itemSchema.flex || undefined}
          style={{ display: itemSchema.isVisible === false ? 'none' : 'block' }}
          className={itemSchema.className}
          validation={
            itemSchema.handleChildrenValidation
              ? nodeProps.validation
              : undefined
          }
          validationFields={
            itemSchema.handleChildrenValidation ? validationFields : undefined
          }
        >
          <FICFormNode {...itemProps} />
        </ItemComponent>
      )
    })

    // Wrap list into component
    const Component = nodeConfig.component
    const LabelComponent = nodeConfig.labelComponent
    return (
      <Component>
        <LabelComponent schema={schema} />
        {items}
      </Component>
    )
  }

  // Return form field if field
  if (nodeConfig.role === 'field') {
    const FieldComponent = nodeConfig.fieldComponent || FICFormField
    return <FieldComponent {...nodeProps} />
  }
}

FICFormNode.defaultProps = {
  config: {},
  schema: {},
  data: {},
  onChange: undefined,
  validation: {},
  formOptions: {},
}

FICFormNode.propTypes = {
  config: PropTypes.object,
  schema: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
  formOptions: PropTypes.object,
}

export default FICFormNode
