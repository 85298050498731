import { BECallStatus } from '../../../common/types'
import { GlobalStore } from '../../app/rootReducer'
import AccountantStore from './accountantTypes'

export const selectAccountantStore = (state: GlobalStore): AccountantStore =>
  state.accountant

export const selectClientsInvitationsStatus = (
  state: GlobalStore
): BECallStatus => state.accountant.clientsInvitations.callStatus
