import '../css/FICTextButton.css'

import { Icon } from '@fattureincloud/fic-design-system'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import { buildDataSetObject } from './componentsUtils'

/*

PROPS:
- text: (default "")
- color: (default primary)
- size: (default large)
- iconLeft: (default undefined)
- iconRight: (default undefined)
- style
- onClick
- href
- loading
- disabled
*/

class FICTextButton extends Component {
  constructor(props) {
    super(props)
    this.clickable = true
  }

  render() {
    const props = this.props
    const color = props.color ? props.color : 'primary'
    const size = props.size ? props.size : 'large'
    const disable = props.disabled ? 'btn-disabled' : ''
    const className =
      'fic-text-button btn-flat ' +
      color +
      ' ' +
      size +
      ' ' +
      disable +
      (props.className ? ' ' + props.className : '')
    const dataSet = buildDataSetObject(props.dataSet)
    const customProps = props.reloadPage
      ? { href: props.href, className: className }
      : { href: props.href, className: className + ' ajax-link' }
    let iconLeft = null
    let iconRight = null
    if (props.loading) {
      iconLeft = (
        <LeftWrapper hasText={!!props.text}>
          <CustomIcon fixedWidth icon={faCircleNotch} spin />
        </LeftWrapper>
      )
    } else if (props.iconLeft) {
      iconLeft = (
        <LeftWrapper hasText={!!props.text}>
          <CustomIcon fixedWidth icon={props.iconLeft} />
        </LeftWrapper>
      )
    }
    if (props.iconRight) {
      iconRight = (
        <RightWrapper hasText={!!props.text}>
          <CustomIcon fixedWidth icon={props.iconRight} />
        </RightWrapper>
      )
    }
    return (
      <a
        style={props.style}
        onClick={e => {
          if (!props.reloadPage) e.preventDefault()
          if (!this.clickable || props.loading || props.disabled) {
            return
          }
          this.clickable = false
          setTimeout(() => {
            this.clickable = true
          }, 500)
          if (props.onClick) {
            props.onClick()
          }
        }}
        target={props.target}
        {...customProps}
        {...dataSet}
        {...props.nativeProps}
      >
        {iconLeft}
        {props.text}
        {iconRight}
      </a>
    )
  }
}

const CustomIcon = styled(Icon)`
  display: inline;
`

const LeftWrapper = styled.span`
  margin-right: ${({ hasText }) => (hasText ? 4 : 0)}px;
`

const RightWrapper = styled.span`
  margin-left: ${({ hasText }) => (hasText ? 4 : 0)}px;
`

FICTextButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  href: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  reloadPage: PropTypes.bool,
}

FICTextButton.defaultProps = {
  text: '',
  color: 'primary',
  size: 'large',
  href: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  style: undefined,
  onClick: undefined,
  disabled: false,
  target: '',
  reloadPage: false,
}

export default FICTextButton
