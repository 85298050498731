import { Draft, PayloadAction } from '@reduxjs/toolkit'

import initialState from '../localStorageInitialState'
import LocalStorageStore, { localStorageKeys } from '../localStorageStoreTypes'
import {
  resetFromLocalStorageObject,
  updateLocalStorageObject,
} from '../localStorageUtils'

interface ResetLocalStorageItem {
  key: localStorageKeys
}

export const setReduxStorage = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<Partial<LocalStorageStore>>
): LocalStorageStore => ({
  ...state,
  ...action.payload,
})

export const setLocalStorage = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<Partial<LocalStorageStore>>
): LocalStorageStore => {
  updateLocalStorageObject(action.payload)
  return {
    ...state,
    ...action.payload,
  }
}

export const resetLocalStorageItem = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<ResetLocalStorageItem>
): LocalStorageStore => {
  const { key } = action.payload
  resetFromLocalStorageObject(key, initialState[key])
  return { ...state, [key]: initialState[key] }
}
