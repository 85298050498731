import { emailHasError, emailHasPecDomain } from '../emailUtils'
import { validateLength } from './stringValidators'
import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

export type EmailValidatorParams = ValidatorParams & {
  checkPEC?: boolean
}

/**
 * Email validator
 * @param {string} email - The email to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateEmail = (
  email: string,
  params?: EmailValidatorParams
): ValidationResult => {
  if (email === '' && params?.allowEmpty) return Validation.success()
  if (params?.minLength || params?.maxLength) {
    const lengthCheck = validateLength(email, params)
    if (!lengthCheck.valid) return lengthCheck
  }

  const error = emailHasError(email)
  if (error) return Validation.error(error)

  if (params?.checkPEC) {
    const pecWarn = emailHasPecDomain(email)
    if (pecWarn) return Validation.warning(pecWarn)
  }

  return Validation.success()
}
