import {
  add,
  compare,
  diff,
  endOfMonth,
  format,
  getYear,
  isAfter,
  isBefore,
  isPast,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  ParsableDateTime,
  parse,
  subtract,
  unix,
} from '@fattureincloud/madbit-js-dates'

import { TDateTime, TimeFormat } from './types'

const timeRegex = /\d{2}:\d{2}(:\d{2})?/
const PREV_YEAR = (new Date().getFullYear() - 1).toString()
const THIS_YEAR = new Date().getFullYear().toString()
const NEXT_YEAR = (new Date().getFullYear() + 1).toString()

/* Wrap all library methods, if something changes there is only on file to modify */
const FICDateTime: TDateTime = {
  add: (...args) => add(...args),
  compare: (...args) => compare(...args),
  currentYear: new Date().getFullYear(),
  diff: (...args) => diff(...args),
  endOfMonth: (...args) => endOfMonth(...args),
  format: (...args) => format(...args),
  getYear: (...args) => getYear(...args),
  isAfter: (...args) => isAfter(...args),
  isBefore: (...args) => isBefore(...args),
  isPast: (...args) => isPast(...args),
  isToday: (...args) => isToday(...args),
  isTomorrow: (...args) => isTomorrow(...args),
  isValid: (...args) => isValid(...args),
  isYesterday: (...args) => isYesterday(...args),
  parse: (...args) => parse(...args),
  subtract: (...args) => subtract(...args),
  unix: (...args) => unix(...args),
  formatTime: (time: string, format: TimeFormat): string => {
    if (!timeRegex.test(time)) return ''
    const [h, m, s] = time.split(':')
    return format === 'HH:mm' ? `${h}:${m}` : `${h}:${m}:${s ?? '00'}`
  },
  PREV_YEAR,
  THIS_YEAR,
  NEXT_YEAR,
  formatWithMonthDateTime: (dateTime: ParsableDateTime): string => {
    if (!dateTime) {
      return ''
    }
    const formattedDate = new Date(dateTime).toLocaleString('it-IT', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
    const capitalizedDate = formattedDate.replace(/\b\w/g, char =>
      char.toUpperCase()
    )
    return capitalizedDate
  },
}

export default FICDateTime
