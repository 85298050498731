import { isKey } from './tsUtils'

export const generateRandomId = (): string =>
  (Math.random() + 1).toString(36).substring(7)

export function formatIBAN(iban: string, joined: true): string
export function formatIBAN(iban: string, joined: false): Array<string>
export function formatIBAN(iban: string): Array<string>
export function formatIBAN(
  iban: string,
  joined = false
): Array<string> | string {
  if (!iban) return ''

  const i = iban.replace(/\s/g, '').toUpperCase()
  const parts = [
    i.substr(0, 2),
    i.substr(2, 2),
    i.substr(4, 1),
    i.substr(5, 5),
    i.substr(10, 5),
    i.substr(15, 12),
  ]

  return joined ? parts.filter(p => p !== '').join(' ') : parts
}

export const formatHiddenIBAN = (iban: string | undefined): string =>
  iban ? 'IT' + iban.padStart(25, '*') : ''

export const formatHiddenCardNumber = (num: string | undefined): string =>
  num ? `**** **** **** ${num}` : ''

/**
 * Convert a number into italian currency value with euro symbol
 * @param {number|string|undefined|null} number
 * @param {string} symbolSide
 * @param {string} symbol
 *
 * @returns {string} string
 */
export const currency = (
  number: number | string | undefined | null,
  symbolSide: 'left' | 'right' = 'right',
  symbol = '€'
): string => {
  if (number === undefined || number === null || number === '') {
    return ''
  }
  if (typeof number !== 'number') {
    number = parseFloat(number)
  }
  return isNaN(number) || !isFinite(number)
    ? ''
    : (symbolSide === 'left' ? `${symbol} ` : '') +
        number.toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) +
        (symbolSide === 'right' ? ` ${symbol}` : '')
}

export const formatRange = (
  a: number | null,
  b: number | null,
  currency = ''
): string => {
  if (a && b) return `da ${a}${currency} a ${b}${currency}`
  if (a && !b) return `sopra ${a}${currency}`
  if (!a && b) return `fino a ${b}${currency}`

  return currency ? 'qualsiasi importo' : 'qualsiasi valore'
}

export const stringToFloat = (n: string): number =>
  parseFloat(n.replace(',', '.'))

export const floatToString = (n: number): string =>
  n.toString().replace('.', ',')

export const isNumeric = (n: string): boolean => {
  return !isNaN(stringToFloat(n)) && isFinite(stringToFloat(n))
}

export const shortenString = (
  text: string,
  maxLength: number,
  ellipsis = false
): string =>
  text.slice(0, maxLength) + (text.length > maxLength && ellipsis ? '...' : '')

export const stringConcat = (texts: string[], separator = ' '): string =>
  texts.filter(Boolean).join(separator)

export const capitalize = (s: string): string =>
  s ? s[0].toUpperCase() + s.slice(1) : ''

export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakeToCamelCase = (str: string): string =>
  str.replace(/_([a-z])/g, (_, letter: string) => letter.toUpperCase())

export const base64JsonParse = <T extends Record<string, unknown>>(
  encoded: string
): T | undefined => {
  try {
    return JSON.parse(atob(encoded)) as T
  } catch (e) {
    return undefined
  }
}

export const replaceAccents = (
  str: string,
  splitSeparator = '',
  joinSeparator = ''
): string =>
  str
    .toLowerCase()
    .split(splitSeparator)
    .join(joinSeparator)
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')

const accentsMap = new Map([
  ['Š', 'S'],
  ['š', 's'],
  ['Ž', 'Z'],
  ['ž', 'z'],
  ['À', 'A'],
  ['Á', 'A'],
  ['Â', 'A'],
  ['Ã', 'A'],
  ['Ä', 'A'],
  ['Å', 'A'],
  ['Æ', 'A'],
  ['Ç', 'C'],
  ['È', 'E'],
  ['É', 'E'],
  ['Ê', 'E'],
  ['Ë', 'E'],
  ['Ì', 'I'],
  ['Í', 'I'],
  ['Î', 'I'],
  ['Ï', 'I'],
  ['Ñ', 'N'],
  ['Ò', 'O'],
  ['Ó', 'O'],
  ['Ô', 'O'],
  ['Õ', 'O'],
  ['Ö', 'O'],
  ['Ø', 'O'],
  ['Ù', 'U'],
  ['Ú', 'U'],
  ['Û', 'U'],
  ['Ü', 'U'],
  ['Ý', 'Y'],
  ['Þ', 'B'],
  ['ß', 'Ss'],
  ['à', 'a'],
  ['á', 'a'],
  ['â', 'a'],
  ['ã', 'a'],
  ['ä', 'a'],
  ['å', 'a'],
  ['æ', 'a'],
  ['ç', 'c'],
  ['è', 'e'],
  ['é', 'e'],
  ['ê', 'e'],
  ['ë', 'e'],
  ['ì', 'i'],
  ['í', 'i'],
  ['î', 'i'],
  ['ï', 'i'],
  ['ð', 'o'],
  ['ñ', 'n'],
  ['ò', 'o'],
  ['ó', 'o'],
  ['ô', 'o'],
  ['õ', 'o'],
  ['ö', 'o'],
  ['ø', 'o'],
  ['ù', 'u'],
  ['ú', 'u'],
  ['û', 'u'],
  ['ý', 'y'],
  ['þ', 'b'],
  ['ÿ', 'y'],
])

export const replaceSpecialChars = (str: string): string =>
  str
    .split('')
    .map(char => (!accentsMap.has(char) ? char : accentsMap.get(char)))
    .join('')

// Vowels array  for the plural function below
const vowels = [
  'a',
  'à',
  'e',
  'è',
  'é',
  'i',
  'ì',
  'o',
  'ò',
  'u',
  'ù',
  'A',
  'E',
  'I',
  'O',
  'U',
  "'",
]

/**
 * Transform a string from singular to plural if the provided number is zero or greater than 1
 *
 * @param {number} num
 * @param {string} str
 *
 * @return {string}
 */
export const plural = (num: number, str: string): string => {
  let result = str.replace('%n%', `${num}`)
  let slash = result.indexOf('/')
  if (slash < 0 || num < 0) return result

  do {
    // Search parenthesis
    const firstPar = result.indexOf('[')
    const lastPar = result.indexOf(']', slash)
    if (firstPar >= 0 && lastPar >= 0) {
      const [sing, plur] = result
        .slice(firstPar + 1, lastPar + 1)
        .replace('[', '')
        .replace(']', '')
        .split('/')

      result =
        result.slice(0, firstPar) +
        (num === 1 ? sing : plur) +
        result.slice(lastPar + 1)
    } else {
      if (num === 1) {
        // Find the end of the word
        let cutEnd = slash
        do {
          cutEnd += 1
        } while (result.charAt(cutEnd) !== ' ' && cutEnd < result.length)

        result = result.slice(0, slash) + result.slice(cutEnd)
      } else {
        let cutStart = slash
        do {
          cutStart -= 1
        } while (vowels.includes(result.charAt(cutStart)) && cutStart > 0)
        result = result.slice(0, cutStart + 1) + result.slice(slash + 1)
      }
    }

    // Check if there's another slash
    slash = result.indexOf('/')
  } while (slash >= 0)
  return result
}

/**
 * Given an e-mail, it returns a protected string, example input: testpippo@gmail.com => test****@****.com
 * @param mail
 */
export const protectEmail = (mail: string): string => {
  const [name, fullDomain] = mail.split('@')
  const [domain, country] = fullDomain.split('.')
  const domainLen = Math.min(domain.length, 10)
  const blockedName =
    name.length >= 4
      ? `${name.substr(0, 4)}${'*'.repeat(name.length - 4)}`
      : name
  return `${blockedName}@${'*'.repeat(domainLen)}.${country}`
}

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>?@[\\\]^_{|}~])[A-Za-z\d!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]{8,}$/

const passLower = /[a-z]/
const passUpper = /[A-Z]/
const passNumbers = /[\d]/
const passSpecial = /[!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]/
const invalidChars = /[^a-zA-Z\d!#$%&()*+,-./:;<=>?@[\\\]^_{|}~]/

export type PassValidation = {
  all: boolean
  length: boolean
  lower: boolean
  numbers: boolean
  special: boolean
  upper: boolean
  invalid: boolean
}

export function validatePassword(pass: string, split: true): PassValidation
export function validatePassword(pass: string, split: false): boolean
export function validatePassword(pass: string): boolean
export function validatePassword(
  pass: string,
  split = false
): boolean | PassValidation {
  if (split) {
    const v: PassValidation = {
      all: true,
      length: pass.length > 7,
      lower: passLower.test(pass),
      numbers: passNumbers.test(pass),
      special: passSpecial.test(pass),
      upper: passUpper.test(pass),
      invalid: invalidChars.test(pass),
    }
    v.all = Object.keys(v).every(key =>
      isKey(key, v) ? (key === 'invalid' ? !v[key] : v[key]) : false
    )
    return v
  }

  return passwordRegex.test(pass)
}

/**
 * Check if a telephone number is valid
 * @param {string} phoneNumber
 *
 * @return {boolean}
 */
export const isPhoneNumberValid = (
  phoneNumber: string,
  allowEmpty = false
): boolean => {
  phoneNumber = phoneNumber.replace(/\D/g, '')
  return allowEmpty && phoneNumber.length === 0
    ? true
    : phoneNumber.length >= 5 && phoneNumber.length <= 15
}

export const trimChar = (str: string, char: string): string => {
  let start = 0,
    end = str.length

  while (start < end && str[start] === char) {
    ++start
  }

  while (end > start && str[end - 1] === char) {
    --end
  }

  return start > 0 || end < str.length ? str.substring(start, end) : str
}

/**
 * Given an array of strings, it returns a string with all the words separated by a comma except the last one that is separated by 'e'
 */
export const listStrings = (words: string[]): string => {
  if (words.length === 0) return ''
  if (words.length === 1) return words[0]
  return words.slice(0, -1).join(', ') + ' e ' + words.slice(-1)[0]
}
