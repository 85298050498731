import { isNumber } from '../utils/tsUtils'
import { licenseNames, planIdMap, reversePlanMap } from './constants'
import { LicensePlan } from './types'

/* CONVERSION FUNCTIONS */

export const getLicenseName = (plan: LicensePlan | number): string =>
  isNumber(plan) ? licenseNames[planIdMap[plan]] : licenseNames[plan]

export const numToString = (plan: number): LicensePlan => planIdMap[plan]

export const stringToNum = (plan: LicensePlan): number => reversePlanMap[plan]

/* EQUAL COMPARISON FUNCTIONS */

const is = (plan: LicensePlan | number, check: LicensePlan) =>
  isNumber(plan) ? plan === reversePlanMap[check] : plan === check

export const isTrial = (plan: LicensePlan | number): boolean =>
  is(plan, LicensePlan.TRIAL)

export const isStandard = (plan: LicensePlan | number): boolean =>
  is(plan, LicensePlan.STANDARD)

export const isPremium = (plan: LicensePlan | number): boolean =>
  is(plan, LicensePlan.PREMIUM)

export const isPremiumPlus = (plan: LicensePlan | number): boolean =>
  is(plan, LicensePlan.PREMIUM_PLUS)

export const isComplete = (plan: LicensePlan | number): boolean =>
  is(plan, LicensePlan.COMPLETE)

/* MIN COMPARISON FUNCTIONS */

const min = (
  currentPlan: LicensePlan | number,
  minPlan: LicensePlan
): boolean => {
  const curr: number = isNumber(currentPlan)
    ? currentPlan
    : reversePlanMap[currentPlan]

  return curr >= reversePlanMap[minPlan]
}

export const isMinStandard = (plan: LicensePlan | number): boolean =>
  min(plan, LicensePlan.STANDARD)

export const isMinPremium = (plan: LicensePlan | number): boolean =>
  min(plan, LicensePlan.PREMIUM)

export const isMinPremiumPlus = (plan: LicensePlan | number): boolean =>
  min(plan, LicensePlan.PREMIUM_PLUS)

/* MAX COMPARISON FUNCTIONS */

const max = (currentPlan: LicensePlan | number, maxPlan: LicensePlan) => {
  const curr: number = isNumber(currentPlan)
    ? currentPlan
    : reversePlanMap[currentPlan]

  return curr <= reversePlanMap[maxPlan]
}

export const isMaxStandard = (plan: LicensePlan | number): boolean =>
  max(plan, LicensePlan.STANDARD)

export const isMaxPremium = (plan: LicensePlan | number): boolean =>
  max(plan, LicensePlan.PREMIUM)

export const isMaxPremiumPlus = (plan: LicensePlan | number): boolean =>
  max(plan, LicensePlan.PREMIUM_PLUS)
