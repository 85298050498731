import { Draft, PayloadAction } from '@reduxjs/toolkit'

import TSPayStore from '../tsPayTypes'

export const setBankReconciliationActive = (
  state: Draft<TSPayStore>,
  action: PayloadAction<boolean>
): void => {
  state.bankReconciliation = action.payload
}
