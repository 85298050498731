import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { BECallStatus } from '../../../../common/types'
import AccountantStore, { ClientsInvitations } from '../accountantTypes'

export const setClientsInvitations = (
  state: Draft<AccountantStore>,
  action: PayloadAction<ClientsInvitations>
): void => {
  state.clientsInvitations = action.payload
}

export const setClientsInvitationsCallStatus = (
  state: Draft<AccountantStore>,
  action: PayloadAction<BECallStatus>
): void => {
  state.clientsInvitations.callStatus = action.payload
}
