import { Title2, Title3 } from '@fattureincloud/fic-design-system'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'

import { setOuterLoading } from '../../../redux/features/loaders/loadersSlice'
import maintenanceIllustration from '../../../resources/images/illustrations/maintenance.svg'
import logoFIC from '../../../resources/images/logos/ficNew/ficLogoHorizontal2RowsColor.svg'
import { Left, Logo, MaintenanceImage, MaintenancePage, Right } from './styled'

const Maintenance = (): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    const target = document.querySelector<HTMLElement>(
      '#react-overlay-help-container'
    )
    if (target) {
      ReactDOM.unmountComponentAtNode(target)
      window.setTimeout(() => {
        dispatch(setOuterLoading({ isLoading: false }))
      }, 500)
    }
  }, [dispatch])

  return (
    <MaintenancePage>
      <Left>
        <Logo alt='Logo Fatture in Cloud' src={logoFIC} />
        <Title2 centered bold>
          Torniamo subito!
        </Title2>
        <Title3 centered>
          Fatture in Cloud è temporaneamente
          <br />
          inattivo per manutenzione.
        </Title3>
      </Left>
      <Right>
        <MaintenanceImage src={maintenanceIllustration} />
      </Right>
    </MaintenancePage>
  )
}

export default Maintenance
