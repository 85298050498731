import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

import { FICSpacing } from '../../config/theme/spacing/spacing'

type MarginParam = keyof FICSpacing | 0

type MarginParams =
  | [MarginParam]
  | [MarginParam, MarginParam]
  | [MarginParam, MarginParam, MarginParam]
  | [MarginParam, MarginParam, MarginParam, MarginParam]

export const margin = (
  ...args: MarginParams
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    margin: ${({ theme }) =>
      args.reduce(
        (prev, curr) => `${prev} ${curr === 0 ? '0px' : theme.spacing[curr]}`,
        ''
      )};
  `

/* TOP */
export const marginTopXXS = css`
  margin: ${({ theme }) => theme.spacing.XXS};
`

export const marginTopXS = css`
  margin: ${({ theme }) => theme.spacing.XS};
`

export const marginTopS = css`
  margin: ${({ theme }) => theme.spacing.S};
`

export const marginTopM = css`
  margin: ${({ theme }) => theme.spacing.M};
`

export const marginTopL = css`
  margin: ${({ theme }) => theme.spacing.L};
`

export const marginTopXL = css`
  margin: ${({ theme }) => theme.spacing.XL};
`

export const marginTopXXL = css`
  margin: ${({ theme }) => theme.spacing.XXL};
`

/* RIGHT */
export const marginRightXXS = css`
  margin: ${({ theme }) => theme.spacing.XXS};
`

export const marginRightXS = css`
  margin: ${({ theme }) => theme.spacing.XS};
`

export const marginRightS = css`
  margin: ${({ theme }) => theme.spacing.S};
`

export const marginRightM = css`
  margin: ${({ theme }) => theme.spacing.M};
`

export const marginRightL = css`
  margin: ${({ theme }) => theme.spacing.L};
`

export const marginRightXL = css`
  margin: ${({ theme }) => theme.spacing.XL};
`

export const marginRightXXL = css`
  margin: ${({ theme }) => theme.spacing.XXL};
`

/* LEFT */
export const marginLeftXXS = css`
  margin: ${({ theme }) => theme.spacing.XXS};
`

export const marginLeftXS = css`
  margin: ${({ theme }) => theme.spacing.XS};
`

export const marginLeftS = css`
  margin: ${({ theme }) => theme.spacing.S};
`

export const marginLeftM = css`
  margin: ${({ theme }) => theme.spacing.M};
`

export const marginLeftL = css`
  margin: ${({ theme }) => theme.spacing.L};
`

export const marginLeftXL = css`
  margin: ${({ theme }) => theme.spacing.XL};
`

export const marginLeftXXL = css`
  margin: ${({ theme }) => theme.spacing.XXL};
`

/* BOTTOM */
export const marginBottomXXS = css`
  margin: ${({ theme }) => theme.spacing.XXS};
`

export const marginBottomXS = css`
  margin: ${({ theme }) => theme.spacing.XS};
`

export const marginBottomS = css`
  margin: ${({ theme }) => theme.spacing.S};
`

export const marginBottomM = css`
  margin: ${({ theme }) => theme.spacing.M};
`

export const marginL = css`
  margin: ${({ theme }) => theme.spacing.L};
`

export const marginXL = css`
  margin: ${({ theme }) => theme.spacing.XL};
`

export const marginXXL = css`
  margin: ${({ theme }) => theme.spacing.XXL};
`
