import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { BECallStatus } from '../../../../common/types'
import {
  GetRecoveryCodeResponse,
  RecoveryCodeStatus,
} from '../../../../common/types/auth'
import { MFAStore } from '..'
import { RecoveryCode } from '../mfaTypes'

const generateRecoveryCode = createAsyncThunk<
  Partial<RecoveryCode>,
  void,
  {
    rejectValue: RecoveryCode
  }
>('mfa/generateRecoveryCode', async (_, thunkApi) => {
  try {
    const response = await Api.post<GetRecoveryCodeResponse>({
      request: 'mfa/recovery_code',
    })
    return {
      code: response.data.data.recoveryCode,
      fetchStatus: BECallStatus.SUCCESS,
      status: RecoveryCodeStatus.ACTIVE,
    }
  } catch (e) {
    return thunkApi.rejectWithValue({
      fetchStatus: BECallStatus.FAILED,
      remainingAttempts: 0,
      status: RecoveryCodeStatus.REGENERATED,
    })
  }
})

export const addGenerateRecoveryCodeCases = (
  builder: ActionReducerMapBuilder<MFAStore>
): void => {
  builder
    .addCase(generateRecoveryCode.pending, state => {
      state.recoveryCode.fetchStatus = BECallStatus.LOADING
    })
    .addCase(generateRecoveryCode.fulfilled, (state, action) => {
      state.recoveryCode = {
        ...state.recoveryCode,
        ...action.payload,
      }
    })
    .addCase(generateRecoveryCode.rejected, (state, action) => {
      if (action.payload) {
        state.recoveryCode = action.payload
      }
    })
}

export default generateRecoveryCode
