import { Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  MAX_DEFAULT_PAYMENT_AMOUNT,
  MIN_DEFAULT_PAYMENT_AMOUNT,
} from '../../../../components/pages/tspay/TSPayConstants'
import TSPayStore from '../tsPayTypes'

export type SetTSPayChargeAmounts = {
  min: number | null | undefined
  max: number | null | undefined
}

export const setIncomingPaymentsActive = (
  state: Draft<TSPayStore>,
  action: PayloadAction<boolean>
): void => {
  state.incomingPayments = action.payload
}

export const setChargeAmounts = (
  state: Draft<TSPayStore>,
  action: PayloadAction<SetTSPayChargeAmounts>
): void => {
  state.minChargeAmount = action.payload.min ?? MIN_DEFAULT_PAYMENT_AMOUNT
  state.maxChargeAmount = action.payload.max ?? MAX_DEFAULT_PAYMENT_AMOUNT
}
