import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import FICDateTime from '../../../../common/dateTime'
import Api from '../../../../common/network/Api'
import { GlobalStore } from '../../../app/rootReducer'
import PreferencesStore, {
  DismissConf,
  DismissibleElement,
} from '../preferencesStoreTypes'

type Returned = {
  key: DismissibleElement
  value: DismissConf
}

type ThunkArg = {
  key: DismissibleElement
  time?: number
}

type ThunkApiConfig = {
  rejectValue: false
  state: GlobalStore
}

const remindLaterDismissibleElement = createAsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
>(
  'preferences/remindLaterDismissibleElement',
  async ({ key, time }, { getState, rejectWithValue }) => {
    const { preferences } = getState()
    const newDate = FICDateTime.add(
      preferences[key].showAt ?? 'now',
      time ?? 1440, // 24 hours
      'minutes'
    )
    if (!FICDateTime.isValid(newDate)) {
      rejectWithValue(false)
    }

    const newValue: DismissConf = {
      showAt: FICDateTime.format(newDate, 'SQL+T'),
      dismissCount: preferences[key].dismissCount + 1,
    }

    try {
      await Api.post({
        request: 'user_preferences/set',
        postData: { field: key, value: newValue },
      })
      return { key, value: newValue }
    } catch (error) {
      return rejectWithValue(false)
    }
  }
)

export const addRemindLaterDismissibleElement = (
  builder: ActionReducerMapBuilder<PreferencesStore>
): void => {
  builder.addCase(remindLaterDismissibleElement.fulfilled, (state, action) => {
    const { key, value } = action.payload
    state[key] = value
  })
}

export default remindLaterDismissibleElement
