import {
  ComponentPropsWithRef,
  ComponentType,
  ExoticComponent,
  lazy,
} from 'react'

const chunkLoader = <T extends ComponentType<any>>(
  lazyComponent: () => Promise<{ default: T }>,
  attemptsLeft = 3
): Promise<{ default: T }> => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        window.setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }
          chunkLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 1500)
      })
  })
}

const lazyLoad = <T extends ComponentType<any>>(
  component: () => Promise<{ default: T }>
): ExoticComponent<ComponentPropsWithRef<T>> =>
  lazy(() => chunkLoader(component))

export default lazyLoad
