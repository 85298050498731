import { GlobalStore } from '../../app/rootReducer'
import LocalStorageStore, {
  AccountantCall,
  BankReconciliationWaitingActivation,
  MaintenanceStore,
  SidebarCounters,
  SinglePendingExpenseRegistrationType,
} from './localStorageStoreTypes'

export const getLocalStorage = (state: GlobalStore): LocalStorageStore =>
  state.localStorage

export const getAccessToken = (state: GlobalStore): string =>
  getLocalStorage(state).accessToken

export const getActiveWebinars = (state: GlobalStore): number[] =>
  getLocalStorage(state).activeWebinars

export const getHasuraAccessToken = (state: GlobalStore): string =>
  getLocalStorage(state).hasuraAccessToken

export const getLocalStorageVersion = (state: GlobalStore): string =>
  state.localStorage.version

export const getAccountantCall = (state: GlobalStore): AccountantCall | null =>
  state.localStorage.accountantCall

export const getAccountantWabinars = (state: GlobalStore): number =>
  state.localStorage.accountantWebinars

export const getMaintenance = (state: GlobalStore): MaintenanceStore =>
  state.localStorage.maintenance || {
    startAt: '',
    message: '',
  }

export const getBannerMessage = (state: GlobalStore): string =>
  state.localStorage.ficBannerMessage

interface XMasStatus {
  isXmasActive: boolean
  isSnowEnabled: boolean
}

export const getXmasStatus = (state: GlobalStore): XMasStatus => {
  const date = new Date()
  const isXmasActive =
    date.getDate() >= 20 && date.getDate() <= 31 && date.getMonth() === 11
  const isSnowEnabled = isXmasActive && getLocalStorage(state).isSnowEnabled

  return { isXmasActive, isSnowEnabled }
}

export const getSidebarCounters = (state: GlobalStore): SidebarCounters =>
  getLocalStorage(state).sidebarCounters

export const getBankReconciliationWaitingActivation = (
  state: GlobalStore
): BankReconciliationWaitingActivation =>
  getLocalStorage(state).bankReconciliationWaitingActivation

export const getLastOpenedSidebarMenu = (state: GlobalStore): string =>
  state.localStorage.lastOpenedSidebarMenu

export const getPendingExpensesRegistration = (
  state: GlobalStore
): SinglePendingExpenseRegistrationType[] =>
  getLocalStorage(state).pendingExpensesRegistration ?? []

export const getPendingExpensesCounter = (state: GlobalStore): number =>
  getLocalStorage(state).sidebarCounters.pendingExpensesCounter

export const getBankStatementsCounter = (state: GlobalStore): number =>
  getLocalStorage(state).sidebarCounters.bankStatementsCounter

export const getFiscalYear = (state: GlobalStore): number =>
  getLocalStorage(state).fiscalYear
export const getExpensesListSelectedMonths = (state: GlobalStore): number[] =>
  getLocalStorage(state).expensesListSelectedMonths
export const getExpensesListSearchText = (state: GlobalStore): string =>
  getLocalStorage(state).expensesListSearchText

export const getHasAutomaticRenewal = (state: GlobalStore): boolean =>
  getLocalStorage(state).hasAutomaticRenewal
