import { Toast } from '@fattureincloud/fic-design-system'
import React, { Fragment } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import useConfigPolling from '../../common/hooks/useConfigPolling'
import useInitApp from '../../common/hooks/useInitApp'
import useOldDashUrl from '../../common/hooks/useOldDashUrl'
import useStorageListener from '../../common/hooks/useStorageListener'
import useVersionRefresh from '../../common/hooks/useVersionRefresh'
import lazyLoad from '../../common/network/lazyLoad'
import { readFromFICConfig } from '../../redux/features/localStorage/localStorageUtils'
import LoadingOverlay from '../common/LoadingOverlay'
import GoogleTagMangerProvider from '../common/providers/googleTagManagerProvider/GoogleTagManagerProvider'
import ErrorPage from '../pages/error/ErrorPage'
import { ficConfigListener } from './components/Listeners'
import MaintenanceGuard from './components/MaintenanceGuard'
import useRouterNavigate from './components/useRouterNavigate'

const ExternalApp = lazyLoad(
  () => import('./components/externalApp/ExternalApp')
)

const InternalApp = lazyLoad(
  () => import('./components/internalApp/InternalApp')
)

const App = (): JSX.Element => {
  useOldDashUrl()
  useVersionRefresh()
  useConfigPolling()

  useStorageListener(ficConfigListener)

  useRouterNavigate()
  // Inizializza e chiama global_config api e registra device
  const isReady = useInitApp()

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <GoogleTagMangerProvider>
        <MaintenanceGuard>
          <Fragment>
            <Toast.Container position={'bottom-right'} />
            {isReady && (
              <>
                {readFromFICConfig('accessToken') ? (
                  <InternalApp />
                ) : (
                  <ExternalApp />
                )}
              </>
            )}
          </Fragment>
        </MaintenanceGuard>
        <LoadingOverlay />
      </GoogleTagMangerProvider>
    </ErrorBoundary>
  )
}

export default App
