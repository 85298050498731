import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { AuthIntent } from '../../../../common/types/auth'
import MFAStore from '../mfaTypes'

export const setMFAAuthIntent = (
  state: Draft<MFAStore>,
  action: PayloadAction<AuthIntent | undefined>
): void => {
  state.authIntent = action.payload
}
