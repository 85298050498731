import { Toast } from '@fattureincloud/fic-design-system'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ResponseType } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import store from '../../redux'
import { GlobalStore } from '../../redux/app/rootReducer'
import { getAccessToken } from '../../redux/features/localStorage/localStorageSelectors'
import { showQuestion } from '../../redux/features/question'
import { hostApi, isLocal } from '../environment'
import { ApiFilter, ApiSort } from '../types'
import {
  applyFilters,
  applySorting,
  convertQuestionOptions,
  createHeaders,
} from '../utils/apiUtils'
import { isQuestionError } from '../utils/errorUtils'
import { fileDownload } from '../utils/fileUtils'
import { logout } from '../utils/logout'
import { isUrl } from '../utils/urlUtils'

const dynamicBaseURL = isUrl(hostApi)
  ? hostApi
  : window.location.origin + hostApi

type ApiMethod = 'get' | 'post' | 'put' | 'delete'

export type AxiosHeaders = AxiosRequestConfig['headers']

export type ApiCallOptions = {
  customToken?: string
  filters?: ApiFilter[]
  formData?: FormData
  headers?: AxiosHeaders
  noAlerts?: boolean
  noCache?: boolean
  onError?: (error: AxiosError) => void | boolean
  postData?: Record<string, unknown>
  queryData?: Record<string, unknown>
  rawRequest?: boolean
  rawResponse?: boolean
  request: string
  responseType?: ResponseType
  signal?: AbortSignal
  skipLogoutOnUnauthorized?: boolean
  sort?: ApiSort[]
  url?: string
  useCredentials?: boolean
  withoutToken?: boolean
}

export type ApiGenericCallOptions = {
  method: ApiMethod
} & ApiCallOptions

type ApiGetFileOptions = {
  url: string
  filename: string
  onSuccess?: (res: AxiosResponse) => void
  onError?: (error: AxiosError) => void
  onFinish?: () => void
}

const excludeRegex = [/^_[a-zA-Z0-9_]*/g, /^([a-zA-Z0-9_]+)\.([a-zA-Z0-9_]+)/g]

export default class Api {
  public static call = async <T = any>({
    onError,
    ...otherCallOptions
  }: ApiGenericCallOptions): Promise<AxiosResponse<T>> => {
    const {
      filters,
      formData,
      method,
      noAlerts,
      postData = {},
      queryData = {},
      rawRequest,
      rawResponse,
      request,
      url,
      customToken,
      noCache,
      withoutToken,
      useCredentials = false,
      responseType = 'json',
      headers = {},
      signal,
      skipLogoutOnUnauthorized,
      sort,
    } = otherCallOptions

    try {
      //TODO: controllare come mai su IE11 transformResponse e transformRequest non funzionano e magari ottimizzare qui
      let response = await axios.request<T>({
        baseURL: url || dynamicBaseURL,
        url: applySorting(applyFilters(request, filters), sort),
        method,
        headers: createHeaders(
          customToken,
          formData !== undefined,
          headers,
          noCache,
          Boolean(url),
          withoutToken
        ),
        params: rawRequest
          ? queryData
          : snakecaseKeys(queryData, {
              deep: true,
              exclude: excludeRegex,
            }),
        data:
          formData ??
          (rawRequest
            ? postData
            : snakecaseKeys(postData, {
                deep: true,
                exclude: excludeRegex,
              })),
        withCredentials: useCredentials,
        responseType,
        signal,
      })

      if (!rawResponse) {
        response = camelcaseKeys(response, {
          deep: true,
        })
      }

      if (!noAlerts) {
        Api.handleAlert(response)
      }
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          !skipLogoutOnUnauthorized &&
          error?.response?.status === 401 &&
          !isLocal
        ) {
          logout()
        }

        if (error?.response) {
          if (isQuestionError(error)) {
            const { message, name, options, title } =
              error.response.data.extra.question

            store.dispatch(
              showQuestion({
                callParams: { ...otherCallOptions },
                error: message,
                name,
                options: convertQuestionOptions(options),
                title,
              })
            )
            throw error
          }

          const alertShown = noAlerts || Api.handleAlert(error?.response)
          let handleError = true
          if (onError) {
            handleError = onError(error) || false
          }
          if (handleError && !alertShown) {
            if (!Api.handleValidationError(error.response)) {
              if (!Api.handleError(error.response)) {
                Toast.error('Si è verificato un errore sconosciuto.')
              }
            }
          }
        }
      } else {
        console.error(JSON.stringify(error))
      }
      throw error
    }
  }

  public static get = <T = any>(options: ApiCallOptions) =>
    Api.call<T>({ ...options, method: 'get' })

  public static post = <T = any>(options: ApiCallOptions) =>
    Api.call<T>({ ...options, method: 'post' })

  public static put = <T = any>(options: ApiCallOptions) =>
    Api.call<T>({ ...options, method: 'put' })

  public static delete = <T = any>(options: ApiCallOptions) =>
    Api.call<T>({ ...options, method: 'delete' })

  public static download = (
    options: ApiCallOptions & { filename: string }
  ): void => {
    const { filename, ...callOptions } = options

    void Api.get<{ data: string } | ArrayBuffer | ArrayBufferView | Blob>({
      ...callOptions,
      responseType: 'blob',
    }).then(({ data: response }) => {
      if ('data' in response) {
        fileDownload(response.data, filename)
      } else {
        fileDownload(response, filename)
      }
    })
  }

  public static getFile = ({
    filename,
    onError,
    onFinish,
    onSuccess,
    url,
  }: ApiGetFileOptions): void => {
    const state: GlobalStore = store.getState()
    const token = getAccessToken(state)

    axios
      .get(url, {
        baseURL: dynamicBaseURL,
        responseType: 'blob',
        headers: token ? { Authorization: token } : {},
      })
      .then(res => {
        if (onSuccess) {
          onSuccess(res)
        }
        fileDownload(res.data, filename)
      })
      .catch(e => {
        if (onError) {
          onError(e)
        }
        if (axios.isAxiosError(e)) {
          if (e?.response?.status === 401 && !isLocal) {
            logout()
          }

          if (e?.response) {
            const alertShown = Api.handleAlert(e?.response)
            if (
              !alertShown &&
              !Api.handleValidationError(e.response) &&
              !Api.handleError(e.response)
            ) {
              Toast.error('Si è verificato un errore sconosciuto.')
            }
          }
        }
      })
      .finally(() => {
        if (onFinish) {
          onFinish()
        }
      })
  }

  private static headerFilename = (disposition: string) => {
    let filename = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches?.[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }
    return filename
  }

  private static handleAlert = (response: AxiosResponse) => {
    if (response?.data?.extra?.alert) {
      Toast.show(response.data.extra.alert.message, {
        type: response.data.extra.alert.type,
        autoClose: response.data.extra.alert.duration || 5000,
      })
      return true
    }
  }

  private static handleValidationError = (response: AxiosResponse) => {
    if (response?.data?.error?.validation_result) {
      const validations = response.data.error.validation_result
      const fields = Object.keys(validations)
      if (fields.length > 0) {
        const field = fields[0]
        const message = validations[field][0]
        Toast.error(message)
        return true
      }
    }
    return false
  }

  public static handleError = (response: AxiosResponse) => {
    if (response?.data?.error?.message) {
      const message = response.data.error.message as string
      // TODO: REMOVE THIS SOON -> Mapping temporaneo errore salvataggio spesa per aliquota ID non trovata
      if (message?.includes('aliquota IVA con id ')) {
        Toast.error('Impossibile salvare', {
          content:
            'Elimina il documento ricevuto, sincronizza le fatture mancanti e riprova.',
        })
      } else {
        Toast.error(message)
      }

      return true
    }
    return false
  }
}
