import styled, { css } from 'styled-components/macro'

import FICColors from '../../../common/styles/FICColors'

const Sidetext = styled.span`
  position: absolute !important;
  color: ${FICColors.placeholder};
  cursor: default;
  ${props =>
    props.size === 'large' &&
    css`
      top: 28%;
    `}
`

export const LeftText = styled(Sidetext)`
  left: 7px !important;
  right: auto;
  z-index: 1;
`

export const RightText = styled(Sidetext)`
  right: 7px !important;
`
