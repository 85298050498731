import store from '../../redux'
import { setOuterLoading } from '../../redux/features/loaders/loadersSlice'
import { hostFic, hostSecure } from '../environment'
import { base64JsonParse, isNumeric } from './stringUtils'

export const reloadPage = (to?: string): void => {
  store.dispatch(setOuterLoading({ isLoading: true }))
  to ? (window.location.href = to) : window.location.reload()
}

export const redirectToMainSite = (): void => reloadPage(hostFic)

export const redirectToLoginPage = (redirect?: string): void =>
  reloadPage(`${hostSecure}/login${redirect ? `?redirect=${redirect}` : ''}`)

export const getUrlParam = (name: string): string | null =>
  new URLSearchParams(window.location.search).get(name)

export const isInternal = (url: string): boolean => {
  try {
    return (
      new URL(url, window.location.origin).hostname === window.location.hostname
    )
  } catch {
    return false
  }
}

export const getUrlSub = (pathname: string, index: number): string => {
  // Remove starting and trailing slashes
  if (pathname.startsWith('/')) pathname = pathname.slice(1)
  if (pathname.endsWith('/')) pathname = pathname.slice(0, -1)

  return pathname.split('/')[index] || ''
}

const parseParam = (p: string): unknown => {
  if (p === 'null') return null
  if (p === 'undefined') return undefined
  if (p === '' || p === undefined) return ''
  if (isNumeric(p)) return parseFloat(p)
  return p
}

// URLSearchParams doesn't work on IE
export const getUrlParamsSafe = <T extends Record<string, unknown>>(
  query?: string
): T => {
  query = query ?? window.location.search
  const qs = query.startsWith('?') ? query.substring(1) : query
  return qs.split('&').reduce((params, param) => {
    const [name, value] = param.split('=')
    if (name) {
      const key = decodeURIComponent(name) as keyof T
      params[key] = parseParam(decodeURIComponent(value || '')) as T[keyof T]
    }
    return params
  }, {} as T)
}

export const isUrl = (text = ''): boolean => {
  try {
    new URL(text)
  } catch {
    return false
  }
  return true
}

type urlParts = string | undefined | null
export const urlConcat = (parts: urlParts[]): string =>
  parts.filter(Boolean).join('/')

export const getUrlPayload = <T extends Record<string, unknown>>():
  | T
  | undefined => {
  if (!window.location.search) return undefined

  const { payload } = getUrlParamsSafe<{ payload: string }>(
    window.location.search
  )
  if (!payload) return undefined

  const parsedJson = base64JsonParse<T>(payload)
  return parsedJson && typeof parsedJson === 'object' ? parsedJson : undefined
}

export const clearScheme = (url: string | null | undefined): string =>
  url ? url.replace('https://', '').replace('http://', '') : ''

export const clearPath = (path: string): string =>
  path.startsWith('/') ? path.slice(1) : path

export const absolutePath = (path: string): string =>
  path.startsWith('/') ? path : `/${path}`

export const goToDic = (): void => {
  window.open('https://secure.dipendentincloud.it/', '_blank')
}

export const goToCouponTSMarket = (): void => {
  window.open('https://incloud.teamsystem.com/richiesta-coupon-fic', '_blank')
}
