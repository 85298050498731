import VisibilitiesStore from './visibilitiesStoreTypes'

const visibilitiesInitialState: VisibilitiesStore = {
  subHeader: true,
  customerly: true,
  help: true,
  keepOpenFattureDropdown: false,
  keepOpenCompanySwitcherDropdown: false,
  drawerVisible: false,
  toggleScrollTop: true,
}

export default visibilitiesInitialState
