import ModalsStore from './modalsStoreTypes'

const modalsInitialState: ModalsStore = {
  showCervedPrivacyModal: false,
  showClientsInvitationModal: false,
  showCompanyChanged: false,
  showCompanyPicker: false,
  showDocumentTransform: false,
  showEditAliquotaModal: false,
  showEditEmail: false,
  showForfettarioLimitWarningModal: false,
  showForfettarioPrevisionModal: false,
  showInviteClient: false,
  showNewPaymentAccountModal: false,
  showRegenerateRecoveryCodeModal: false,
  showServicePresentationModal: false,
  showSudoModeModal: false,
  showSurveyModal: false,
  showEditTranchesModal: false,
}

export default modalsInitialState
