import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { GlobalStore } from '../../../app/rootReducer'
import PreferencesStore, {
  DismissConf,
  DismissibleElement,
} from '../preferencesStoreTypes'

const hideDismissibleElement = createAsyncThunk<
  { key: DismissibleElement; value: DismissConf },
  DismissibleElement,
  { rejectValue: false; state: GlobalStore }
>(
  'preferences/hideDismissibleElement',
  async (key, { getState, rejectWithValue }) => {
    const { preferences } = getState()

    const newValue: DismissConf = {
      showAt: preferences[key].showAt,
      dismissCount: 6, // The tour can be dismissed only 5 times, 6 is more than enough to hide the tour forever
    }

    try {
      await Api.post({
        request: 'user_preferences/set',
        postData: { field: key, value: newValue },
      })
      return { key, value: newValue }
    } catch (error) {
      return rejectWithValue(false)
    }
  }
)

export const addHideDismissibleElement = (
  builder: ActionReducerMapBuilder<PreferencesStore>
): void => {
  builder.addCase(hideDismissibleElement.fulfilled, (state, action) => {
    const { key, value } = action.payload
    state[key] = value
  })
}

export default hideDismissibleElement
