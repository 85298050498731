import swapStorageSlice from './swapStorageSlice'
export type { default as SwapStorageStore } from './swapStorageTypes'
export * from './swapStorageTypes'
export { default as disconnectAccountantCompany } from './thunks/disconnectAccountantCompany'
export * from './swapStorageSelectors'

export const {
  clearCouponMassiveAssignment,
  clearMigrationsClientsData,
  setCouponMassiveAssignment,
  setDisconnectAccountantCompany,
  setDisconnectAccountantCompanyStatus,
  setDocumentToTransform,
  setEditTranchesModalData,
  setMigrationsClientsData,
  setTSPayAutoActivateService,
  setTSPayPOSPayments,
} = swapStorageSlice.actions

export const swapStorage = swapStorageSlice.reducer
