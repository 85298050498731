import { validateTaxCode } from '../../../common/utils/validators'
import FICUtils from '../../common/general/js/FICUtils'
import FICValidator from '../../common/general/js/FICValidator'

const FICValidation = {}
export default FICValidation

FICValidation.itTaxCode = (value, allowVatNumbers = true) => {
  const taxCodeCheck = validateTaxCode(value, {
    allowEmpty: true,
  })
  return (
    taxCodeCheck.valid ||
    (allowVatNumbers && FICUtils.controllaPIVA(value) === '')
  )
}

FICValidation.itVatNumber = value => {
  return FICUtils.controllaPIVA(value) === ''
}

FICValidation.isEmail = value => {
  return FICValidator.email(value, true).valid
}

FICValidation.isPecEmail = value => {
  return FICValidator.pecDomain(value, true).valid
}
