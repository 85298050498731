class FICColors {}

FICColors.white = '#FFFFFF'
FICColors.blue = '#0D97D5'
FICColors.lightBlue = '#E0EAF9'
FICColors.deepBlue = '#007CE4'
FICColors.green = '#13CE64'
FICColors.darkGreen = '#00875A'
FICColors.black = '#333333'
FICColors.gray = '#EBEDEE'
FICColors.darkGray = '#7B8B93'
FICColors.borderColor = '#AAB4B9'
FICColors.inputDisabled = '#EBEDEE'
FICColors.inputDisabledText = '#BDC5C8'
FICColors.placeholder = '#94A1A7'
FICColors.boxBackgroundColor = '#F8F9F9'

// black
FICColors.black100 = '#333333'

// blue
FICColors.blue50 = '#E4F3FA'
FICColors.blue100 = '#C6E7F5'
FICColors.blue200 = '#A4D8EF'
FICColors.blue300 = '#7DC8E8'
FICColors.blue400 = '#4DB4E0'
FICColors.blue500 = '#0D97D5'
FICColors.blue600 = '#0B8AC0'
FICColors.blue700 = '#0A79A8'
FICColors.blue800 = '#08648B'
FICColors.blue900 = '#064763'

// grey
FICColors.grey50 = '#FAFAFA'
FICColors.grey100 = '#EBEDEE'
FICColors.grey300 = '#A8B3B8'
FICColors.grey500 = '#6F7E86'
FICColors.grey600 = '#65747B'
FICColors.grey700 = '#5A676D'
FICColors.grey900 = '#22282B'

// red
FICColors.red = '#FF4949'
FICColors.red50 = '#FBE7E2'
FICColors.red500 = '#DE350B'
FICColors.red600 = '#DA300A'

// yellow / orange
FICColors.yellow300 = '#FFB862'

FICColors.form = {
  field: {
    label: '#333333',
    validationMessage: '#DE350B',
    description: '#5A676D',
  },
}

FICColors.button = {
  primary: {
    green: {
      normal: '#00875A',
      hover: '#007448',
      active: '#00572E',
      disabled: '#B3DBCE',
      text: FICColors.white,
      textHover: FICColors.white,
    },
    white: {
      normal: '#FFFFFF',
      hover: '#FFFFFF',
      active: '#C4CBCF',
      disabled: '#6F7E86',
      text: '#333333',
      textHover: '#333333',
    },
    blue: {
      normal: FICColors.blue,
      hover: '#0A79A8',
      active: '#064763',
      disabled: '#C6E7F5',
      text: FICColors.white,
      textHover: FICColors.white,
    },
    red: {
      normal: '#DE350B',
      hover: '#D52808',
      active: '#C81603',
      disabled: '#F5C2B6',
      text: FICColors.white,
      textHover: FICColors.white,
    },
    yellow: {
      normal: '#FF991F',
      hover: '#FF8617',
      active: '#FF6B0A',
      disabled: '#FFE0BC',
      text: FICColors.white,
      textHover: FICColors.white,
    },
    whiteBlue: {
      normal: FICColors.white,
      hover: FICColors.grey100,
      active: '#ebedee',
      disabled: '#F8F9F9',
      text: FICColors.blue,
      textHover: FICColors.black,
    },
  },
  secondary: {
    blue: {
      normal: '#E4F3FA',
      hover: '#C6E7F5',
      active: '#A4D8EF',
      disabled: '#E4F3FA',
      text: '#0D97D5',
      textHover: '#0D97D5',
    },
    red: {
      normal: '#FBE7E2',
      hover: '#F5C2B6',
      active: '#EF9A85',
      disabled: '#FBE7E2',
      text: '#DE350B',
      textHover: '#DE350B',
    },
    green: {
      normal: '#E0F1EB',
      hover: '#B3DBCE',
      active: '#80C3AD',
      disabled: '#E0F1EB',
      text: '#00875A',
      textHover: '#00875A',
    },
    white: {
      normal: '#6F7E86',
      hover: '#EBEDEE',
      active: '#C4CBCF',
      disabled: '#6F7E86',
      text: '#333333',
      textHover: '#333333',
    },
    yellow: {
      normal: '#FFF3E4',
      hover: '#FFE0BC',
      active: '#FFCC8F',
      disabled: '#FFF3E4',
      text: '#FF991F',
      textHover: '#FF991F',
    },
  },
}

FICColors.link = {
  normal: '#0D97D5',
  hover: '#0B8AC0',
  gray: '#384248',
}

FICColors.text = {
  gray: '#384248',
}

export default FICColors
