import { createSlice } from '@reduxjs/toolkit'

import {
  clearCouponMassiveAssignment,
  setCouponMassiveAssignment,
} from './reducers/couponMassiveAssignment'
import {
  setDisconnectAccountantCompany,
  setDisconnectAccountantCompanyStatus,
} from './reducers/disconnectAccountantCompanyReducer'
import { setDocumentToTransform } from './reducers/documentToTransformReducer'
import { setEditTranchesModalData } from './reducers/editTranches'
import {
  clearMigrationsClientsData,
  setMigrationsClientsData,
} from './reducers/migrationsClientsData'
import { setTSPayAutoActivateService } from './reducers/tsPayAutoActivateServiceReducer'
import { setTSPayPOSPayments } from './reducers/tsPayPOSPaymentsReducer'
import initialState from './swapStorageInitialState'
import { addDisconnectAccountantCompany } from './thunks/disconnectAccountantCompany'

const swapStorageSlice = createSlice({
  name: 'swapStorage',
  initialState,
  reducers: {
    clearCouponMassiveAssignment,
    setCouponMassiveAssignment,
    setMigrationsClientsData,
    clearMigrationsClientsData,
    setDisconnectAccountantCompany,
    setDisconnectAccountantCompanyStatus,
    setDocumentToTransform,
    setTSPayAutoActivateService,
    setTSPayPOSPayments,
    setEditTranchesModalData,
  },
  extraReducers: builder => {
    addDisconnectAccountantCompany(builder)
  },
})

export default swapStorageSlice
