import { LicensePlan } from '../../../common/license'
import { GaTagsStore } from './gaTagsStoreTypes'

const gaTagsInitialState: GaTagsStore = {
  userId: undefined,
  visitorType: 'utente',
  visitorLifetimeValue: 0,
  userPlan: LicensePlan.TRIAL,
  regimeMin: 'no_forf',
  userType: 'OM',
}

export default gaTagsInitialState
