import React from 'react'

import {
  InformativaDatasetCerved,
  InformativaNoteLegaliCerved,
  InformativaPrivacyCerved,
} from './config'
import { WrapperSecondText } from './styled'

export const CervedPrivacyContent = (): JSX.Element => {
  return (
    <>
      <>
        Con riferimento alle aziende, i dati mostrati sono forniti da Cerved
        Group Spa, società di informazione commerciale autorizzata. Consulta{' '}
        <InformativaPrivacyCerved />
      </>
      <WrapperSecondText>
        Con riferimento alle Pubbliche Amministrazioni, i dati sono ricavati
        dall’Indice dei domicili digitali delle Pubbliche Aministrazioni (IPA) e
        dei gestori di pubblici servizi realizzato da AgiD. Consulta{' '}
        <InformativaNoteLegaliCerved /> e <InformativaDatasetCerved />.
      </WrapperSecondText>
    </>
  )
}
