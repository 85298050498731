import { GlobalStore } from '../../app/rootReducer'
import LoadersStore from './loadersStoreTypes'

export const getLoaders = (state: GlobalStore): LoadersStore => state.loaders

export const getOuterLoader = (state: GlobalStore): boolean =>
  state.loaders.outer

export const getInnerLoader = (state: GlobalStore): boolean =>
  state.loaders.inner
