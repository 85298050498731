import styled from 'styled-components/macro'

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const Title = styled.div`
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
`

export const WrapperSecondText = styled.div`
  margin-top: 24px;
`

export const StyledLink = styled.a`
  outline: none !important;
  color: ${({ theme }) => theme.palette.blue[500]};
  cursor: pointer;
`
