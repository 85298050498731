import { useCallback, useEffect, useRef } from 'react'
import { useEvent } from 'react-use'

const useFocusSharedTabs = (callback: () => void, interval = 0): void => {
  const timerId = useRef<number>(0)

  const handleFocus = useCallback((): void => {
    callback()
    if (interval) timerId.current = window.setInterval(callback, interval)
  }, [callback, interval])

  const handleBlur = useCallback((): void => {
    clearInterval(timerId.current)
  }, [])

  useEffect(() => {
    if (document.hasFocus()) {
      callback()
      if (interval) timerId.current = window.setInterval(callback, interval)
    }

    return (): void => {
      clearInterval(timerId.current)
    }
  }, [callback, interval, handleBlur, handleFocus])

  useEvent('focus', handleFocus)
  useEvent('blur', handleBlur)

  useEffect(() => {
    !document.hasFocus() && callback()
  }, [callback])
}

export default useFocusSharedTabs
