import FICColors from './FICColors'

class FICStyles {}

FICStyles.h1 = {
  color: FICColors.black,
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: '72px',
}

FICStyles.h2 = {
  color: FICColors.black,
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '54px',
}

FICStyles.h3 = {
  color: FICColors.black,
  fontSize: '30px',
  fontWeight: 400,
  lineHeight: '45px',
}

FICStyles.h4 = {
  color: FICColors.black,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '36px',
}

FICStyles.h5 = {
  color: FICColors.black,
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
}

FICStyles.h6 = {
  color: FICColors.black,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
}

FICStyles.headline = {
  color: FICColors.black,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}

FICStyles.body = {
  color: FICColors.black,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
}

FICStyles.border = '1px solid ' + FICColors.borderColor

FICStyles.boxContainer = {
  padding: 16,
  boxSizing: 'border-box',
  backgroundColor: FICColors.boxBackgroundColor,
  border: FICStyles.border,
  borderRadius: 4,
  color: '#5C6E77',
}
const formLabelStyle = {
  color: FICColors.black,
  fontWeight: 400,
}

FICStyles.formLabel = {
  default: {
    medium: {
      fontSize: '14px',
      lineHeight: '20px',
      ...formLabelStyle,
    },
    large: {
      fontSize: '16px',
      lineHeight: '24px',
      ...formLabelStyle,
    },
  },
  inline: {
    medium: {
      fontSize: '14px',
      lineHeight: '30px',
      ...formLabelStyle,
    },
    large: {
      fontSize: '16px',
      lineHeight: '30px',
      ...formLabelStyle,
    },
  },
}

FICStyles.elevation3 = {
  boxShadow:
    '0px 3px 6px rgba(51, 51, 51, 0.1), 0px 10px 20px rgba(51, 51, 51, 0.15)',
}

export default FICStyles
