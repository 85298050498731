import { GlobalStore } from '../../app/rootReducer'
import TSPayStore from './tsPayTypes'

export const selectTSPay = (state: GlobalStore): TSPayStore => state.tsPay

export const selectTSPayActive = (state: GlobalStore): boolean =>
  state.tsPay.active

export const selectIncomingPaymentsActive = (state: GlobalStore): boolean =>
  state.tsPay.incomingPayments

export const selectOutgoingPaymentsActive = (state: GlobalStore): boolean =>
  state.tsPay.outgoingPayments

export const selectBankReconciliationActive = (state: GlobalStore): boolean =>
  state.tsPay.bankReconciliation

export const selectDigitalPOSActive = (state: GlobalStore): boolean =>
  state.tsPay.digitalPos
