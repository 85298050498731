import { BECallStatus } from '../../../common/types'
import { MigrationsClientsData } from '.'
import SwapStorageStore, { CouponMassiveAssignment } from './swapStorageTypes'

export const couponMassiveAssignmentInitialState: CouponMassiveAssignment = {
  clients: [],
  counters: {},
}

export const migrationsClientsDataInitialState: MigrationsClientsData = {
  clients: [],
  counters: {},
  options: [],
}

const swapStorageInitialState: SwapStorageStore = {
  documentToTransform: undefined,
  tsPayPOSPayments: undefined,
  tsPayAutoActivateService: undefined,
  disconnectAccountantCompany: {
    fetchStatus: BECallStatus.IDLE,
  },
  couponMassiveAssignment: couponMassiveAssignmentInitialState,
  migrationsClientsData: migrationsClientsDataInitialState,
}

export default swapStorageInitialState
