import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BECallStatus } from '../../../common/types'
import initialState from './questionInitialState'
import { Question } from './questionStoreTypes'

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    showQuestion: (state, action: PayloadAction<Question>) => {
      state.question = { ...action.payload }
    },
    hideQuestion: state => {
      state.question = null
    },
    setQuestionStatus: (state, action: PayloadAction<BECallStatus>) => {
      state.status = action.payload
    },
  },
})

export default questionSlice
