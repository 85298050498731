import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import validate from 'validate.js'

import { Label } from '../../forms-v2/FICForm/FICFormField'
import FICColors from '../../styles/FICColors'

const ValidationMessage = styled.div`
  display: block;
  color: ${FICColors.form.field.validationMessage};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 6px;
`

const renderValidationMessage = (validations, validationFields) => {
  if (!validations) {
    return null
  }
  return map(validationFields, field => {
    return (
      <ValidationMessage key={'message_' + field}>
        {validations[field]}
      </ValidationMessage>
    )
  })
}

const FICFormListLabel = ({ schema }) => {
  if (!schema || !schema.label) {
    return null
  }
  return (
    <Label key='label'>
      {schema.label === '' ? <span>&nbsp;</span> : schema.label}
    </Label>
  )
}

/** ---- Default layout elements ---- **/

/** Column - Stampa elementi in colonna **/

const FICFormColumnItemImpl = props => {
  return (
    <div className={props.className}>
      {props.children}
      {renderValidationMessage(props.validation, props.validationFields)}
    </div>
  )
}

FICFormColumnItemImpl.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  validation: PropTypes.object,
  validationFields: PropTypes.array,
}

const FICFormColumn = styled.div`
  display: block;
`

const FICFormColumnItem = styled(FICFormColumnItemImpl)`
  display: ${props => props.style.display || 'block'};
  & + & {
    margin-top: 16px;
  }
`

/** Row - Stampa elementi in riga **/

const FICFormRow = styled.div`
  display: flex;
  flex-direction: row;
`

const FICFormRowItem = styled.div`
  flex: ${props => props.flex || 1};
  display: ${props => props.style.display || 'block'};
  & + & {
    margin-left: 16px;
  }
`

/** Block - [MOLTO CUSTOM] Serve per appiccicare input in riga **/

const FICFormBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const FICFormBlockItem = styled.div`
  flex: ${props => props.flex || 1};
  &:first-child {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  & + &:not(:last-child) {
    input {
      margin-left: 0;
      border-radius: 0;
      border-left: none;
    }
  }
  &:last-child {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
`

/** ---- Default config ---- **/

export const defaultConfig = {
  components: {
    column: {
      role: 'list',
      component: FICFormColumn,
      itemComponent: FICFormColumnItem,
      labelComponent: FICFormListLabel,
    },
    row: {
      role: 'list',
      component: FICFormRow,
      itemComponent: FICFormRowItem,
      labelComponent: FICFormListLabel,
    },
    block: {
      role: 'list',
      component: FICFormBlock,
      itemComponent: FICFormBlockItem,
      labelComponent: FICFormListLabel,
    },
  },
  customValidators: {},
}

validate.validators.presence.message = '^Il campo è obbligatorio'
