import camelcaseKeys from 'camelcase-keys'
import { useCallback, useEffect, useState } from 'react'
import { browserName, osName } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import { setChat } from '../../redux/features/configurations/configurationsSlice'
import { setOuterLoading } from '../../redux/features/loaders/loadersSlice'
import { readFromFICConfig } from '../../redux/features/localStorage/localStorageUtils'
import Api from '../network/Api'
import { getDeviceId, setDeviceId } from '../utils/appUtils'
import useAuth from './useAuth'

type APIResponse = {
  data: {
    deviceId: string
  }
}

const useInitApp = (): boolean => {
  const { getAppConfig, refreshAuth } = useAuth()
  const token = readFromFICConfig('accessToken')
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const afterDeviceIdLoad = useCallback(() => {
    if (token) {
      refreshAuth()
        .then(() => {
          dispatch(setOuterLoading({ isLoading: false }))
          setIsReady(true)
        })
        .catch(e => console.log(e))
    } else {
      getAppConfig()
        .then(response => {
          dispatch(
            setChat(camelcaseKeys(response.data.data.chat, { deep: true }))
          )
          dispatch(setOuterLoading({ isLoading: false }))
          setIsReady(true)
        })
        .catch(e => console.log(e))
    }
  }, [dispatch, getAppConfig, refreshAuth, token])

  useEffect(() => {
    const userDeviceId = getDeviceId()
    Api.post<APIResponse>({
      request: 'devices/register',
      postData: { os: osName, browser: browserName, service: 'fic' },
      noAlerts: true,
    })
      .then(response => response.data.data)
      .then(({ deviceId }) => {
        if (deviceId && deviceId !== userDeviceId) {
          setDeviceId(deviceId)
        }
        afterDeviceIdLoad()
      })
      .catch(e => console.log(e))
  }, [afterDeviceIdLoad, dispatch])

  return isReady
}

export default useInitApp
