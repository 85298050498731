import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { CompanyDetailsStore } from '../companyDetailsStoreTypes'

type SetUsageLimitPayload = {
  key: keyof CompanyDetailsStore['limits']
  value: number
}

export const setUsageLimit = (
  state: Draft<CompanyDetailsStore>,
  action: PayloadAction<SetUsageLimitPayload>
): void => {
  const { key, value } = action.payload
  state.limits[key].usage = value
}

export const incrementUsageLimit = (
  state: Draft<CompanyDetailsStore>,
  action: PayloadAction<SetUsageLimitPayload>
): void => {
  const { key, value } = action.payload
  state.limits[key].usage += value
}

export const decrementUsageLimit = (
  state: Draft<CompanyDetailsStore>,
  action: PayloadAction<SetUsageLimitPayload>
): void => {
  const { key, value } = action.payload
  state.limits[key].usage -= value
}
