import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { BECallStatus } from '../../../../common/types'
import SwapStorageStore, { DisconnectCompany } from '../swapStorageTypes'

export const setDisconnectAccountantCompany = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<DisconnectCompany | undefined>
): void => {
  state.disconnectAccountantCompany.company = action.payload
}

export const setDisconnectAccountantCompanyStatus = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<BECallStatus>
): void => {
  state.disconnectAccountantCompany.fetchStatus = action.payload
}
