import LoadersStore from './helpOverlayStoreTypes'

const loadersInitialState: LoadersStore = {
  position: {
    bottom: 0,
    side: 0,
  },
  visibility: false,
}

export default loadersInitialState
