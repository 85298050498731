import { LocationListener } from 'history'
import { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router'

const useHistoryListener = (callback: LocationListener): void => {
  const location = useLocation()
  const action = useNavigationType()

  useEffect(() => {
    callback(location, action)
  }, [action, callback, location])
}

export default useHistoryListener
