import { createSelector } from '@reduxjs/toolkit'

import { GlobalStore } from '../../../app/rootReducer'

export const getConnections = (state: GlobalStore) =>
  state.auth.connections.list

export const getConnectionsArray = createSelector(
  [getConnections],
  connections => Object.values(connections.byId)
)

export const getConnectionId = (state: GlobalStore) =>
  state.auth.connections.current

export const getCurrentConnection = createSelector(
  [getConnections, getConnectionId],
  (connections, connectionId) => connections.byId[connectionId]
)

export const getIsLogged = (state: GlobalStore) => !!getConnectionId(state)
