import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GlobalParamsPayload, setFromApi } from '../../app/actions/setFromApi'
import initialState from './preferencesInitialState'
import { setPreference } from './reducers/preferenceReducer'
import { addHideDismissibleElement } from './thunks/hideDismissibleElement'
import { addRemindLaterDismissibleElement } from './thunks/remindLaterDismissibleElement'
import { addSetAsyncPreferenceCases } from './thunks/setAsyncPreference'

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setPreference,
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return {
          ...state,
          ...action.payload.preferences,
        }
      }
    )
    addRemindLaterDismissibleElement(builder)
    addHideDismissibleElement(builder)
    addSetAsyncPreferenceCases(builder)
  },
})

export default preferencesSlice
