import { configureStore } from '@reduxjs/toolkit'

import { isProduction } from '../common/environment'
import createRootReducer, { GlobalStore } from './app/rootReducer'

const store = configureStore<GlobalStore>({
  reducer: createRootReducer(),
  devTools: !isProduction,
})

export default store
