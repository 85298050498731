export enum Regime {
  ORDINARIO = 'ordinario',
  FORFETTARIO_15 = 'forfettario_15',
  FORFETTARIO_5 = 'forfettario_5',
  MINIMI = 'minimi_5',
  NIP = 'nip_10',
}

export enum LicensePayment {
  TRIAL = 'trial',
  REGULAR = 'regular',
  FATTURASMART = 'fatturasmart',
  FORFETTARI = 'forfettari',
  COUPON = 'coupon',
  OLD_COUPON = 'old_coupon',
}

export enum LicensePaymentBy {
  CURRENT_ACCOUNTANT = 'current_accountant',
  COMPANY = 'company',
  TRIAL = 'trial',
}

export enum LicenseStatus {
  ACTIVE = 'active',
  EXPIRING = 'expiring', // Expiring in 30 days
  EXPIRED = 'expired',
}
