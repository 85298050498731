import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { couponMassiveAssignmentInitialState } from '../swapStorageInitialState'
import SwapStorageStore, { CouponMassiveAssignment } from '../swapStorageTypes'

export const setCouponMassiveAssignment = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<CouponMassiveAssignment>
): void => {
  state.couponMassiveAssignment = action.payload
}

export const clearCouponMassiveAssignment = (
  state: Draft<SwapStorageStore>
): void => {
  state.couponMassiveAssignment = couponMassiveAssignmentInitialState
}
