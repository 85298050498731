import '../css/FICModal.css'

import { ThemeProvider } from '@fattureincloud/fic-design-system'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { browserVersion, isIE } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'

import FIC from '../../../common/general/js/FIC'
import FICUtils from '../../../common/general/js/FICUtils'
import FICTextButton from './FICTextButton'

export var FICModalBody = Modal.Body
export var FICModalRawHeader = Modal.Header
export var FICModalRawTitle = Modal.Title
const FICModalRawFooter = Modal.Footer

export default class FICModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _visible: 'pre-show',
    }
    this._mounted = true
    this._container = props.container
  }

  componentDidCatch(error, info) {
    if (isIE && browserVersion < '11') {
      alert(
        'Si è verificato un errore. Aggiorna il browser alla versione più recente.'
      )
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ _visible: 'visible' }, () => {
        this.focus()
      })
    }, 0)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  hideInBG = () => {
    this.setState({ _visible: 'hidden' })
  }
  showFromBG = () => {
    if (this.state._visible === 'hidden') {
      this.setState({ _visible: 'visible' }, () => {
        this.focus()
        if (this.props.onShowFromBG) {
          this.props.onShowFromBG()
        }
      })
    }
  }
  hide = () => {
    // Sarebbe close
    const id = this.props.id
    this.setState({ _visible: 'closed' }, () => {
      if (this._container) {
        this._container.remove(id)
      }
    })
    this.props.onHide && this.props.onHide()
  }
  requestClose = () => {
    if (this.props.requestClose) {
      this.props.requestClose().then(res => res && this.hide())
    } else {
      this.hide()
    }
  }
  focus = () => {
    setTimeout(() => {
      $('.fic-modal-' + this.props.id).focus()
    }, 10) // Delay necessario
  }
  renderChildren = props => {
    if (!this.props.children) return null
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        hide: this.hide,
      })
    })
  }

  render() {
    const style = { content: {} }
    if (this.props.width) {
      style.content.width = this.props.width
    }
    let className = 'fic-modal fic-modal-' + this.props.id
    if (this.props.scrollable) {
      className += ' scrollable'
    }
    return (
      <ReactModal
        id={this.props.id}
        className={className}
        overlayClassName={'fic-modal-overlay fic-modal-' + this.state._visible}
        isOpen
        contentLabel='Finestra di dialogo'
        style={style}
        onRequestClose={this.props.allowClose ? this.requestClose : undefined}
        tabindex='-1'
        ariaHideApp={false}
      >
        {this.renderChildren() || (
          <div>
            <FICModalHeader html={this.props.htmlTitle} />
            <FICModalBody>{this.props.htmlBody}</FICModalBody>
            <FICModalFooter
              modal={this}
              html={this.props.htmlFooter}
              buttons={this.props.footerButtons}
            />
          </div>
        )}
      </ReactModal>
    )
  }
}

FICModal.propTypes = {
  allowClose: PropTypes.bool,
}

FICModal.defaultProps = {
  allowClose: true,
}

class FICModalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propsStack: [],
    }
    this.modalRefs = {}
  }

  add = properties => {
    this.setState(prevState => {
      const newId =
        new Date().getTime() + '-' + FICUtils.randomBetween(0, 10000)
      const newStack = prevState.propsStack.slice()
      properties.id = newId
      for (const key in this.modalRefs) {
        if (!this.modalRefs.hasOwnProperty(key)) continue
        this.modalRefs[key].hideInBG()
      }
      newStack.push(properties)
      return { propsStack: newStack }
    })
  }
  remove = id => {
    delete this.modalRefs[id]
    setTimeout(() => {
      this.setState(prevState => {
        let newStack = prevState.propsStack.slice()
        newStack = newStack.filter(item => item.id != id)
        return { propsStack: newStack }
      })
    }, 500)
    if (Object.keys(this.modalRefs).length > 0) {
      let lastKey
      // do not change or you'll get fired
      // seriously
      for (lastKey in this.modalRefs) {
        // do nothing
      }
      this.modalRefs[lastKey].showFromBG()
    }
  }

  render() {
    return (
      <ThemeProvider theme={FIC.theme}>
        <div>
          {this.state.propsStack.map(item => {
            return (
              <FICModal
                id={item.id}
                key={item.id}
                container={this}
                ref={modal => {
                  if (modal && modal.state._visible !== 'closed') {
                    this.modalRefs[item.id] = modal
                  }
                }}
                {...item.props}
              >
                {item.content}
              </FICModal>
            )
          })}
        </div>
      </ThemeProvider>
    )
  }
}

FICModal.init = container => {
  FICModal.container = ReactDOM.render(<FICModalContainer />, container)
}

/*

USAGE:

FICModal.show({
  title: "Ciao",
  body: "Miao",
  actions: [
    {text: "Conferma", color: "success", onClick: null},
    {text: "Chiudi", color: "gray", onClick: null},
  ]
})

*/

FICModal.show = options => {
  FICModal.container.add({
    content: null,
    props: {
      width: options.width,
      htmlTitle: options.title,
      htmlBody: options.body,
      htmlFooter: options.footer,
      footerButtons: options.actions,
      allowClose: options.allowClose,
      onHide: options.onHide,
    },
  })
}

FICModal.showMessage = (
  title,
  message,
  smallMessage,
  confirmText = 'Chiudi',
  confirmColor = 'gray'
) => {
  FICModal.show({
    title: title,
    body: (
      <span>
        {message}
        {smallMessage && (
          <Fragment>
            <br />
            <span className='small-gray-message'>{smallMessage}</span>
          </Fragment>
        )}
      </span>
    ),
    actions: [{ text: confirmText, color: confirmColor, onClick: null }],
  })
}

/*

USAGE:

FICModal.showCustom(<Component/>, {propName: "Abc"})

*/

FICModal.showCustom = (content, modalProps = {}) => {
  FICModal.container.add({ content: content, props: modalProps })
}

export function FICModalHeader(props) {
  return (
    <FICModalRawHeader>
      <FICModalRawTitle>{props.html}</FICModalRawTitle>
    </FICModalRawHeader>
  )
}

function itemsWithType(items, type) {
  if (!items) return []
  else return items.map(item => Object.assign(item, { type: type }))
}

export function FICModalFooter(props) {
  let footer = props.html
  const items = [
    ...itemsWithType(props.leftItems, 'left'),
    ...itemsWithType(props.centerItems, 'center'),
    ...itemsWithType(
      [...props.rightItems, ...props.buttons].reverse(),
      'right'
    ),
  ]
  if (items) {
    footer = items.map(
      ({ hide, html, icon, keepOpen, onClick, type, ...item }, idx) => {
        if (hide) {
          return null
        }
        if (html) {
          return (
            <div
              className={'footer-item ' + type}
              key={idx}
              style={{ display: 'inline-block', height: '31px' }}
            >
              {html}
            </div>
          )
        }
        return (
          <FICTextButton
            className={'footer-item ' + type}
            key={idx}
            iconLeft={icon}
            onClick={() => {
              if (onClick != null) {
                onClick()
              }
              if (props.modal && !keepOpen) {
                props.modal.hide()
              }
            }}
            {...item}
          />
        )
      }
    )
  }
  return <FICModalRawFooter>{footer}</FICModalRawFooter>
}

FICModalFooter.propTypes = {
  leftItems: PropTypes.array,
  centerItems: PropTypes.array,
  rightItems: PropTypes.array,
  buttons: PropTypes.array,
}

FICModalFooter.defaultProps = {
  leftItems: [],
  centerItems: [],
  rightItems: [],
  buttons: [],
}
