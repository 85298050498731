import { Icon } from '@fattureincloud/fic-design-system'
import React from 'react'
import styled, { css } from 'styled-components/macro'

import FICColors from '../../../common/styles/FICColors'

const InputTextIcon = styled(({ isDisabled, size, ...props }) => (
  <IconContainer {...props}>
    <Icon {...props} />
  </IconContainer>
))`
  color: ${FICColors.text.gray};
  line-height: ${props => (props.size !== 'large' ? 30 : 40)}px;
  ${props =>
    props.isDisabled &&
    css`
      color: ${FICColors.inputDisabledText};
      cursor: not-allowed;
    `}
`
const IconContainer = styled.span`
  position: absolute !important;
  font-size: 12px;
`

export const LefIcon = styled(InputTextIcon)`
  left: 10px !important;
  right: auto;
  z-index: 1;
`

export const RightIcon = styled(({ isValid, ...props }) => (
  <InputTextIcon {...props} />
))`
  right: 10px !important;
  ${props =>
    props.isValid === true &&
    css`
      color: ${FICColors.blue};
    `}
  ${props =>
    props.isValid === false &&
    css`
      color: ${FICColors.red};
    `}
`
