import preferencesSlice from './preferencesSlice'
export type { default as PreferencesStore } from './preferencesStoreTypes'
export { default as setAsyncPreference } from './thunks/setAsyncPreference'
export { default as hideDismissibleElement } from './thunks/hideDismissibleElement'
export { default as remindLaterDismissibleElement } from './thunks/remindLaterDismissibleElement'
export * from './preferencesSelectors'

export const { setPreference } = preferencesSlice.actions

export const preferenceReducer = preferencesSlice.reducer
