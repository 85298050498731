import { BECallStatus } from '../../../common/types'
import { RecoveryCodeStatus } from '../../../common/types/auth'
import MFAStore from './mfaTypes'

const mfaStore: MFAStore = {
  authIntent: undefined,
  factor: undefined,
  intent: undefined,
  recoveryCode: {
    fetchStatus: BECallStatus.IDLE,
    remainingAttempts: 3,
    status: RecoveryCodeStatus.ACTIVE,
  },
}

export default mfaStore
