class MixPanelTracker {
  execute(method, ...params) {
    // debugger
    if (typeof mixpanel === 'undefined') {
      return
    }
    return mixpanel[method](...params)
  }

  optOutTracking = () => {
    this.execute('opt_out_tracking')
  }

  hasOptedOutTracking = () => {
    this.execute('has_opted_out_tracking')
  }

  setConfig = config => {
    this.execute('set_config', config)
  }

  register = data => {
    this.execute('register', data)
  }

  registerOnce = data => {
    this.execute('register_once', data)
  }

  track = (name, data) => {
    this.execute('track', name, data)
  }

  trackWithDelay = (name, data, delay) => {
    if (!delay) {
      delay = 2000
    }

    setTimeout(this.track(name, data), delay)
  }

  trackLinks = (selector, name, data) => {
    this.execute('track_links', selector, name, data)
  }
}

export default MixPanelTracker
