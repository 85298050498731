import ConfigurationsStore from './configurationsStoreTypes'

const configurationsInitialState: ConfigurationsStore = {
  chat: {
    globalVisible: false,
    helpVisible: false,
    instantiate: false,
    singleConversation: false,
    helpOverlayPositon: {
      bottom: 0,
      side: 0,
    },
  },
  currencies: [],
  countries: [],
}

export default configurationsInitialState
