class FICNumbers {}

FICNumbers.parse = (string, strict = false) => {
  string = FICNumbers.commasToDots(string)
  if (!strict) {
    string = string.replace(/[^\d.-]/g, '')
  } else if (/[^\d.-]/g.test(string)) {
    return NaN
  }
  const n = parseFloat(string)
  return !strict && isNaN(n) ? 0 : n
}

FICNumbers.randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

FICNumbers.qtyPrecision = 6

FICNumbers.roundToTwo = num => {
  return FICNumbers.roundToDigit(num, 2)
}
/*
- Vecchia function di arrotondamento
FICNumbers.roundToDigit = (num, digits) => {
  if (num < 1.0e-6 && num > -1.0e-6) return 0
  return (
    +(Math.round(num * FICNumbers.nSign(num) + 'e+' + digits) + 'e-' + digits) *
    FICNumbers.nSign(num)
  )
}
*/

FICNumbers.roundToDigit = (num, digits) => {
  // if (digits < 0 || digits > 8) throw new Error('Invalid decimals digits.')

  var epsilon = 0.000000005 // 0.5*10^-8
  if (num < epsilon && num > -epsilon) return 0

  var p = Math.pow(10, digits)
  var pn = num * p
  var roundedPn = Math.round(pn)
  var precision = epsilon * p

  if (Math.abs(roundedPn - pn) + precision > 0.5)
    pn += precision * FICNumbers.nSign(num)

  return Math.round(pn) / p
}

FICNumbers.nSign = num => {
  if (num > 0) return 1
  if (num < 0) return -1
  return 0
}

FICNumbers.roundSignificative = (n, digits = FICNumbers.qtyPrecision) => {
  if (digits === undefined) {
    digits = 0
  }
  let point = 0
  const sign = FICNumbers.nSign(n)
  n *= sign
  if (n >= 1) {
    for (let i = 1; i < 15; i++) {
      if (n / Math.pow(10, i) < 1) {
        point = -i + digits
        break
      }
    }
  } else {
    for (let i = 1; i < 15; i++) {
      if (n * Math.pow(10, i) > 1) {
        point = i + digits - 1
        break
      }
    }
  }
  if (point < 0) {
    return (
      sign *
      FICNumbers.roundToDigit(n / Math.pow(10, -point), 0) *
      Math.pow(10, -point)
    )
  }
  return sign * FICNumbers.roundToDigit(n, point)
}

FICNumbers.dotsToCommas = txt => {
  if (txt === undefined) return ''
  return String(txt).replace('.', ',')
}

FICNumbers.commasToDots = txt => {
  if (txt === undefined) return ''
  return String(txt).replace(',', '.')
}

export default FICNumbers
