import LocalStorageStore, {
  localStorageKeys,
  localStorageValues,
} from './localStorageStoreTypes'

export const LOCALSTORAGE_FIC_KEY = 'ficConfig'

const getFICConfig = (): LocalStorageStore =>
  JSON.parse(
    localStorage.getItem(LOCALSTORAGE_FIC_KEY) || '{}'
  ) as LocalStorageStore

export const readFromFICConfig = <T extends localStorageKeys>(
  key: T
): LocalStorageStore[T] => getFICConfig()[key]

export const updateLocalStorageObject = (
  updateObject: Partial<LocalStorageStore>
): void => {
  const newConfig = {
    ...getFICConfig(),
    ...updateObject,
  }
  localStorage.setItem(LOCALSTORAGE_FIC_KEY, JSON.stringify(newConfig))
}

export const resetFromLocalStorageObject = (
  key: localStorageKeys,
  value: localStorageValues
): void => {
  const actualConfig = { ...getFICConfig(), [key]: value }
  localStorage.setItem(LOCALSTORAGE_FIC_KEY, JSON.stringify(actualConfig))
}

export const localStorageFindOrCreate = <T extends localStorageKeys>(
  key: T,
  value: LocalStorageStore[T],
  forceOverride = false
): LocalStorageStore[T] => {
  const storageValue: LocalStorageStore[T] = getFICConfig()[key]
  if (!forceOverride && storageValue !== undefined) {
    return storageValue
  } else {
    updateLocalStorageObject({ [key]: value })
    return value
  }
}
