import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GlobalParamsPayload,
  setFromApi,
} from '../../../app/actions/setFromApi'
import initialState from './connectionsInitialState'

interface SetConnectionId {
  connectionId: number
}

interface RemoveConnection {
  connectionId: number
}

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setConnectionId: (state, action: PayloadAction<SetConnectionId>) => {
      state.current = action.payload.connectionId
    },
    removeConnection: (state, action: PayloadAction<RemoveConnection>) => {
      const { connectionId } = action.payload
      delete state.list.byId[connectionId]
      state.list.allIds = state.list.allIds.filter(id => id !== connectionId)
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.auth.connections
      }
    )
  },
})

const connections = connectionsSlice.reducer

export const { removeConnection, setConnectionId } = connectionsSlice.actions
export default connections
