import { Toast } from '@fattureincloud/fic-design-system'
import axios, { AxiosError } from 'axios'

import { QuestionOptionsBackendType } from '../types'
import { AuthIntent } from '../types/auth'

export type FICError = {
  error: {
    code?: string
    message: string
    validation_result?: Record<string, string[]>
  }
}

export type FICAlertError = {
  extra: {
    alert: {
      message: string
    }
  }
}

export type FICQuestionError = FICError & {
  extra: {
    question: {
      message: string
      name: string
      title: string
      options: QuestionOptionsBackendType
    }
  }
}

export type SudoModeError = {
  error: {
    code: 'NEED_SUDO_MODE'
    message: string
  }
  extra: {
    authIntent: AuthIntent
  }
}

export type CustomError<T> = AxiosError<T> & {
  response: {
    data: T
  }
}

export function hasErrorData<T>(e: unknown): e is CustomError<T> {
  if (!axios.isAxiosError(e)) return false

  return e.response !== undefined && 'data' in e.response
}

export function isFICError(e: unknown): e is CustomError<FICError> {
  return hasErrorData<FICError>(e) && 'error' in e.response.data
}

export function isSudoModeError(e: unknown): e is CustomError<SudoModeError> {
  return (
    hasErrorData<SudoModeError>(e) &&
    'error' in e.response.data &&
    'code' in e.response.data.error
  )
}

export function isFICAlertError(e: unknown): e is CustomError<FICAlertError> {
  return (
    hasErrorData<FICAlertError>(e) &&
    'extra' in e.response.data &&
    'alert' in e.response.data.extra
  )
}

export const handleUnknownError = (error: unknown): void => {
  if (isFICError(error)) {
    Toast.error(error.response.data.error.message)
  } else if (isFICAlertError(error)) {
    Toast.error(error.response.data.extra.alert.message)
  } else {
    Toast.error('Si è verificato un errore imprevisto.')
  }
}

export function isQuestionError(
  e: unknown
): e is CustomError<FICQuestionError> {
  return (
    hasErrorData<FICQuestionError>(e) &&
    'extra' in e.response.data &&
    'question' in e.response.data.extra
  )
}
