import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GlobalParamsPayload,
  setFromApi,
} from '../../../app/actions/setFromApi'
import initialState from './currentSessionInitialState'

interface SetSession {
  session: string
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SetSession>) => {
      return action.payload.session
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.auth.currentSession
      }
    )
  },
})

const session = sessionSlice.reducer

export const { setSession } = sessionSlice.actions
export default session
