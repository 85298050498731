import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import FICDateTime from '../../../../common/dateTime'
import { ConsentType } from '../../../../common/types/person'
import { capitalize } from '../../../../common/utils/stringUtils'
import {
  GlobalParamsPayload,
  setFromApi,
} from '../../../app/actions/setFromApi'
import initialState from './personInitialState'
import {
  EmailConfirmationDetailsType,
  EmailConfirmationType,
  PasswordManagementType,
  PersonInfo,
  SurveyType,
} from './personStoreTypes'

interface SetPersonName {
  firstName: string
  lastName: string
}

interface SetPasswordManagement {
  attribute: keyof PasswordManagementType
  value: boolean
}

interface SetSurveyToken {
  token: string | null
}

interface SetSurveyLastAction {
  lastAction: SurveyType['lastAction']
}

interface SetPersonInfo extends Partial<PersonInfo> {
  emailHash?: string
}

interface SetEmailDetailsNeverAskedConfirmation {
  neverAskedConfirmation: EmailConfirmationDetailsType['neverAskedConfirmation']
}

type SetConsents = Partial<Record<ConsentType, boolean>>

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setPersonName: (state, action: PayloadAction<SetPersonName>) => {
      const { firstName, lastName } = action.payload
      state.firstName = capitalize(firstName)
      state.lastName = capitalize(lastName)
    },
    setPasswordManagement: (
      state,
      action: PayloadAction<SetPasswordManagement>
    ) => {
      const { attribute, value } = action.payload
      state.passwordManagement[attribute] = value
    },
    setSurveyToken: (state, action: PayloadAction<SetSurveyToken>) => {
      state.survey.token = action.payload.token
    },
    setSurveyLastAction: (
      state,
      action: PayloadAction<SetSurveyLastAction>
    ) => {
      state.survey.lastAction = action.payload.lastAction
    },
    setEmailConfirmationState: (
      state,
      action: PayloadAction<EmailConfirmationType>
    ) => {
      state.emailConfirmationState = action.payload
    },
    setEmailNeverAskedConfirmationState: (
      state,
      action: PayloadAction<SetEmailDetailsNeverAskedConfirmation>
    ) => {
      const { neverAskedConfirmation } = action.payload
      const details = state.emailConfirmationState.details
      if (details) {
        details.neverAskedConfirmation = neverAskedConfirmation
      }
    },
    setPersonInfo: (state, action: PayloadAction<SetPersonInfo>) => {
      const { birthDate, email, emailHash, firstName, lastName } =
        action.payload

      state.customerlyHash = emailHash ?? state.customerlyHash
      state.birthDate = birthDate !== undefined ? birthDate : state.birthDate
      state.firstName = firstName ? capitalize(firstName) : state.firstName
      state.lastName = lastName ? capitalize(lastName) : state.lastName
      state.email = email ?? state.email
    },
    setConsents: (state, action: PayloadAction<SetConsents>): void => {
      const newValues = action.payload
      const now = FICDateTime.format('now', 'SQL+T')

      state.consents = state.consents.map(c => ({
        ...c,
        ...(c.name in newValues
          ? {
              accepted: newValues[c.name],
              optinDate: newValues[c.name] ? now : null,
              optoutDate: newValues[c.name] ? null : now,
            }
          : {}),
      }))
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return action.payload.auth.person
      }
    )
  },
})

const person = personSlice.reducer

export const {
  setConsents,
  setEmailConfirmationState,
  setEmailNeverAskedConfirmationState,
  setPasswordManagement,
  setPersonInfo,
  setPersonName,
  setSurveyLastAction,
  setSurveyToken,
} = personSlice.actions
export default person
