import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

//import Api from '../network/Api'

const allowedDashUrls = [
  '/forgot-password',
  '/link2save-callbackurl',
  '/mobile-landing',
  '/password-recovery',
  '/accountant_coupons/assign-coupons',
  '/accountant_coupons/coupons-list',
  '/accountant/coupons-list',
  '/accountant/link-client',
  '/einvoices/view-expenses',
  '/einvoices/view-issued',
  '/einvoices/view-pending',
  '/tspay/bank-reconciliation',
  '/tspay/link-accounts',
  '/invoices/compute',
]

const dashUrlsMap: Record<string, string> = {
  '/accountant_coupons-assign-coupons': '/accountant_coupons/assign-coupons',
  '/accountant_coupons-coupons-list': '/accountant_coupons/coupons-list',
  '/accountant-coupons-list': '/accountant/coupons-list',
  '/accountant-link-client': '/accountant/link-client',
  '/einvoices-view-expenses': '/einvoices/view-expenses',
  '/einvoices-view-issued': '/einvoices/view-issued',
  '/einvoices-view-pending': '/einvoices/view-pending',
  '/tspay-bank-reconciliation': '/tspay/bank-reconciliation',
  '/tspay-link-accounts': '/tspay/link-accounts',
  '/invoices-bin': '/bin/invoices',
  '/expenses-bin': '/bin/expenses',
  '/air-expenses-bin': 'bin/air_expenses',
}

const APP_REGEX = /\/apps\/([0-9]+)/

const logOccurence = (_path: string): void => {
  /* Api.post({
    request: 'log_old_link',
    postData: { data: { path } },
  }).catch(e => console.log(e)) */
}

const useOldDashUrl = (): void => {
  const { hash, pathname, search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname.includes('-')) {
      // For some URLs is allowed to have dashes everywhere
      if (allowedDashUrls.some(allowed => pathname.startsWith(allowed))) {
        return
      }

      // Don't know if we need this check
      if (pathname.startsWith('/help')) {
        return
      }

      // New app store url, the app link have the name of the application that can have dashes: skip
      if (APP_REGEX.test(pathname)) {
        return
      }

      // Old app store url
      if (pathname.startsWith('/apps-')) {
        const parts = pathname.split('-')
        // First part is /apps, remove it we don't need it
        parts.shift()
        // Second part is the id of the application
        const id = parts.shift()

        if (id) {
          // This new URL will match the previous if with /apps/
          logOccurence(pathname + search + hash)
          navigate(`/apps/${id}/${parts.join('-')}`, { replace: true })
          return
        }
      }

      // The are some url where a dash is allowed in the second part of the url
      for (const dashUrl in dashUrlsMap) {
        if (pathname.startsWith(dashUrl)) {
          const newUrl = pathname.replace(dashUrl, dashUrlsMap[dashUrl])
          logOccurence(pathname + search + hash)
          navigate(newUrl + search + hash, { replace: true })
          return
        }
      }

      // All other urls must have dashes replaced with slashes
      const newUrl = pathname.replace(/-/g, '/')
      logOccurence(pathname + search + hash)
      navigate(newUrl + search + hash, { replace: true })
    }
  }, [hash, navigate, pathname, search])
}

export default useOldDashUrl
