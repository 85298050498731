import PropTypes from 'prop-types'
import React from 'react'

import FICRadio from './FICRadio'

function FICRadioGroup(props) {
  return (
    <div style={props.style} className={props.className}>
      {props.options.map(option => {
        return (
          <FICRadio
            key={option[props.valueKey]}
            label={option[props.labelKey]}
            data={option}
            onClick={() =>
              props.onChange
                ? handleClick(
                    props.onChange,
                    option[props.valueKey],
                    props.name,
                    option
                  )
                : undefined
            }
            isChecked={props.value === option[props.valueKey]}
            isDisabled={props.isDisabled}
            renderOption={props.renderOption}
            optionsSpacing={props.optionsSpacing}
            inline={props.inline}
          />
        )
      })}
    </div>
  )
}

function handleClick(onChange, value, name, option) {
  onChange(value, name, option)
}

FICRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  renderOption: PropTypes.func,
  optionsSpacing: PropTypes.number,
  style: PropTypes.object,
  inline: PropTypes.bool,
  className: PropTypes.string,
}

FICRadioGroup.defaultProps = {
  name: '',
  options: [],
  value: undefined,
  valueKey: 'value',
  labelKey: 'label',
  onChange: undefined,
  isDisabled: false,
  renderOption: undefined,
  optionsSpacing: 0,
  style: undefined,
  inline: false,
  className: undefined,
}

export default FICRadioGroup
