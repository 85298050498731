import { LicensePlan } from './types'
import {
  getLicenseName,
  isComplete,
  isMaxPremium,
  isMaxPremiumPlus,
  isMaxStandard,
  isMinPremium,
  isMinPremiumPlus,
  isMinStandard,
  isPremium,
  isPremiumPlus,
  isStandard,
  isTrial,
  numToString,
  stringToNum,
} from './utils'

type TLicense = {
  isMaxPremium: (plan: LicensePlan | number) => boolean
  isMaxPremiumPlus: (plan: LicensePlan | number) => boolean
  isMaxStandard: (plan: LicensePlan | number) => boolean
  isMinPremium: (plan: LicensePlan | number) => boolean
  isMinPremiumPlus: (plan: LicensePlan | number) => boolean
  isMinStandard: (plan: LicensePlan | number) => boolean
  isPremium: (plan: LicensePlan | number) => boolean
  isComplete: (plan: LicensePlan | number) => boolean
  isPremiumPlus: (plan: LicensePlan | number) => boolean
  isStandard: (plan: LicensePlan | number) => boolean
  isTrial: (plan: LicensePlan | number) => boolean
  name: (plan: number | LicensePlan) => string
  numToString: (plan: number) => LicensePlan
  stringToNum: (plan: LicensePlan) => number
}

const License: TLicense = {
  isMaxPremium,
  isMaxPremiumPlus,
  isMaxStandard,
  isMinPremium,
  isMinPremiumPlus,
  isMinStandard,
  isPremium,
  isComplete,
  isPremiumPlus,
  isStandard,
  isTrial,
  name: getLicenseName,
  numToString,
  stringToNum,
}

export default License
