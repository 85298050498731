import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

import { FICSpacing } from '../../config/theme/spacing/spacing'

type PaddingParam = keyof FICSpacing | 0

type PaddingParams =
  | [PaddingParam]
  | [PaddingParam, PaddingParam]
  | [PaddingParam, PaddingParam, PaddingParam]
  | [PaddingParam, PaddingParam, PaddingParam, PaddingParam]

export const padding = (
  ...args: PaddingParams
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    padding: ${({ theme }) =>
      args.reduce(
        (prev, curr) => `${prev} ${curr === 0 ? '0px' : theme.spacing[curr]}`,
        ''
      )};
  `

/* TOP */
export const paddingTopXXS = css`
  padding: ${({ theme }) => theme.spacing.XXS};
`

export const paddingTopXS = css`
  padding: ${({ theme }) => theme.spacing.XS};
`

export const paddingTopS = css`
  padding: ${({ theme }) => theme.spacing.S};
`

export const paddingTopM = css`
  padding: ${({ theme }) => theme.spacing.M};
`

export const paddingTopL = css`
  padding: ${({ theme }) => theme.spacing.L};
`

export const paddingTopXL = css`
  padding: ${({ theme }) => theme.spacing.XL};
`

export const paddingTopXXL = css`
  padding: ${({ theme }) => theme.spacing.XXL};
`

/* RIGHT */
export const paddingRightXXS = css`
  padding: ${({ theme }) => theme.spacing.XXS};
`

export const paddingRightXS = css`
  padding: ${({ theme }) => theme.spacing.XS};
`

export const paddingRightS = css`
  padding: ${({ theme }) => theme.spacing.S};
`

export const paddingRightM = css`
  padding: ${({ theme }) => theme.spacing.M};
`

export const paddingRightL = css`
  padding: ${({ theme }) => theme.spacing.L};
`

export const paddingRightXL = css`
  padding: ${({ theme }) => theme.spacing.XL};
`

export const paddingRightXXL = css`
  padding: ${({ theme }) => theme.spacing.XXL};
`

/* LEFT */
export const paddingLeftXXS = css`
  padding: ${({ theme }) => theme.spacing.XXS};
`

export const paddingLeftXS = css`
  padding: ${({ theme }) => theme.spacing.XS};
`

export const paddingLeftS = css`
  padding: ${({ theme }) => theme.spacing.S};
`

export const paddingLeftM = css`
  padding: ${({ theme }) => theme.spacing.M};
`

export const paddingLeftL = css`
  padding: ${({ theme }) => theme.spacing.L};
`

export const paddingLeftXL = css`
  padding: ${({ theme }) => theme.spacing.XL};
`

export const paddingLeftXXL = css`
  padding: ${({ theme }) => theme.spacing.XXL};
`

/* BOTTOM */
export const paddingBottomXXS = css`
  padding: ${({ theme }) => theme.spacing.XXS};
`

export const paddingBottomXS = css`
  padding: ${({ theme }) => theme.spacing.XS};
`

export const paddingBottomS = css`
  padding: ${({ theme }) => theme.spacing.S};
`

export const paddingBottomM = css`
  padding: ${({ theme }) => theme.spacing.M};
`
