import { useSelector } from 'react-redux'

import { getSidebarCounters } from './localStorageSelectors'
import { SidebarCounterKey } from './localStorageStoreTypes'

const useSidebarCounter = (key?: SidebarCounterKey): number => {
  const counters = useSelector(
    getSidebarCounters,
    (prev, curr) => key !== undefined && prev[key] === curr[key]
  )
  return key ? counters[key] : 0
}

export default useSidebarCounter
