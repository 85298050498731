import companyDetailsSlice from './companyDetailsSlice'

export const {
  decrementUsageLimit,
  incrementUsageLimit,
  setAccountantsCount,
  setCompanyCountry,
  setCompanyType,
  setEiSendingCounter,
  setEnasarco,
  setForfettariRevenue,
  setHasBlockedNumerations,
  setHasMed,
  setInvoiceTradingStatus,
  setIsAgyoActive,
  setIsRegimeForfettario,
  setLicenseExpiringDate,
  setNeedTermsConditionsConfirmation,
  setPlanId,
  setUsageLimit,
  setVatNumber,
} = companyDetailsSlice.actions

export const companyDetails = companyDetailsSlice.reducer
