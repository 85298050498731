import get from 'lodash/get'
import has from 'lodash/has'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components/macro'

import FICColors from '../../styles/FICColors'
import FICStyles from '../../styles/FICStyles'

const Container = styled.div`
  display: block;
`

export const Label = styled.div`
  ${props =>
    FICStyles.formLabel[props.inline ? 'inline' : 'default'][
      props.size || 'large'
    ]};
  background-color: transparent;
  display: block;
  ${props =>
    props.inline &&
    css`
      display: inline-block;
      vertical-align: top;
      margin-right: 25px;
    `};
  color: ${FICColors.form.field.label};
  text-align: left;
  margin-bottom: 6px;
  border-radius: 0;
  padding: 0;
`

const Field = styled.div`
  display: block;
  ${props =>
    props.inline &&
    css`
      display: inline-block;
      vertical-align: top;
    `}
`

const ValidationMessage = styled.div`
  display: block;
  color: ${FICColors.form.field.validationMessage};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 4px;
`

export const Description = styled.div`
  display: block;
  color: ${FICColors.form.field.description};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 4px;
`

/* eslint-disable react/prop-types */

const renderLabel = ({ inline, label, labelSize, required }) => {
  if (label === '') label = <span>&nbsp;</span>
  return label ? (
    <Label
      key='label'
      className='label'
      inline={inline}
      size={labelSize || 'large'}
    >
      {label}
      {required && '*'}
    </Label>
  ) : null
}

renderLabel.propTypes = {
  inline: PropTypes.bool,
}

renderLabel.defaultProps = {
  inline: false,
}

const renderField = (
  Component,
  schema,
  data,
  onChange,
  { validationMessage }
) => {
  const value = get(data, schema.name)
  const isValid =
    value === undefined
      ? undefined
      : Array.isArray(validationMessage)
      ? validationMessage.length === 0
      : undefined
  return (
    <Field key='field' inline={schema.inline}>
      <Component
        {...schema.componentProps}
        value={value}
        onChange={
          onChange
            ? (value, data) => {
                onChange(schema.name, value, data)
              }
            : undefined
        }
        isValid={isValid}
      />
    </Field>
  )
}

renderField.propTypes = {
  inline: PropTypes.bool,
  validation: PropTypes.object,
  formOptions: PropTypes.object,
}

renderField.defaultProps = {
  inline: false,
  validation: {},
  formOptions: {},
}

const renderValidationMessage = (schema, data, { validationMessage }) => {
  const value = get(data, schema.name)
  return value !== undefined && validationMessage ? (
    <ValidationMessage key='message'>{validationMessage[0]}.</ValidationMessage>
  ) : null
}

const renderDescription = ({ description }, data) => {
  return description ? (
    <Description key='description'>{description}</Description>
  ) : null
}

/* eslint-enable react/prop-types */

export const FICFormField = ({
  className,
  config,
  data,
  formOptions,
  hideValidation,
  onChange,
  schema,
  style,
  validation,
}) => {
  // Load config
  const fieldConfig = config.components[schema.type]
  if (!fieldConfig) {
    console.error(
      "FICForm: Component config not found for type '" + schema.type + "'."
    )
    return null
  }

  // Calculations
  const Component = fieldConfig.component
  const extra = {}
  if (has(validation, schema.name)) {
    extra.validationMessage = get(validation, schema.name)
  }

  // Render
  return (
    <Container className={className} style={style}>
      {renderLabel(schema)}
      {renderField(Component, schema, data, onChange, extra)}
      {!hideValidation && renderValidationMessage(schema, data, extra)}
      {renderDescription(schema, data)}
    </Container>
  )
}

// label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])

FICFormField.defaultProps = {
  className: undefined,
  style: {},
  config: {},
  schema: {},
  data: {},
  onChange: undefined,
  hideValidation: false,
  validation: {},
  formOptions: {},
}

FICFormField.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  schema: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
  hideValidation: PropTypes.bool,
  formOptions: PropTypes.object,
}
