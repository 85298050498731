import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import initialState from './visibilitiesInitialState'
import VisibilitiesStore from './visibilitiesStoreTypes'

interface SetVisibility {
  element: keyof VisibilitiesStore
  visible: boolean
}

const visibilitiesSlice = createSlice({
  name: 'visibilities',
  initialState,
  reducers: {
    setVisibility: (
      state,
      action: PayloadAction<SetVisibility | SetVisibility[]>
    ) => {
      if (Array.isArray(action.payload)) {
        for (let i = 0; i < action.payload.length; i++) {
          const { element, visible } = action.payload[i]
          state[element] = visible
        }
      } else {
        state[action.payload.element] = action.payload.visible
      }
    },
    toggleVisibility: (
      state,
      action: PayloadAction<keyof VisibilitiesStore>
    ) => {
      const element = action.payload
      state[element] = !state[element]
    },
  },
})

const visibilities = visibilitiesSlice.reducer

export const { setVisibility, toggleVisibility } = visibilitiesSlice.actions
export default visibilities
