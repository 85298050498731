import { useCallback, useState } from 'react'
import { useInterval } from 'react-use'

const useCountdown = (seconds: number, fn?: () => void): number => {
  const [timeLeft, setTimeLeft] = useState(seconds)
  const decrement = useCallback(() => setTimeLeft(timeLeft => timeLeft - 1), [])

  const delay = timeLeft > 0 ? 1000 : null
  useInterval(decrement, delay)

  if (timeLeft === 0 && fn) {
    fn()
  }

  return timeLeft
}

export default useCountdown
