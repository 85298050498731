import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { RecoveryCodeStatus } from '../../../../common/types/auth'
import MFAStore, { RecoveryCode } from '../mfaTypes'

export const setRecoveryCode = (
  state: Draft<MFAStore>,
  action: PayloadAction<RecoveryCode>
): void => {
  state.recoveryCode = action.payload
}

export const setRecoveryCodeAttempt = (
  state: Draft<MFAStore>,
  action: PayloadAction<number>
): void => {
  state.recoveryCode.remainingAttempts = action.payload
}

export const setRecoveryCodeStatus = (
  state: Draft<MFAStore>,
  action: PayloadAction<RecoveryCodeStatus>
): void => {
  state.recoveryCode.status = action.payload
}
