import { InputHelperProps } from '@fattureincloud/fic-design-system'
import {
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'

export const inputHelperError: InputHelperProps = {
  icon: { icon: faExclamationCircle },
  message: '',
  status: 'error',
}

export const inputHelperWarning: InputHelperProps = {
  icon: { icon: faExclamationTriangle },
  message: '',
  status: 'warning',
}

export const inputHelperSuccess: InputHelperProps = {
  icon: { icon: faCheck },
  message: '',
  status: 'success',
}
