import { CircularProgressBarPalette } from '@fattureincloud/fic-design-system'

import { ficPalette } from '../palette'

// This color belongs to the right palette
const circularProgressBarPalette: CircularProgressBarPalette = {
  standard: {
    path: ficPalette.blue[500],
    trail: ficPalette.white[100],
    trailBorder: ficPalette.blue[50],
  },
  warning: {
    path: ficPalette.yellow[800],
    trail: ficPalette.white[100],
    trailBorder: ficPalette.yellow[50],
  },
  error: {
    path: ficPalette.red[800],
    trail: ficPalette.white[100],
    trailBorder: ficPalette.red[50],
  },
}

export default circularProgressBarPalette
