// Regex di controllo sulla conformità della mail rispetto all'RFC (Regex from https://emailregex.com/)
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (e: string): boolean =>
  emailRegex.test(e.toLowerCase())

export const emailHasError = (
  e?: string,
  required = false,
  checkLength = false
): string => {
  if (e === undefined) return required ? 'Campo obbligatorio' : ''

  if (e.length === 0 && required) return 'Campo obbligatorio'
  if (!isValidEmail(e)) return "Inserisci un'e-mail valida"
  if (checkLength && e.length > 64)
    return "L'indirizzo e-mail non può superare i 64 caratteri"

  return ''
}

export const multiEmailHasError = (e: string, required = false): string => {
  let emailArray: string[] = []

  if (e) {
    emailArray.push(e)

    if (e.includes(',')) {
      emailArray = e.split(',')
    } else if (e.includes(';')) {
      emailArray = e.split(';')
    }
  }

  if (!(emailArray.length > 0) && required) {
    return 'Nessun indirizzo e-mail inserito'
  }

  if (e) {
    if (
      !emailArray.every(email => {
        return emailHasError(email.trim(), false, true)
      })
    ) {
      return 'Uno o più indirizzi e-mail inseriti non sono corretti.'
    }
  }
  return ''
}

const pecDomains = [
  'pec.it',
  'legalmail.it',
  'hyperpec.it',
  'postacert.it',
  'registerpec.it',
  'dadapec.com',
  'pec-mail.it',
  'arubapec.it',
  'lamiapec.it',
  'pecimprese.it',
  'pec.libero.it',
  'gigapec.it',
  'pec.net',
  'omceo.it',
  'ingpec.eu',
  'psypec.it',
  'pec-legal.it',
  'consulentidellavoropec.it',
  'mypec.eu',
  'pec.cgn.it',
  'pecditta.com',
  'pecdittaindividuale.it',
  'pecavvocati.it',
  'casellapec.com',
  'pec.cloud',
  'open.legalmail.it',
]

export const pecCheck = (email: string): boolean =>
  email.length > 0 && pecDomains.some(d => email.endsWith(d))

export const emailHasPecDomain = (email?: string, required = false): string => {
  if (emailHasError(email, required) !== '') {
    return emailHasError(email, required)
  }
  if (email && pecCheck(email)) {
    return 'Non usare una PEC/e-mail certificata.'
  }
  return ''
}
