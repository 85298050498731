import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import initialState from './gaTagsInitialState'
import { GaTagsStore } from './gaTagsStoreTypes'

const gaTagsSlice = createSlice({
  name: 'gaTags',
  initialState,
  reducers: {
    setGaTags: (state, action: PayloadAction<Partial<GaTagsStore>>) => ({
      ...state,
      ...action.payload,
    }),
  },
})

const gaTags = gaTagsSlice.reducer

export const { setGaTags } = gaTagsSlice.actions
export default gaTags
