import { Toast } from '@fattureincloud/fic-design-system'
import React from 'react'

import { CervedPrivacyContent } from '../../../../components/layout/components/basePage/components/modals/components/CervedPrivacyModal/CervedPrivacyContent'
import FICModal from '../../components/js/FICModal'
import FICMultilineTextView from '../../components/js/FICMultilineTextView'
import FICUtils from '../../general/js/FICUtils'
import { download } from './download'
import { headerFilename } from './FICNetwork'

class FICRestV2 {}

FICRestV2.execute = ({
  formData,
  formMapping,
  method,
  noAlerts,
  onComplete,
  onError,
  onSuccess,
  postData,
  queryData,
  request,
  skipLogoutOnUnauthorized = false,
  url,
}) => {
  const query = $.param(queryData || {})
  let data
  if (formData) {
    data = new FormData()
    Object.keys(formData).forEach(key => {
      data.append(key, formData[key])
    })
  } else {
    data = JSON.stringify(postData)
  }
  const headers = {
    'Content-Type': !formData ? 'application/json; charset=utf-8' : undefined,
    'Accept-Language': 'it',
  }
  if (reactGlobalParams.access_token) {
    headers['Authorization'] = reactGlobalParams.access_token
  }
  if (reactGlobalParams.adminAccountId) {
    headers['Admin-Account-Id'] = reactGlobalParams.adminAccountId
  } else {
    const deviceId = localStorage.getItem('fic_device_id')
    if (deviceId) {
      headers['X-Device-Id'] = deviceId
    }
  }

  const options = {
    url: url + '/' + request + (query ? '?' + query : ''),
    method: method,
    dataType: 'json',
    headers: headers,
    data: data,
    jsonp: false,
    cache: formData ? false : undefined,
    contentType: formData ? false : undefined,
    processData: formData ? false : undefined,
    type: formData ? 'POST' : undefined,
    success: function (responseData, result, rawData) {
      if (
        FICRestV2.handleQuestion({
          responseData,
          method,
          url,
          request,
          queryData,
          postData,
          onSuccess,
          onError,
          onComplete,
          formMapping,
        })
      ) {
        return
      }

      FICRestV2.handlePopup(responseData)

      const alertShown = noAlerts || FICRestV2.handleAlert(responseData)
      const success = rawData.status >= 200 && rawData.status < 300
      if (success) {
        if (onSuccess) {
          onSuccess(responseData)
        }
      } else if (onError) {
        onError(responseData)
      } else if (!alertShown) {
        if (!FICRestV2.handleValidationError(responseData, formMapping)) {
          if (!FICRestV2.handleError(responseData)) {
            Toast.error('Si è verificato un errore sconosciuto.')
          }
        }
      }
    },
    error: function (rawData, result, statusMessage) {
      if (
        !skipLogoutOnUnauthorized &&
        rawData.status === 401 &&
        ['production', 'development'].includes(process.env.REACT_APP_ENV)
      ) {
        // unauthorized exception logout
        window.logout()
      }
      const responseData = rawData.responseJSON
      if (!rawData.responseJSON && rawData.readyState !== 0) {
        // Comportamento inatteso
      }
      if (
        FICRestV2.handleQuestion({
          responseData,
          method,
          url,
          request,
          queryData,
          postData,
          onSuccess,
          onError,
          onComplete,
          formMapping,
        })
      ) {
        return
      }

      const alertShown =
        noAlerts || FICRestV2.handleAlert(responseData, onError)
      let handleError = true
      if (onError) {
        handleError = onError(responseData, rawData)
      }
      if (handleError && !alertShown) {
        if (!FICRestV2.handleValidationError(responseData, formMapping)) {
          if (!FICRestV2.handleError(responseData)) {
            Toast.error('Si è verificato un errore sconosciuto.')
          }
        }
      }
    },
    complete: function (response, status) {
      const responseData = response.responseJSON
      if (onComplete) {
        onComplete(responseData, status)
      }
    },
  }
  return $.ajax(options)
}

FICRestV2.handleAlert = responseData => {
  if (responseData?.extra) {
    if (responseData.extra.alert) {
      Toast.show(responseData.extra.alert.message, {
        type: responseData.extra.alert.type,
        autoClose: responseData.extra.alert.duration || 5000,
      })

      return true
    }
  }
}

FICRestV2.handleError = responseData => {
  if (responseData?.error?.message) {
    Toast.error(responseData.error.message)
    return true
  }
  return false
}

FICRestV2.handleValidationError = (responseData, formMapping) => {
  if (responseData?.error?.validation_result) {
    const validations = responseData.error.validation_result
    const fields = Object.keys(validations)
    if (fields.length > 0) {
      const field = fields[0]
      const message = validations[field][0]
      Toast.error(message)
      return true
    }
  }
  return false
}

FICRestV2.handleQuestion = ({ responseData, ...params }) => {
  if (!responseData || !responseData.extra || !responseData.extra.question) {
    return false
  }
  const question = responseData.extra.question
  const options = question.options
  const actions = []
  for (const o in options) {
    actions.push({
      text: o,
      color: options[o].color,
      onClick:
        options[o].value == null
          ? null
          : () => {
              const requestField = ['GET', 'DELETE'].includes(params.method)
                ? 'queryData'
                : 'postData'
              const newData = params[requestField]
                ? FICUtils.clone(params[requestField])
                : {}
              if (!newData['_answers']) {
                newData['_answers'] = {}
              }
              newData['_answers'][question.name] = options[o].value
              FICRestV2.execute({ ...params, [requestField]: newData })
            },
    })
  }
  // WorkAround per privacy Cerved (so che è brutto ma essendo l'API a rispondere con la question la intercetto..)
  if (question && question.name === 'ignore_check') {
    FICModal.show({
      title: question.title,
      body: (
        <FICMultilineTextView>
          <div style={{ marginBottom: 24, color: '#333333' }}>
            {question.message}
          </div>
          <div style={{ color: '#6F7E86' }}>
            <CervedPrivacyContent />
          </div>
        </FICMultilineTextView>
      ),
      actions: actions,
      allowClose: actions?.some(action => action.onClick === null),
    })
  } else {
    FICModal.show({
      title: question.title,
      body: <FICMultilineTextView>{question.message}</FICMultilineTextView>,
      actions: actions,
      allowClose: actions?.some(action => action.onClick === null),
    })
  }

  return true
}

FICRestV2.handlePopup = responseData => {
  if (!responseData || !responseData.extra || !responseData.extra.popup) {
    return false
  }
  const popup = responseData.extra.popup
  FICModal.showMessage(
    popup.title,
    popup.message,
    null,
    popup.confirm_text,
    popup.confirm_color
  )
  return true
}

FICRestV2.rest = params => {
  FICRestV2.execute(
    Object.assign({}, params, { url: process.env.REACT_APP_HOST_API })
  )
}

FICRestV2.get = params => {
  FICRestV2.rest(Object.assign({}, params, { method: 'GET' }))
}

FICRestV2.delete = params => {
  FICRestV2.rest(Object.assign({}, params, { method: 'DELETE' }))
}

FICRestV2.post = params => {
  FICRestV2.rest(Object.assign({}, params, { method: 'POST' }))
}

FICRestV2.put = params => {
  FICRestV2.rest(Object.assign({}, params, { method: 'PUT' }))
}

FICRestV2.patch = params => {
  FICRestV2.rest(Object.assign({}, params, { method: 'PATCH' }))
}

FICRestV2.navigate = (request, queryData, returnUrl = false) => {
  const query = $.param(queryData || {})
  const url =
    reactGlobalParams.api_host + '/' + request + (query ? '?' + query : '')
  if (returnUrl) {
    return url
  }
  window.open(url)
}

FICRestV2.download = ({
  filename,
  method,
  onError,
  onSuccess,
  postData,
  queryData,
  request,
  url,
}) => {
  url = url || reactGlobalParams.api_host
  const query = $.param(queryData || {})

  url = url + '/' + request + (query ? '?' + query : '')
  method = method || 'GET'

  const xhr = new XMLHttpRequest()
  xhr.open(method, url, true)
  xhr.setRequestHeader('Authorization', reactGlobalParams.access_token)
  if (method === 'POST' && postData) {
    xhr.setRequestHeader('Content-type', 'application/json')
  }
  if (reactGlobalParams.adminAccountId) {
    xhr.setRequestHeader('Admin-Account-Id', reactGlobalParams.adminAccountId)
  }

  xhr.responseType = 'blob'
  xhr.onload = function (e) {
    if (this.status === 200) {
      const headers = xhr.getAllResponseHeaders().split('\n')
      const disposition = headers.filter(
        header =>
          header.startsWith('content-disposition') ||
          header.startsWith('Content-Disposition')
      )
      if (disposition.length > 0) {
        filename = headerFilename(disposition[0])
      }

      download(new Blob([this.response]), filename)

      if (onSuccess) {
        onSuccess()
      }
    } else {
      const blob = new Blob([this.response], { type: 'application/json' })
      const reader = new FileReader()

      reader.readAsText(blob)
      reader.addEventListener('loadend', e => {
        const text = e.srcElement.result
        const responseArray = JSON.parse(text)

        if (onError) {
          onError(responseArray.error)
        } else {
          Toast.error(responseArray.error.message)
        }
      })
    }
  }

  const body = postData ? JSON.stringify(postData) : ''
  xhr.send(body)
}

/*
FICRestV2.download = ({ method, url, request, queryData, postData, filename, onSuccess, onError }) => {
  url = url || reactGlobalParams.api_host
  const query = $.param(queryData ? queryData : {})
  let options = {
    url: url + '/' + request + (query ? ('?' + query) : ''),
    method: method || 'GET',
    headers: { 'Authorization': reactGlobalParams.access_token, 'Content-Type': 'application/json; charset=utf-8' },
    data: JSON.stringify(postData),
    success: function (responseData, result, rawData) {
      if (!filename) {
        const headers = rawData.getAllResponseHeaders().split('\n')
        let disposition = headers.filter(header => header.startsWith('content-disposition') || header.startsWith('Content-Disposition'))
        if (disposition.length > 0)
          filename = headerFilename(disposition[0])
      }

      download(rawData.responseText, filename)
    },
    error: function (rawData, result, statusMessage) {

    },
  }
  $.ajax(options)
}
*/

/*
FICRestV2.download = (params) => {
  let myHeaders = new Headers({
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': reactGlobalParams.access_token,
  })
  const { method, request, queryData, data, onSuccess, onError } = params
  const query = $.param(queryData ? queryData : {})
  let url = reactGlobalParams.api_host + '/' + request + '?' + query
  let filename
  return fetch(url, {
    method: method || 'GET',
    headers: myHeaders,
    body: JSON.stringify(data),
    credentials: 'same-origin',
    mode: 'cors',
  }).then(resp => {
    filename = resp.headers.get('File-Name') || 'download'
    return resp.blob()
  }).then(blob => {
    download(blob, filename)
    if (onSuccess)
      onSuccess()
  })
}
*/
export default FICRestV2
