import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

import { isNumber } from '../utils/tsUtils'

export const fixedHeight = (
  height: number | string
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const h: string = isNumber(height) ? `${height}px` : height

  return css`
    height: ${h};
    min-height: ${h};
    max-height: ${h};
  `
}

export const fixedWidth = (
  width: number | string
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const w: string = isNumber(width) ? `${width}px` : width

  return css`
    width: ${w};
    min-width: ${w};
    max-width: ${w};
  `
}
