/* eslint-disable */
import { normalize, schema } from 'normalizr'

import { ApiCompanyType } from '../../app/actions/setFromApi'
import { NormalizedObjects } from '../../common/normalization'
import { GenericCompanyType } from './companies/companiesStoreTypes'
import { ConnectionType } from './connections/connectionsStoreTypes'

interface ReturnInterface {
  companies: NormalizedObjects<GenericCompanyType>
  connections: NormalizedObjects<ConnectionType>
}

export const normalizeAuth = (
  originalData: ApiCompanyType[]
): ReturnInterface => {
  const company = new schema.Entity(
    'companies',
    {},
    {
      processStrategy: (value, parent) => {
        const { accessToken, connectionId, ...company } = value

        return {
          ...company,
          connections: {
            controllerId:
              parent.id || (value.type === 'accountant' ? value.id : null),
            controlledId: value.id,
            accessToken: accessToken,
            id: connectionId,
            manageable: !!parent.id || value.type === 'company',
          },
        }
      },
    }
  )

  const companies = [company]
  const connection = new schema.Entity(
    'connections',
    {},
    {
      mergeStrategy: (connectionA, connectionB) => ({
        ...connectionA,
        ...connectionB,
        controllerId: connectionA.controlledId,
        manageable: connectionA.manageable || connectionB.manageable,
      }),
    }
  )

  company.define({ controlledCompanies: companies, connections: connection })

  const normalized = normalize(originalData, [company])

  const compAllIds = normalized.entities.companies
    ? Object.keys(normalized.entities.companies!).map(id => parseInt(id))
    : []

  const normalizedCompanies: NormalizedObjects<GenericCompanyType> = {
    byId: normalized.entities.companies || {},
    allIds: compAllIds,
  }

  const connAllIds = normalized.entities.connections
    ? Object.keys(normalized.entities.connections!).map(id => parseInt(id))
    : []
  const normalizedConnections: NormalizedObjects<ConnectionType> = {
    byId: normalized.entities.connections || {},
    allIds: connAllIds,
  }

  return {
    companies: normalizedCompanies,
    connections: normalizedConnections,
  }
}
