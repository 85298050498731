import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../common/network/Api'
import { BECallStatus } from '../../../../common/types'
import PreferencesStore, {
  SetAsyncPreferencePayload,
} from '../preferencesStoreTypes'

const setAsyncPreference = createAsyncThunk<
  SetAsyncPreferencePayload,
  SetAsyncPreferencePayload,
  { rejectValue: false }
>(
  'preferences/setAsyncPreferenceStatus',
  async (payload: SetAsyncPreferencePayload, thunkApi) => {
    try {
      await Api.post({
        request: 'user_preferences/set',
        postData: { field: payload.name, value: payload.value },
      })
      return payload
    } catch (e) {
      return thunkApi.rejectWithValue(false)
    }
  }
)

export const addSetAsyncPreferenceCases = (
  builder: ActionReducerMapBuilder<PreferencesStore>
): void => {
  builder
    .addCase(setAsyncPreference.pending, state => {
      state.fetchStatus = BECallStatus.LOADING
    })
    .addCase(setAsyncPreference.fulfilled, (state, action) => {
      const { name, value } = action.payload
      // eslint-disable-next-line
      // @ts-ignore
      state[name] = value
    })
    .addCase(setAsyncPreference.rejected, state => {
      return state
    })
}

export default setAsyncPreference
