import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { MigrationsClientsData } from '..'
import { migrationsClientsDataInitialState } from '../swapStorageInitialState'
import SwapStorageStore from '../swapStorageTypes'

export const setMigrationsClientsData = (
  state: Draft<SwapStorageStore>,
  action: PayloadAction<MigrationsClientsData>
): void => {
  state.migrationsClientsData = action.payload
}

export const clearMigrationsClientsData = (
  state: Draft<SwapStorageStore>
): void => {
  state.migrationsClientsData = migrationsClientsDataInitialState
}
