import ModalsConfigStoreTypes from './modalsConfigStoreTypes'

const modalsConfigInitialState: ModalsConfigStoreTypes = {
  newPaymentAccountModalConfig: {
    prefilledName: '',
    lastCreatedId: null,
  },
  forfettarioLimitWarningModal: {
    type: 'warning',
  },
}

export default modalsConfigInitialState
