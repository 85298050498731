// import Popup from 'react-popup'
import 'moment/locale/it'

import { Toast } from '@fattureincloud/fic-design-system'
import moment from 'moment'

import FICModalV2 from '../../../common/components-v2/FICModal/FICModal'
import { config } from '../../../common/forms-v2/config'
import FICForm from '../../../common/forms-v2/FICForm/FICForm'
import FICModal from '../../components/js/FICModal'
import FICRestV2 from '../../network/js/FICRestV2'
import MixPanelTracker from './MixPanelTracker'
import TagManagerTracker from './TagManagerTracker'

class FIC {}

FIC.globals = undefined

FIC.init = (theme = theme) => {
  // ReactDOM.render(<Popup />, overlayContainer)
  FIC.globals = reactGlobalParams
  if (theme !== null) {
    FIC.theme = theme
  }
  FICModal.init(document.getElementById('react-overlay-modal-container'))
  FICModalV2.init(document.getElementById('react-overlay-modal2-container'))
  FICForm.init(FICForm.createConfig(config))
  moment.locale('it')
  window.Toast = Toast
  window.FICRestV2 = FICRestV2
  window.FICModal = FICModal
}

FIC.setGlobals = globals => {
  Object.keys(globals).forEach(key => {
    FIC.globals[key] = globals[key]
  })
}

FIC.can = (accessLevel, moduleName, submoduleName = '') => {
  const permissions = FIC.globals.permissions
  let p
  if (!['read', 'write'].includes(accessLevel)) {
    return false
  }
  if (!permissions || !permissions.hasOwnProperty(moduleName)) {
    return false
  }
  if (permissions[moduleName] === 'detailed') {
    if (!permissions[moduleName + '_detailed'].hasOwnProperty(submoduleName)) {
      return false
    }
    p = permissions[moduleName + '_detailed'][submoduleName]
  } else {
    p = permissions[moduleName]
  }
  if (p === 'none') {
    return false
  }
  if (accessLevel === 'read') {
    return true
  }
  return p === 'write'
}

FIC.canRead = (moduleName, submoduleName = '') => {
  return FIC.can('read', moduleName, submoduleName)
}

FIC.canWrite = (moduleName, submoduleName = '') => {
  return FIC.can('write', moduleName, submoduleName)
}

FIC.tracker = new MixPanelTracker()
FIC.tracker.setConfig({
  debug: false,
})

FIC.gtm = new TagManagerTracker()

export default FIC
