import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

export const TAX_CODE_REGEX =
  /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/

/**
 * Tax code validator
 * @param {string} taxCode - The tax code to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateTaxCode = (
  taxCode: string,
  params?: ValidatorParams
): ValidationResult => {
  if (typeof taxCode !== 'string') {
    return Validation.error('Il codice fiscale deve essere una stringa')
  }
  taxCode = taxCode.trim().toUpperCase()
  if (!taxCode && !params?.allowEmpty) {
    return Validation.error('Il codice fiscale non può essere vuoto')
  }

  if (taxCode.length < 11 && taxCode.length > 16) {
    return Validation.error(
      'Il codice fiscale deve essere lungo 11 o 16 caratteri'
    )
  }
  if (!TAX_CODE_REGEX.test(taxCode)) {
    return Validation.error('Il codice fiscale non è valido')
  }
  const expectedCheckCode = taxCode.charAt(15)
  const stringToCheck = taxCode.slice(0, 15)
  if (getCheckCode(stringToCheck) !== expectedCheckCode) {
    return Validation.error('Il codice fiscale non è valido')
  }

  return Validation.success()
}

const getCheckCode = (codiceFiscale: string): string => {
  codiceFiscale = codiceFiscale.toUpperCase()
  let val = 0
  for (let i = 0; i < 15; i = i + 1) {
    const c = codiceFiscale[i]
    val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
  }
  val = val % 26
  return CHECK_CODE_CHARS.charAt(val)
}

const CHECK_CODE_ODD: Record<string, number> = {
  '0': 1,
  '1': 0,
  '2': 5,
  '3': 7,
  '4': 9,
  '5': 13,
  '6': 15,
  '7': 17,
  '8': 19,
  '9': 21,
  A: 1,
  B: 0,
  C: 5,
  D: 7,
  E: 9,
  F: 13,
  G: 15,
  H: 17,
  I: 19,
  J: 21,
  K: 2,
  L: 4,
  M: 18,
  N: 20,
  O: 11,
  P: 3,
  Q: 6,
  R: 8,
  S: 12,
  T: 14,
  U: 16,
  V: 10,
  W: 22,
  X: 25,
  Y: 24,
  Z: 23,
}

const CHECK_CODE_EVEN: Record<string, number> = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25,
}

export const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
