import store from '../../redux'
import { getChatConfig } from '../../redux/features/configurations/configurationsSelectors'
import { setVisibility } from '../../redux/features/visibilities/visibilitiesSlice'
import Api from '../network/Api'

export function wait(ms: number): Promise<undefined> {
  return new Promise(resolve => window.setTimeout(resolve, ms))
}

export const openCustomerly = (help = false): void => {
  const state = store.getState()
  const { globalVisible, helpVisible } = getChatConfig(state)
  const alreadyVisible = state.visibilities.customerly

  const visible = globalVisible || (helpVisible && help) || alreadyVisible

  if (visible) {
    window.customerly?.open()
    window.customerly?.show()
  } else {
    alert(
      "Purtroppo in questo momento l'assistenza clienti è completamente occupata a causa di un numero eccezionalmente elevato di richieste.\n" +
        'Ci scusiamo molto per il disagio e contiamo di ripristinare il supporto nel più breve tempo possibile.'
    )
  }

  store.dispatch(setVisibility({ element: 'customerly', visible }))
}

export const sendCustomerlyEvent = (event: string): void =>
  window.customerly?.event(event)

export const refreshPHPSession = (currentSession: string): void => {
  void Api.get({
    url: window.location.origin,
    request:
      '/rest.php?request=restore_session&new_nav=1&params=' + currentSession,
  })
}

export const getDeviceId = (): string | null => {
  return localStorage.getItem('fic_device_id')
}

export const setDeviceId = (deviceId: string): void => {
  if (!deviceId) return
  localStorage.setItem('fic_device_id', deviceId)
}

export const debounce = (
  f: (...args: unknown[]) => void,
  ms = 500
): ((...args: unknown[]) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => f.apply(this, args), ms)
  }
}

export const copyToClipboard = (text: string): Promise<void> =>
  navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : new Promise(() => {
        throw new Error("Insecure environment, can't copy to clipboard.")
      })
