import React from 'react'

import FICFileUploader from '../components/js/FICFileUploader'
import FICAutocomplete from '../components-v2/FICAutocomplete/FICAutocomplete'
import FICButton from '../components-v2/FICButton/FICButton'
import FICLink from '../components-v2/FICButton/FICLink'
import FICCheckbox from '../components-v2/FICCheckbox/FICCheckbox'
import FICInputText from '../components-v2/FICInputText/FICInputText'
import FICRadioGroup from '../components-v2/FICRadioGroup/FICRadioGroup'
import FICSelect from '../components-v2/FICSelect/FICSelect'
import FICValidation from '../validation-v2/validation'

/* eslint-disable react/prop-types */

const LargeInputText = ({ size, ...props }) => (
  <FICInputText {...props} size='large' />
)

/* eslint-enable react/prop-types */

export const config = {
  components: {
    text: {
      role: 'field',
      component: FICInputText,
    },
    'text-large': {
      role: 'field',
      component: LargeInputText,
    },
    radio: {
      role: 'field',
      component: FICRadioGroup,
    },
    checkbox: {
      role: 'field',
      component: FICCheckbox,
    },
    autocomplete: {
      role: 'field',
      component: FICAutocomplete,
    },
    select: {
      role: 'field',
      component: FICSelect,
    },
    uploader: {
      role: 'field',
      component: FICFileUploader,
    },
    button: {
      role: 'field',
      component: FICButton,
    },
    link: {
      role: 'field',
      component: FICLink,
    },
  },
  customValidators: {
    itTaxCode: value => {
      if (!value) return ''
      return !FICValidation.itTaxCode(value)
        ? '^Codice fiscale non corretto'
        : ''
    },
    itVatNumber: value => {
      if (!value) return ''
      return !FICValidation.itVatNumber(value)
        ? '^Partita IVA non corretta'
        : ''
    },
    isEmail: value => {
      if (!value) return ''
      return !FICValidation.isEmail(value) ? '^E-mail non valida' : ''
    },
    isPecEmailNeedWarning: value => {
        if (!value) return ''
        return !FICValidation.isPecEmail(value)
            ? `^L'utilizzo di un'e-mail PEC è sconsigliato`
            : ''
    },
  },
}
