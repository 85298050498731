import { DepositConfig, ServicesConfigurations } from './pages/tsPayPage/types'

export const MAX_DEFAULT_PAYMENT_AMOUNT = 10000
export const MIN_DEFAULT_PAYMENT_AMOUNT = 1
export const FLUID_PAYMENT_MIN_PAYMENT = 50

export const APIStatus = {
  ACTIVE: 'active',
  ACTIVATING: 'activating',
  NOT_ACTIVE: 'not_active',
}

export const OnboardingStatus = {
  NOT_STARTED: 'not_started',
  PENDING: 'pending',
  IDENTIFICATION: 'identification',
  WAITING: 'waiting',
  FAILED: 'verification_failed',
  ERROR: 'technical_error',
  EXPIRED: 'expired',
  DONE: 'done',
}

const initialDepositConfig: DepositConfig = {
  ibanLastFourDigits: '',
  paymentsFrequency: '',
  paymentsSchedule: '',
}

const emptyPaymentMethod = {
  min: 0,
  max: 10000,
  label: '',
  active: false,
}

export const initialConfiguration: ServicesConfigurations = {
  bankReconciliation: {
    active: false,
    paymentAccounts: [],
  },
  depositConfig: initialDepositConfig,
  digitalPos: {
    active: false,
    pos: [],
    unhandledCount: 0,
  },
  incomingPayments: {
    active: false,
    fluidPayments: false,
    paymentMethods: {
      paypal: { ...emptyPaymentMethod },
      sdd: { ...emptyPaymentMethod },
      credit_card: { ...emptyPaymentMethod },
    },
  },
  linkedAccounts: [],
  outgoingPayments: {
    active: false,
  },
}
